import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { ButtonSC, SpecialCombosSC, SpecialCombosSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import lines from "../../../public/lines.png"
import rLine from "../../../public/rLine.png"

import ComboPoligon from "../ComboPoligon"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import RoundButton from "../RoundButton"
import Col from "../ui/Col"
import pxToRem from "../../utils/pxToRem"
import classNames from "classnames"

export interface SpecialCombosProps extends SpecialCombosSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

const dragon = new URL("images/dragon.png?as=webp&width=882", import.meta.url).toString()

const traits = [
  { id: 6, name: "Spikes", amount: 2 },
  { id: 1, name: "Ear", amount: 1 },
  { id: 4, name: "Red Orb", amount: 1 },
  { id: 3, name: "Fangs", amount: 1 },
  { id: 2, name: "Yellow Orb", amount: 1 },
  { id: 5, name: "Lightning", amount: 1 },
  { id: 10, name: "Hair", amount: 1 },
  { id: 9, name: "Arms", amount: 1 },
  { id: 8, name: "Scales", amount: 1 },
  { id: 7, name: "Spiral", amount: 1 },
]

const angle = 360 / traits.length

function SpecialCombos({ className, style, screenWidth }: SpecialCombosProps) {
  const [ ref, setRef ] = useState<HTMLImageElement | null>(null)
  const [ imgSize, setImgSize ] = useState(0)

  const onRefChange = useCallback((node: HTMLImageElement) => {
    setRef(node)

    function check() {
      const height = node?.getBoundingClientRect().height || 0
      if (height > 0) {
        setImgSize(height)
      } else {
        setTimeout(() => check(), 10)
      }
    }

    check()
  }, [])

  useEffect(() => {
    setImgSize(ref?.getBoundingClientRect().height || 0)
  }, [ ref, screenWidth ])

  const radius = useMemo(() => {
    if (screenWidth > 1008) {
      return parseInt(pxToRem(imgSize / 2))
    } else if (screenWidth > 860) {
      return parseInt(pxToRem(screenWidth / 1.7))
    } else {
      return parseInt(pxToRem(screenWidth / 1.45))
    }
  }, [ imgSize ])

  const [ hidden, setHidden ] = useState(true)
  const onClick = useCallback(() => setHidden(state => !state), [ hidden ])

  return (
    <SpecialCombosSC className={ className } style={ style }>
      <Col gap={ 12 }>
        <Text className="h1">
          Unlocking <span className="colored-title">Special Combos</span>
        </Text>
        <Text className="title-description">
          To unlock a Special Skin, you need a specific combo of Traits.<br/>
          Only experienced players can do that and are allowed to compete in the Ladder mode.<br/>
          But you’re overthinking this.<br/>
          Try it. You’re smart, you’ll get the hang of it.
        </Text>
      </Col>
      <div className="dragon">
        <img
          className="dragon-img"
          src={ dragon }
          width={ 882 }
          alt="dragon"
          ref={ onRefChange }
        />
        <div
          className="dragon-clickable"
          onClick={ onClick }
          style={ { width: `${ imgSize / 2 }px`, height: `${ imgSize / 2 }px` } }
        />
        <img src={ lines } alt="lines" className="lines"/>
        <img src={ rLine } alt="rLine" className="r-line"/>
        <Text
          size={ 16 }
          className={ classNames("combo-text-hint", !hidden ? "hidden" : null) }>Tap to see
          combos</Text>

        { traits.map((trait, i) => {
          return (
            <ComboPoligon
              key={ trait.id }
              className={ `combo combo-${ i + 1 }` }
              trait={ trait.id.toString() }
              text={ `x${ trait.amount }` }
              altText={ trait.name }
            />
          )
        }) }

        { traits.map((trait, i) => {
          const x = radius * Math.sin(Math.PI * 2 * angle * i / 360)
          const y = radius * Math.cos(Math.PI * 2 * angle * i / 360)

          return (
            <div key={ trait.id } className="combo-mobile-container">
              <ComboPoligon
                className={ classNames("combo-mobile", hidden ? "hidden" : null) }
                trait={ trait.id.toString() }
                text={ `x${ trait.amount }` }
                altText={ trait.name }
                type={ "click" }
                style={ {
                  transform: `translateX(${ x }rem) translateY(${ y }rem)`,
                } }
              />
            </div>
          )
        }) }
      </div>
      <ButtonSC>
        <a href="https://play.snook.gg/" target="_blank"><RoundButton color={ RoundButtonTypeEnum.GREEN }>CLICK TO
          PLAY</RoundButton></a>
      </ButtonSC>
    </SpecialCombosSC>
  )
}

export default memo(SpecialCombos)
