import styled, { keyframes } from "styled-components"
import leftYBG from "./left-ybg.png"
import rightYBG from "./right-ygb.png"
import middleRectangle from "./middle-rectangle.svg"
import Col from "../ui/Col"
import Row from "../ui/Row"
import pxToRem from "../../utils/pxToRem"
import { AnimatedArrowPointingSC } from "../AnimatedArrowPointing/styled"

const image = new URL("../../../public/image.png?as=webp&width=1080", import.meta.url).toString()

export interface PlayingSnookSCProps {
}

export const PlayingSnookSC = styled(Col)<PlayingSnookSCProps>`
  padding: 8rem 0;
  background-blend-mode: normal, normal, luminosity, normal;

  overflow-x: auto;

  .play-btn {
    transform: scale(0.4);
  }

  .right-part {
    width: 4.625rem;
  }

  .left-part {
    width: ${ pxToRem(423) };
  }

  ${ AnimatedArrowPointingSC } {
    padding: 0 2rem .5rem;
    display: none;
  }
`

export const WitchCardSC = styled.div`
  position: relative;

  z-index: 2;

  transition: transform .3s ease-in-out, filter .3s ease-in-out;

  .snookCard1, .snookCard2 {
    position: absolute;
    top: 35%;
    left: 25%;

    transform: translateX(-50%) translateY(-25%);

    transition: transform .3s ease-in-out, filter .3s ease-in-out;
  }

  .snookCard2 {
    transform: translateX(calc(-50% - 1rem)) translateY(-25%);
  }

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 ${ pxToRem(12) } ${ pxToRem(16) } rgba(0, 0, 0, 0.25));

    .snookCard1, .snookCard2 {
      filter: drop-shadow(0 0 ${ pxToRem(4) } #FFFFFF);
    }

    .snookCard1 {
      transform: translateX(calc(-50% + 1rem)) translateY(calc(-25% - 1rem)) rotateZ(6deg);
    }

    .snookCard2 {
      transform: translateX(calc(-50% - 2rem)) translateY(calc(-25% - 1rem)) rotateZ(-5deg);
    }
  }
`

export const BiteCardSC = styled.div`
  position: relative;

  z-index: 2;

  transition: transform .3s ease-in-out, filter .3s ease-in-out;

  .skull1, .skull2, .skull3 {
    transition: transform .3s ease-in-out, filter .3s ease-in-out;
  }

  .skulls {
    position: absolute;
    width: 260px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .skull1 {
    position: absolute;
    top: 50%;
    left: calc(2rem);
  }

  .skull2 {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(0.75rem);
  }

  .skull3 {
    position: absolute;
    top: calc(50% - 2.5rem);
    left: calc(2rem);
  }

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 ${ pxToRem(12) } ${ pxToRem(16) } rgba(0, 0, 0, 0.25));

    .skull1 {
      transform: rotateZ(45deg) scale(1.2);
      filter: drop-shadow(0 0 ${ pxToRem(11.7697) } rgba(255, 255, 255, 0.5));
    }

    .skull2 {
      transform: rotateZ(-45deg) scale(1.2) translateX(-.1rem) translateY(-.5rem);
      filter: drop-shadow(0 0 ${ pxToRem(11.7697) } rgba(255, 255, 255, 0.5));
    }

    .skull3 {
      transform: rotateZ(60deg) scale(1.2) translateX(-.5rem) translateY(-.5rem);
      filter: drop-shadow(0 0 ${ pxToRem(11.7697) } rgba(255, 255, 255, 0.5));
    }
  }

`

export const EarnCardSC = styled.div`
  position: relative;

  z-index: 2;

  transition: transform .3s ease-in-out, filter .3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0 ${ pxToRem(12) } ${ pxToRem(16) } rgba(0, 0, 0, 0.25));
  }
`

export const ShineSC = styled.div`
  position: absolute;
  height: 100%;
  width: 2rem;

  background-color: #FFF7DD;
  filter: blur(${ pxToRem(67) });
`

export const AnimateShineSC = keyframes`
  0% {
    opacity: 1;
    left: -5rem;
  }

  10% {
    left: calc(100% + 5rem);
  }

  11%, 100% {
    opacity: 0;
  }
`

export const AnimateActive1SC = keyframes`
  3% {
    opacity: 0;
  }

  10%, 25% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }
`

export const AnimateActive2SC = keyframes`
  7% {
    opacity: 0;
  }

  14%, 35% {
    opacity: 1;
  }

  45% {
    opacity: 0;
  }
`

export const AnimateActive3SC = keyframes`
  10% {
    opacity: 0;
  }

  17%, 45% {
    opacity: 1;
  }

  55% {
    opacity: 0;
  }
`

export const BlockSC = styled(Row)`
  position: relative;
`

export const LeftBlockSC = styled(Row)`
  position: relative;
  width: 100%;
  filter: drop-shadow(0 ${ pxToRem(16) } ${ pxToRem(24) } rgba(0, 0, 0, 0.08));

  .left-stock {
    background: linear-gradient(180deg, rgba(0, 6, 157, 0) 0%, rgba(0, 6, 157, 0.3) 100%), #3284FF;
    border-radius: ${ pxToRem(24) } 0 0 ${ pxToRem(24) };
    background-blend-mode: normal, normal;

    flex-grow: 1;

    position: relative;
    overflow: hidden;

    button {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), #FF9E2D;
      background-blend-mode: overlay, normal;
      border-radius: 1.5rem;
      border: 0;

      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0.2rem 0.5rem;

      text-align: center;

      color: #FFFFFF;

      & > img {
        height: 0.5rem;
        margin: 0 0 .05rem .2rem;
      }
    }

    ${ ShineSC } {
      transform: rotateZ(15deg);
      left: -10rem;
      animation-name: ${ AnimateShineSC };
      animation-duration: 5s;
      animation-iteration-count: infinite;
    }

    & > .text {
      position: absolute;
      bottom: 0;
      left: 50%;

      margin-bottom: 1rem;
      width: 100%;
      transform: translateX(-50%);

      p {
        margin-bottom: 1rem;
      }
    }

    &::after {
      content: "";
      background: url(${ image });
      opacity: 0.06;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      background-size: 160%;
      z-index: 1;
      background-blend-mode: luminosity;
    }
  }

  .right-stock {
    background-image: url(${ leftYBG });
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(20rem * 0.19210526315789472);
    height: 20rem;

    position: relative;

    .active {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;

      background-image: url(${ leftYBG });
      background-size: cover;

      opacity: 0;

      filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.5));

      animation-duration: 5s;
      animation-iteration-count: infinite;

      & > div {
        width: 100%;
        height: 100%;
        mask: url(${ leftYBG });
        mask-size: cover;

        background-color: #FFF7DD;
        background-size: 100%;
      }

      &.active-1 {
        animation-name: ${ AnimateActive1SC };
      }

      &.active-2 {
        animation-name: ${ AnimateActive2SC };
      }

      &.active-3 {
        animation-name: ${ AnimateActive3SC };
      }
    }
  }
`

export const MiddleBlockSC = styled(LeftBlockSC)`
  .left-stock {
    content: "";
    background-color: #3284FF;
    mask: url(${ middleRectangle }), url(${ middleRectangle });
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-composite: add;
    mask-position: left, 200%;
    border-radius: 0;

    ${ ShineSC } {
      animation-delay: .25s;
    }

    & > .text {
      margin-left: 1rem;
    }
  }
`

export const RightBlockSC = styled(MiddleBlockSC)`
  .left-stock {
    background-color: #3284FF;
    mask: url(${ middleRectangle }), url(${ middleRectangle });
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-composite: add;
    mask-position: left, 200%;
    border-radius: 0;

    ${ ShineSC } {
      animation-delay: .5s;
    }
  }

  .right-stock {
    background-image: url(${ rightYBG });
    width: calc(20rem * 0.08157894736842106);
    height: 20rem;

    .active {
      background-image: url(${ rightYBG });

      & > div {
        mask: url(${ rightYBG });
        mask-size: cover;
      }
    }
  }
`

export const CardWrapperSC = styled(Row)`
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: flex-end;
  margin-top: 5%;
  margin-bottom: -30%;

  @media screen and (max-width: 1008px) {
    margin-bottom: -30%;
  }
`

export const ButtonSC = styled.div`
  margin-top: 3rem;
`

export const ScrollSC = styled.div`
  overflow-x: auto;

  //min-width: 1300px;
  //width: 1300px !important;

  //padding: 0 1.5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const SmallWrapperSC = styled(Row)`
  @media screen and (max-width: 1300px) {
    min-width: calc(1300px + 3rem);
    width: calc(1300px + 3rem) !important;

    justify-content: flex-start;

    ${ AnimatedArrowPointingSC } {
      display: flex;
    }
  }
`