import React, { memo, useEffect } from "react"
import { MainSC, MainSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import HeroSection from "../../components/HeroSection"
import Totals from "../../components/Totals"
import PlayingSnook from "../../components/PlayingSnook"
import HowToEarn from "../../components/HowToEarn"
import SpecialSkins from "../../components/SpecialSkins"
import SpecialCombos from "../../components/SpecialCombos"
import GameModes from "../../components/GameModes"
import Ladder from "../../components/Ladder"
import WannaDive from "../../components/WannaDive"
import GetSNK from "../../components/GetSNK"

export interface MainProps extends MainSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
  video?: any
}

declare const window: any
declare const YT: any

function Main({ style, screenWidth }: MainProps) {
  useEffect(() => {
    function onYouTubeIframeAPIReady() {
      new YT.Player("player", {
        width: "100%",
        height: "100%",
        videoId: "9QDqu3ayD4I",
        playerVars: { autoplay: 1, playsinline: 1, loop: 1, playlist: "9QDqu3ayD4I" },
        events: {
          onReady: function (event: any) {
            event.target.mute()
            event.target.playVideo()
          },
        },
      })
    }

    if (!window.YT) {
      const tag = document.createElement("script")

      tag.src = "https://www.youtube.com/iframe_api"
      const firstScriptTag = document.getElementsByTagName("script")[0]
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)

      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
    } else {
      onYouTubeIframeAPIReady()
    }
  }, [])

  return (
    <MainSC style={ style }>
      <div className="firstSection">
        <div id="background-video">
          <div id="background-video-cover"></div>
          <div id="player"></div>
        </div>
        <HeroSection screenWidth={ screenWidth }/>
      </div>
      <div className="secondSection">
        <Totals/>
        <PlayingSnook/>
        <HowToEarn screenWidth={ screenWidth }/>
      </div>
      <div className="thirdSection">
        <SpecialSkins/>
        <SpecialCombos screenWidth={ screenWidth }/>
      </div>
      <GameModes screenWidth={ screenWidth }/>
      <Ladder screenWidth={ screenWidth }/>
      <div className="fourthSection">
        <WannaDive screenWidth={ screenWidth }/>
      </div>
      <GetSNK screenWidth={ screenWidth }/>
    </MainSC>
  )
}

export default memo(Main)
