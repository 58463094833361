import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../../ui/Col";

export interface TraitSCProps {
}

export const TraitSC = styled(Col)<TraitSCProps>`
  position: relative;
  background: #00112C;
  border-width: ${pxToRem(8)} 0px;
  border-style: solid;
  border-color: #FDC500;
  padding: ${pxToRem(128)} 0;

    .trait-wrapper {
      width: ${pxToRem(1440)};
    }
  
    .trait-h1 {
      ont-family: 'Source Sans Pro';
      font-weight: 900;
      font-size: ${pxToRem(72)};
      line-height: 98%;
      letter-spacing: -0.05em;
      text-transform: capitalize;
      color: #FDC500;
    }
  
    .img-1 {
      position: absolute;
      width: 102.91px;
      right: ${pxToRem(120)};
      top: ${pxToRem(5)};
    }

    .img-2 {
      position: absolute;
      width: 146.78px;
      right: ${pxToRem(300)};
      bottom: ${pxToRem(10)};
    }
  
    .img-3 {
      position: absolute;
      width: 180px;
      left: -${pxToRem(5)};
      bottom: ${pxToRem(10)};
    }
  
    .img-4 {
      position: absolute;
      width: 69px;
      left: ${pxToRem(470)};
      bottom: ${pxToRem(5)};
    }
  
    .img-5 {
      position: absolute;
      width: 115.55px;
      left: ${pxToRem(150)};
      top: ${pxToRem(16)};
    }
  
    .img-6 {
      position: absolute;
      width: 294.18px;
      right: 0;
      bottom: 0;
    }
  

  @media screen and (max-width: 1600px) {
    .trait-wrapper {
      width: 90%;
    }
  }

  @media screen and (max-width: 1100px) {
    padding: ${pxToRem(80)} 0;
    .img-1 {
      right: -${pxToRem(51)};
      top: ${pxToRem(42)};
    }

    .img-2 {
      left: -${pxToRem(80)};
      bottom: ${pxToRem(80)};
    }
  
    .img-3 {
      left: -${pxToRem(112)};
      bottom: ${pxToRem(250)};
    }

    .img-4 {
      left: ${pxToRem(500)};
      bottom: ${pxToRem(325)};
    }
  
    .img-5 {
      left: -${pxToRem(43)};
      top: ${pxToRem(8)};
    }
  
    .img-6 {
      right: -${pxToRem(30)};
      bottom: -${pxToRem(140)};
    }
    
    .trait-h1 {
      font-size: ${pxToRem(36)};
    }
  }
`
