import {CSSProperties, memo, ReactNode} from 'react'
import { CardSC } from './styled'

export interface CardProps {
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

function Card({
  className,
  style,
  children,
  ...rest
}: CardProps) {
  return (
    <CardSC
      className={className}
      style={style}
      {...rest}
    >
      {children}
    </CardSC>
  )
}

export default memo(Card)
