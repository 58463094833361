import styled from "styled-components"
import pxToRem from "../../utils/pxToRem"

const imageDark = new URL("../../../public/image-dark.png?as=webp", import.meta.url).toString()
const image = new URL("../../../public/image.png?as=webp&width=1080", import.meta.url).toString()

export interface MainSCProps {
}

export const MainSC = styled.div<MainSCProps>`
  #background-video {
    width: 100%;
    > video, iframe {
      width: 1920px;
      height: 1080px;
      display: block;
      border: 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    top: -15vh;
    margin: 0 auto;

    text-align: center;

    position: absolute;

    #background-video-cover {
      background: linear-gradient(0deg, #103B7B 0%, rgba(16, 59, 123, 0) 35%, rgba(16, 59, 123, 0) 100%, #103B7B 100%),
      linear-gradient(0deg, #103B7B 0%, rgba(16, 59, 123, 0) 35%, rgba(16, 59, 123, 0) 100%, #103B7B 100%),
      linear-gradient(0deg, #103B7B 0%, rgba(16, 59, 123, 0) 35%, rgba(16, 59, 123, 0) 100%, #103B7B 100%);

      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      //display: none;
    }
  }

  .secondSection {
    background: linear-gradient(180deg, #103B7B 13.27%, rgba(16, 59, 123, 0) 49.97%, rgba(16, 59, 123, 0) 70%, #3284FF 90%),
    radial-gradient(50% 50% at 50% 50%, #103B7B 0%, rgba(16, 59, 123, 0) 100%),
    url(${ imageDark }), #3284FF;
    background-blend-mode: normal, normal, luminosity, normal;
    padding-bottom: ${ pxToRem(64) };
  }

  .thirdSection {
    position: relative;
    z-index: 1;
    background-color: #3284FF;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .1;
      z-index: -1;
      background: linear-gradient(180deg, #3284FF 0%, rgba(50, 132, 255, 0) 25%),
      radial-gradient(50% 50% at 50% 50%, #3284FF 0%, rgba(16, 59, 123, 0) 100%),
      url(${ image }), #3284FF;
      background-blend-mode: normal, normal, luminosity, normal;
    }
  }

  .fourthSection {
    position: relative;
    z-index: 1;
    background-color: #1D155B;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .5;
      z-index: -1;
      background: linear-gradient(180deg, #002051 0%, rgba(0, 32, 81, 0) 50%, #1D155B 90%),
      radial-gradient(50% 50% at 50% 50%, #002051 0%, rgba(16, 59, 123, 0) 100%),
      url(${ imageDark }), #002051;
      background-blend-mode: normal, normal, luminosity, normal;
    }
  }

  @media screen and (max-width: 1100px) {
    #background-video {
      > video {
        width: 150vw;
        position: relative;
        margin: 0 auto;
        transform: translateX(-20%);
      }

      overflow: hidden;
      //width: 100vw;

      position: absolute;
    }
  }

  @media screen and (max-width: 700px) {
    #background-video-cover {
      display: block;
      height: 300px;
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
    }

    #background-video {
      > video {
        width: 250vw;
        position: relative;
        top: 0;
        margin: 0 auto;
        transform: translateX(-30%);
      }
      
      overflow: hidden;
      //width: 100vw;

      position: absolute;
      top: -100px;
    }
  }
`
