import styled from "styled-components"
import Col from "../ui/Col"
import pxToRem from "../../utils/pxToRem"
import Row from "../ui/Row"
import { GameModesCardSC } from "../GameModesCard/styled"

const imageDark = new URL("../../../public/image-dark.png?as=webp", import.meta.url).toString()

export interface GameModesSCProps {
}

export const SmallWrapperSC = styled(Row)`
  @media screen and (max-width: 1300px) {
    width: 100vw !important;
    gap: 3rem;
    padding: 0 3rem;
  }

  @media screen and (max-width: 860px) {
    width: 100vw !important;
    gap: 10rem;
    padding: 0 1.5rem;
  }
`

export const GameModesSC = styled(Col)<GameModesSCProps>`
  background-color: #103B7B;
  position: relative;
  z-index: 1;

  border-width: ${ pxToRem(4) } 0;
  border-style: solid;
  border-color: #FDC500;
  padding-top: 4rem;

  .game-mods-card {
    padding-top: ${ pxToRem(240) };
    padding-bottom: ${ pxToRem(80) };
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0) 100%),
    url(${ imageDark }), #103B7B;

    background-blend-mode: overlay, luminosity, normal;
  }

  @media screen and (max-width: 1300px) {
    .game-mods-card {
      padding-top: 15rem;
    }
  }

  @media screen and (max-width: 1200px) {
    .game-mods-card {
      padding-top: 10rem;
    }
  }

  @media screen and (max-width: 860px) {
    .game-mods-card {
      padding-top: 8rem;
      flex-direction: column;
      align-items: center;

      ${ GameModesCardSC } {
        max-width: 350px;
      }
    }
  }
`
