import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { BBTSC, BBTSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Col from "../../ui/Col"
import Text from "../../ui/Text"
import Row from "../../ui/Row"

export interface BBTProps extends BBTSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

const aspectRatio = 83 / 47

function BBT({ style, screenWidth }: BBTProps) {
  const calculateWidth = useCallback((screenWidth: number) => {
    return document.querySelector(".bbt-wrapper > div")?.getBoundingClientRect().width || screenWidth / 2
  }, [])

  const width = useMemo(() => calculateWidth(screenWidth), [ screenWidth ])

  const [ dimensions, setDimensions ] = useState({
    width: width,
    height: width / aspectRatio,
  })

  useEffect(() => {
    setDimensions({
      width: width,
      height: width / aspectRatio,
    })
  }, [ screenWidth ])

  useEffect(() => {
    setTimeout(() => {
      const width = calculateWidth(screenWidth)
      setDimensions({ width: width, height: width / aspectRatio })
    }, 0)
  }, [])

  return (
    <BBTSC style={ style } justify="center">
      <Row className="bbt-wrapper" gap={ screenWidth > 1100 ? 48 : 28 }>
        <Col>
          <div style={ { width: dimensions.width, height: dimensions.height } }>
            <div style={ { width: dimensions.width, height: dimensions.height } } className="bbt-video" id="bbt-video">
              {/*<HexagonButton*/ }
              {/*  className="bbt-video-play"*/ }
              {/*  icon={ HexagonButtonIconEnum.TRIANGLE }*/ }
              {/*  type={ HexagonButtonTypeEnum.ORANGE }*/ }
              {/*/>*/ }
            </div>
          </div>
        </Col>
        <Col gap={ screenWidth > 1100 ? 48 : 28 } className="bbt-text-block">
          <Text className="bbt-h2">BBT-TR 101</Text>
          <Col gap={ 28 }>
            <Text>The most basic application of BBT-TR is for NFT collections.
              A crypto NFT collection reaches out to Snook.
              They can choose if to design the graphic assets on their own or to provide
              Snook with the graphic assets and we will put the room together for them.
            </Text>
            <Text> Once that is done, the collection gets listed on
              Snook and it becomes available through the game UX.
              Now, anyone who meets the access criteria they set up,
              for example ownership of an NFT from the Collection,
              can join and play Snook in their BBTTR. Easy Peasy.
            </Text>
            <Text>
              Ask <b>Unstoppable Domains</b>, one of the first communities to set up their own Themed Room.
            </Text>
          </Col>
        </Col>
      </Row>
    </BBTSC>
  )
}

export default memo(BBT)
