import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../../ui/Col"

const bgHex = new URL("../../../../public/bg-hex.png?as=webp&width=1080", import.meta.url).toString()
const topVideo = new URL("./top-video.png?as=webp&width=1280", import.meta.url).toString()

export interface TopRoomBlockSCProps {
}

export const TopRoomBlockSC = styled(Col)<TopRoomBlockSCProps>`
  padding: ${ pxToRem(220) } 0 8rem;
  position: relative;
  z-index: 1;
  background-color: #103B7B;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, #103B7B 0%, rgba(16, 59, 123, 0) 100%),
    url(${ bgHex }), #103B7B;
    background-blend-mode: normal, normal, luminosity, normal;
  }

  .top-room-wrapper {
    width: 90rem;
    display: flex;
    flex-direction: row;
  }

  .top-block-wrapper {
    flex-grow: 1;
    width: 50%;

    p {
      text-align: left;
    }
  }

  .topRoomBlock-h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(72) };
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }

  .top-video {
    border-bottom: 4px solid #FDC500;
    border-radius: 16px 0 0 16px;
    box-sizing: border-box;

    // background-image: url(${ topVideo });
    //background-size: cover;

    position: relative;
    
    .top-video-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .top-room-btn {
    width: ${ pxToRem(355) };
    margin-top: ${ pxToRem(42) };
  }

  .top-video-mobile {
    display: none;
  }

  @media screen and (max-width: 1600px) {
    .top-room-wrapper {
      margin-top: 3rem;
      width: 90vw;
    }
  }

  @media screen and (max-width: 1100px) {
    justify-content: center;
    align-items: center;

    padding: 10rem ${ pxToRem(32) } 4rem ${ pxToRem(32) } ;

    .topRoomBlock-h1 {
      font-size: ${ pxToRem(55) };
    }

    .top-room-wrapper {
      margin-top: 0;
      flex-direction: column-reverse;
      justify-content: center;
      width: 90vw;
    }

    .top-video {
      border: 4px solid #FDC500;
      border-radius: 16px;
    }

    .top-block-wrapper {
      width: 100%;

      .topRoomBlock-h1 {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .top-room-wrapper {
      padding-top: 0;
    }

    .top-room-btn {
      width: ${ pxToRem(280) };
      margin-top: 0;
    }
  }
`
