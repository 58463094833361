import { memo, useCallback, useState } from "react"
import { CSSProperties } from "styled-components"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"

import "swiper/swiper.min.css"

import {
  CarouseImageSC,
  CarouselMobileSC,
  CarouselSC,
  SmallWrapperSC,
  SpecialSkinsSC,
  SpecialSkinsSCProps,
} from "./styled"
import Text from "../ui/Text"
import Col from "../ui/Col"
import HexagonButton from "../HexagonButton"

import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../HexagonButton/styled"
import classNames from "classnames"

export interface SpecialSkinsProps extends SpecialSkinsSCProps {
  className?: string
  style?: CSSProperties
}

function SpecialSkins({ className, style }: SpecialSkinsProps) {
  const [ state, setState ] = useState(new Set())

  const callback = useCallback((image) => {
    if (state.has(image)) {
      return
    } else {
      setState(previousState => new Set([ ...previousState, image ]))
      setTimeout(() => setState(previousState => {
        const state = new Set(previousState)
        state.delete(image)

        return state
      }), 3_000)
    }
  }, [ state ])

  return (
    <SpecialSkinsSC className={ className } style={ style }>
      <SmallWrapperSC className="small-wrapper">
        <div id="special-skins"></div>
        <Col gap={ 12 }>
          <Text className="h1">Special <span className="colored-title">Skins</span></Text>
          <Text className="title-description"> Not all Special Skins were created equal.<br/>
            We’d actually rather not say too much about them.
          </Text>
        </Col>
        <CarouselSC>
          <div className="navigation">
            <HexagonButton
              className="skin-prev-button"
              type={ HexagonButtonTypeEnum.ORANGE }
              icon={ HexagonButtonIconEnum.TRIANGLE }
              flip={ true }
            />
          </div>
          <Swiper
            id="special-skin-cards"
            modules={ [ Navigation ] }
            spaceBetween={ 16 }
            loop={ true }
            slidesPerView={ 5 }
            noSwipingSelector={ "div" }
            shortSwipes={ false }
            allowTouchMove={ false }
            navigation={ {
              nextEl: ".skin-next-button",
              prevEl: ".skin-prev-button",
            } }
            // onSlideChange={ (swiper) => {
            //   if (swiper.activeIndex === 1) {
            //     swiper.slideTo(5, undefined, false)
            //   } else if (swiper.activeIndex === 4) {
            //     swiper.slideTo(0, undefined, false)
            //   }
            // } }
            // onSlideNextTransitionStart={ (swiper) => {
            //   swiper.$wrapperEl.addClass("swing-start-right")
            //   setTimeout(() => swiper.$wrapperEl.removeClass("swing-start-right"), 600)
            // } }
            // onSlideNextTransitionEnd={ (swiper) => {
            //   swiper.$wrapperEl.addClass("swing-right")
            //   setTimeout(() => swiper.$wrapperEl.removeClass("swing-right"), 600)
            // } }
            // onSlidePrevTransitionStart={ (swiper) => {
            //   swiper.$wrapperEl.addClass("swing-start-left")
            //   setTimeout(() => swiper.$wrapperEl.removeClass("swing-start-left"), 600)
            // } }
            // onSlidePrevTransitionEnd={ (swiper) => {
            //   swiper.$wrapperEl.addClass("swing-left")
            //   setTimeout(() => swiper.$wrapperEl.removeClass("swing-left"), 600)
            // } }
            // onTransitionStart={ (swiper) => {
            //   if (swiper.activeIndex === 5) {
            //     swiper.$wrapperEl.addClass("swing-start-right")
            //     setTimeout(() => swiper.$wrapperEl.removeClass("swing-start-right"), 600)
            //   } else if (swiper.activeIndex === 0) {
            //     swiper.$wrapperEl.addClass("swing-start-left")
            //     setTimeout(() => swiper.$wrapperEl.removeClass("swing-start-left"), 600)
            //   }
            // } }
            // onTransitionEnd={ (swiper) => {
            //   if (swiper.activeIndex === 5) {
            //     swiper.$wrapperEl.addClass("swing-right")
            //     setTimeout(() => swiper.$wrapperEl.removeClass("swing-right"), 600)
            //   } else if (swiper.activeIndex === 0) {
            //     swiper.$wrapperEl.addClass("swing-left")
            //     setTimeout(() => swiper.$wrapperEl.removeClass("swing-left"), 600)
            //   }
            // } }
          >
            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Doge") ? "active" : "") }
                  onClick={ () => callback("Doge") }
                >
                  <img
                    src={ new URL(`./images/skin10.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Doge"
                  />
                  <div className="text">Doge</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Fox") ? "active" : "") }
                  onClick={ () => callback("Fox") }
                >
                  <img
                    src={ new URL(`./images/skin1.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Fox"
                  />
                  <div className="text">Fox</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Ninja") ? "active" : "") }
                  onClick={ () => callback("Ninja") }
                >
                  <img
                    src={ new URL(`./images/skin2.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Ninja"
                  />
                  <div className="text">Ninja</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Rainbow") ? "active" : "") }
                  onClick={ () => callback("Rainbow") }
                >
                  <img
                    src={ new URL(`./images/skin3.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Rainbow"
                  />
                  <div className="text">Rainbow</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("School Girl") ? "active" : "") }
                  onClick={ () => callback("School Girl") }
                >
                  <img
                    src={ new URL(`./images/skin4.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="School Girl"
                  />
                  <div className="text">School Girl</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Shark") ? "active" : "") }
                  onClick={ () => callback("Shark") }
                >
                  <img
                    src={ new URL(`./images/skin5.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Shark"
                  />
                  <div className="text">Shark</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Streamer") ? "active" : "") }
                  onClick={ () => callback("Streamer") }
                >
                  <img
                    src={ new URL(`./images/skin6.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Streamer"
                  />
                  <div className="text">Streamer</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Unicorn") ? "active" : "") }
                  onClick={ () => callback("Unicorn") }
                >
                  <img
                    src={ new URL(`./images/skin7.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Unicorn"
                  />
                  <div className="text">Unicorn</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Zombie") ? "active" : "") }
                  onClick={ () => callback("Zombie") }
                >
                  <img
                    src={ new URL(`./images/skin8.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Zombie"
                  />
                  <div className="text">Zombie</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>

            <SwiperSlide>
              <CarouseImageSC>
                <div
                  className={ classNames("hover", state.has("Cat") ? "active" : "") }
                  onClick={ () => callback("Cat") }
                >
                  <img
                    src={ new URL(`./images/skin9.png?as=webp&width=250`, import.meta.url).toString() }
                    alt="Cat"
                  />
                  <div className="text">Cat</div>
                </div>
              </CarouseImageSC>
            </SwiperSlide>
          </Swiper>
          <div className="navigation">
            <HexagonButton
              className="skin-next-button"
              type={ HexagonButtonTypeEnum.ORANGE }
              icon={ HexagonButtonIconEnum.TRIANGLE }
            />
          </div>
        </CarouselSC>
        <CarouselMobileSC>
          <div>
            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Doge") ? "active" : "") }
                onClick={ () => callback("Doge") }
              >
                <img
                  src={ new URL(`./images/skin10.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Doge"
                  width={ 250 }
                />
                <div className="text">Doge</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Fox") ? "active" : "") }
                onClick={ () => callback("Fox") }
              >
                <img
                  src={ new URL(`./images/skin1.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Fox"
                  width={ 250 }
                />
                <div className="text">Fox</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Ninja") ? "active" : "") }
                onClick={ () => callback("Ninja") }
              >
                <img
                  src={ new URL(`./images/skin2.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Ninja"
                  width={ 250 }
                />
                <div className="text">Ninja</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Rainbow") ? "active" : "") }
                onClick={ () => callback("Rainbow") }
              >
                <img
                  src={ new URL(`./images/skin3.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Rainbow"
                  width={ 250 }
                />
                <div className="text">Rainbow</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("School Girl") ? "active" : "") }
                onClick={ () => callback("School Girl") }
              >
                <img
                  src={ new URL(`./images/skin4.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="School Girl"
                  width={ 250 }
                />
                <div className="text">School Girl</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Shark") ? "active" : "") }
                onClick={ () => callback("Shark") }
              >
                <img
                  src={ new URL(`./images/skin5.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Shark"
                  width={ 250 }
                />
                <div className="text">Shark</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Streamer") ? "active" : "") }
                onClick={ () => callback("Streamer") }
              >
                <img
                  src={ new URL(`./images/skin6.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Streamer"
                  width={ 250 }
                />
                <div className="text">Streamer</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Unicorn") ? "active" : "") }
                onClick={ () => callback("Unicorn") }
              >
                <img
                  src={ new URL(`./images/skin7.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Unicorn"
                  width={ 250 }
                />
                <div className="text">Unicorn</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Zombie") ? "active" : "") }
                onClick={ () => callback("Zombie") }
              >
                <img
                  src={ new URL(`./images/skin8.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Zombie"
                  width={ 250 }
                />
                <div className="text">Zombie</div>
              </div>
            </CarouseImageSC>

            <CarouseImageSC>
              <div
                className={ classNames("hover", state.has("Cat") ? "active" : "") }
                onClick={ () => callback("Cat") }
              >
                <img
                  src={ new URL(`./images/skin9.png?as=webp&width=250`, import.meta.url).toString() }
                  alt="Cat"
                  width={ 250 }
                />
                <div className="text">Cat</div>
              </div>
            </CarouseImageSC>
          </div>
        </CarouselMobileSC>
      </SmallWrapperSC>
    </SpecialSkinsSC>
  )
}

export default memo(SpecialSkins)
