import styled, { keyframes } from "styled-components"

export interface AnimatedArrowStraightSCProps {
  delay?: number
}

const Animate = keyframes`
  0% {
    transform: translateX(-4rem) translateY(-0.063rem) rotateZ(-13deg);
  }
  25%, 100% {
    left: 100%;
    transform: translateX(4rem) translateY(-0.063rem) rotateZ(-13deg);
  }
`

export const AnimatedArrowStraightSC = styled.div<AnimatedArrowStraightSCProps>`
  > .animated-arrow-container {
    margin: 0 3rem;
    position: relative;
    display: flex;

    .earn-arrow {
      width: 1.875rem;
      height: 1.625rem;
      left: 0;
      position: absolute;
      top: -.5rem;

      transform: translateX(-4rem) translateY(-0.063rem) rotateZ(-13deg);

      animation-name: ${ Animate };
      animation-timing-function: cubic-bezier(.25, .75, .75, .25);
      animation-iteration-count: infinite;
      animation-duration: 3s;
      animation-delay: ${ props => props.delay || 0 }s;
    }

    .earn-dot {
      width: 0.5rem;
      margin: 0 1.5rem;
      height: 0.5rem;
    }
  }
`
