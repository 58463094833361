import { memo, useCallback, useRef } from "react"
import { ComboPolygonSC, ComboPolygonSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"

import hoverCombo from "../../../public/hoverCombo.svg"
import defaultCombo from "../../../public/defaultCombo.svg"
import clickCombo from "../../../public/clickCombo.svg"
import Row from "../ui/Row"

export interface ComboPolygonProps extends ComboPolygonSCProps {
  className?: string
  style?: CSSProperties
  trait: string
  text: string
  altText: string
  type?: "hover" | "click"
}

const traits: { [key: string]: any } = {
  "1": {
    small: new URL("images/trait1.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait1.png?as=webp&width=167", import.meta.url).toString(),
  },
  "2": {
    small: new URL("images/trait2.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait2.png?as=webp&width=167", import.meta.url).toString(),
  },
  "3": {
    small: new URL("images/trait3.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait3.png?as=webp&width=167", import.meta.url).toString(),
  },
  "4": {
    small: new URL("images/trait4.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait4.png?as=webp&width=167", import.meta.url).toString(),
  },
  "5": {
    small: new URL("images/trait5.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait5.png?as=webp&width=167", import.meta.url).toString(),
  },
  "6": {
    small: new URL("images/trait6.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait6.png?as=webp&width=167", import.meta.url).toString(),
  },
  "7": {
    small: new URL("images/trait7.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait7.png?as=webp&width=167", import.meta.url).toString(),
  },
  "8": {
    small: new URL("images/trait8.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait8.png?as=webp&width=167", import.meta.url).toString(),
  },
  "9": {
    small: new URL("images/trait9.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait9.png?as=webp&width=167", import.meta.url).toString(),
  },
  "10": {
    small: new URL("images/trait10.png?as=webp&width=94", import.meta.url).toString(),
    big: new URL("images/trait10.png?as=webp&width=167", import.meta.url).toString(),
  },
}

const dummy = () => void 0

function ComboPolygon({ className, style, trait, text, altText, type = "hover" }: ComboPolygonProps) {
  const ref = useRef<HTMLImageElement>(null)

  const enter = useCallback(() => {
    ref.current?.classList.add("hover")
  }, [])

  const leave = useCallback(() => {
    ref.current?.classList.remove("hover")
  }, [])

  const click = useCallback(() => {
    if (!ref.current?.classList.contains("click")) {
      ref.current?.classList.add("click")

      setTimeout(() => {
        ref.current?.classList.remove("click")
      }, 2_000)
    }
  }, [])

  return (
    <ComboPolygonSC className={ className } style={ style }>
      <div
        className="container"
        onMouseEnter={ type === "hover" ? enter : dummy }
        onMouseLeave={ type === "hover" ? leave : dummy }
        onClick={ type === "click" ? click : dummy }
        ref={ ref }
      >
        <div className="combo-default">
          <img src={ defaultCombo } alt="default combo"/>
        </div>
        <div className="combo-hover">
          <img src={ hoverCombo } alt="hover combo"/>
        </div>
        <div className="combo-click">
          <img src={ clickCombo } alt="hover combo"/>
        </div>
        <img src={ traits[trait].small } srcSet={ `${ traits[trait].big } 2x` } className="img-polygon"
             alt="combo"/>
        <Text className="combo-text">{ text }</Text>
        <Row justify="center" align="center" alignItems="center">
          <div className="alt-text">
            <Text weight={ 600 } size={ 13 } lineHeight={ 21 }>{ altText }</Text>
          </div>
        </Row>
      </div>
    </ComboPolygonSC>
  )
}

export default memo(ComboPolygon)
