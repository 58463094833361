import React, { memo, useCallback, useMemo, useState } from "react"
import { InflowSC, InflowSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../../ui/Text"
import minting from "./minting.svg"
import mintingMob from "./mintingMob.svg"
import resurrecting from "./resurrecting.svg"
import resurrectingMob from "./resurrectingMob.svg"

import Card from "../../ui/Card"
import Row from "../../ui/Row"
import Col from "../../ui/Col"
import ScaleOut from "../../ScaleOut"

const activity = new URL("activity.png?as=webp&width=1440", import.meta.url).toString()

export interface InflowProps extends InflowSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

function Inflow({ style, screenWidth }: InflowProps) {
  const [ isOpenMinting, setMintingOpen ] = useState<boolean>(false)
  const [ isOpenResurrection, setResurrectionOpen ] = useState<boolean>(false)

  const mintingImg = useMemo(() => screenWidth > 700 ? minting : mintingMob, [ screenWidth ])
  const resurrectingImg = useMemo(() => screenWidth > 700 ? resurrecting : resurrectingMob, [ screenWidth ])

  const openMinting = useCallback(() => setMintingOpen(true), [])
  const closeMinting = useCallback(() => setMintingOpen(false), [])

  const openResurrection = useCallback(() => setResurrectionOpen(true), [])
  const closeResurrection = useCallback(() => setResurrectionOpen(false), [])

  return (
    <InflowSC style={ style } alignItems="center" gap={ screenWidth > 1100 ? 60 : 24 }>
      <Text className="inflow-h1">SNK Inflow</Text>
      <Card>
        <Text className="inflow-h2">Minting snook-NFTs</Text>
        <Col gap={ 16 } className="minting-img">
          <img src={ mintingImg } alt="minting" onClick={ openMinting }/>
          <ScaleOut title="Minting snook-NFTs" img={ minting } isOpen={ isOpenMinting } onClose={ closeMinting }/>
          { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
        </Col>
        <Col>
          <Text align="left">The funds paid for minting are distributed:</Text>
          <ul>
            <Row gap={ 100 } className="minting-text">
              <Col>
                <li><b>Burned – 20%</b></li>
                <li><b>Ecosystem – 4% </b>to help pay the on-going <br/> infrastructure and gas fees</li>
              </Col>
              <Col>
                <li><b>Treasury – 76%.</b> It will then be further allocated:</li>
                <div className="sub-list">
                  <li>Pay Per Kill: <b>60%</b></li>
                  <li>Snook’s Ladder Prize pool: <b>12%</b></li>
                  <li>LP staking: <b>4%</b></li>
                </div>
              </Col>
            </Row>
          </ul>
        </Col>
      </Card>
      <Card>
        <Text className="inflow-h2">Resurrecting deceased snooks (and Fusing)</Text>
        <Col gap={ 16 } className="resurrecting-img">
          <img src={ resurrectingImg } alt="resurrecting" onClick={ openResurrection }/>
          <ScaleOut
            title="Resurrecting deceased snooks (and Fusing)"
            img={ resurrecting }
            isOpen={ isOpenResurrection }
            onClose={ closeResurrection }
          />
          { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
        </Col>
        <Text>Resurrection fees and Fusion as well, all go directly to the Treasury.</Text>
      </Card>
      <Card>
        <Row className="card-row">
          { screenWidth < 901 && (
            <Col gap={ 24 }>
              <Text className="inflow-h2">Activity-tied Staking</Text>
              <img src={ activity } alt="activity" className="activity-img"/>
            </Col>
          ) }
          <Col gap={ 24 }>
            { screenWidth > 900 && <Text className="inflow-h2">Activity-tied Staking</Text> }
            <Text>This small and uber-cool crypto-innovation is currently only
              applicable to the Themed Rooms in the Big Boys Table game
              mode. Basically, it requires anyone wanting to setup a themed
              Room to stake a certain amount of SNK.
            </Text>
            <Text>
              This is the first ever, as far as we know, example of tying together
              the staking of a cryptocurrency with a valuecreating activity (i.e.,
              playing Snook). It’s cool because it reflects a continuous
              commitment that depends on the fun of playing Snook (rather
              than just a financial position).
            </Text>
          </Col>
          <div>
            { screenWidth > 900 && <img src={ activity } alt="activity" className="activity-img"/> }
          </div>
        </Row>
      </Card>
    </InflowSC>
  )
}

export default memo(Inflow)
