import styled from "styled-components"
import Col from "../../ui/Col";
import pxToRem from "../../../utils/pxToRem";

const bgHex = new URL("../../../../public/bg-hex.png?as=webp&width=1080", import.meta.url).toString()

export interface InflowSCProps {
}

export const InflowSC = styled(Col)<InflowSCProps>`
  padding-top: ${pxToRem(265)};
  position: relative;
  z-index: 1;
  background-color: #103B7B;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    z-index: -1;
    background: linear-gradient(180deg, #103B7B 0%, rgba(0, 32, 81, 0) 50%, #103B7B 90%),
    radial-gradient(50% 50% at 50% 50%, #103B7B 0%, rgba(16, 59, 123, 0) 100%),
    url(${ bgHex }), #103B7B;
    background-blend-mode: normal, normal, luminosity, normal;
  }
  
  .inflow-h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${pxToRem(72)};
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }
  
  .inflow-h2 {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 900;
    font-size: ${pxToRem(44)};
    line-height: 98%;
    letter-spacing: -0.05em;
    color: #FDC500;
  }

  .card-row {
    gap: 60px;
    > div {
      flex: 1;

      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  
  .card-row p {
    text-align: left;
  }
  
  .minting-img {
    width: 1320px;
  }
  
  .click-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  
  .resurrecting-img {
    width: 1320px;
  }
  
  .activity-img {
    width: 100%;
    height: fit-content;
  }
  
  ul {
    text-align: left;
    padding-left: 26px;
    margin: 8px 0 0 0;
  }
  
  li {
    font-weight: 400;
    font-size: 16px;${pxToRem(16)}
    line-height: 24px;${pxToRem(24)}
  }
  
  .sub-list {
    color: #FDC500;
  }

  @media screen and (max-width: 1600px) {

    .minting-img {
      width: 100%;
    }

    .resurrecting-img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1100px) {
    padding-top: ${pxToRem(200)};
    .inflow-h1 {
      font-size: ${pxToRem(36)};
    }
    
    .inflow-h2 {
      font-size: ${pxToRem(24)};
    }

    .card-row {
      gap: 30px;
    }
  }

  @media screen and (max-width: 900px) {
    .card-row {
      flex-direction: column;
      gap: 30px;
      align-items: center;
    }

    .card-row  .inflow-h2 {
      text-align: center;
    }
  }

  @media screen and (max-width: 700px) {
    padding-top: ${pxToRem(140)};
    
    .inflow-h1 {
      font-size: ${pxToRem(36)};
    }
    
    .minting-text {
      flex-direction: column;
      gap: 0;
    }
    
  }
`
