import styled from "styled-components"
import pxToRem from "../../utils/pxToRem"

export interface HeaderSCProps {

}

export const HeaderSC = styled.div<HeaderSCProps>`
  .header-wrapper {
    position: relative;
    min-width: 100vw;

    & > .icons {
      position: fixed;
      z-index: 999;
      right: ${ pxToRem(41) };
      top: ${ pxToRem(40) };
    }
  }

  .logo {
    width: 224px;
    height: 68px;
    position: fixed;
    z-index: 999;
    left: ${ pxToRem(36) };
    top: ${ pxToRem(40) };

    cursor: pointer;
  }

  @media screen and (max-width: 1008px) {
    .logo {
      width: 190px;
      height: 60px;
    }
  }

  @media screen and (max-width: 700px) {
    .logo {
      width: 135px;
      height: 41px;
      top: ${ pxToRem(29) };
      left: ${ pxToRem(22) };
    }
    
    .header-wrapper {
      & > .icons {
        right: ${ pxToRem(22) };
        top: ${ pxToRem(22) };
      }
    }

    .socials-icons {
      display: none;
    }

    .mobile-filter {
      width: 100vw;
      height: 100px;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      border-bottom: 2px solid #FDC500;
      z-index: 999;
    }
  }
`
