import { memo } from "react"
import { GetSNKSC, GetSNKSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import Col from "../ui/Col"

export interface GetSNKProps extends GetSNKSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

const snook = {
  big: new URL("snook.png?as=webp&width=1400", import.meta.url).toString(),
  small: new URL("snook.png?as=webp&width=700", import.meta.url).toString(),
}

function GetSNK({ className, style, screenWidth }: GetSNKProps) {
  return (
    <GetSNKSC className={ className } style={ style } justify="center">
      <Col className="snook">
        <img src={ snook.small } srcSet={ `${ snook.big } 2x` } alt="snook"/>
      </Col>
      <Col className="content" gap={ 8 }>
        <Text className="h1">Get <span className="colored-title">SNK</span></Text>
        <Col gap={ 24 }>
          <Text className="title-description">Snook’s official in-game token is { screenWidth < 701 ? <br/> : "" }
            <b>SNK</b>, and as much as money { screenWidth < 701 ? <br/> : "" }
            don’t grow { screenWidth > 700 ? <br/> : "" }
            on trees in real life, so { screenWidth < 701 ? <br/> : "" }
            does in here. But it does make the { screenWidth < 701 ? <br/> : "" }
            game go round.</Text>
          <Col gap={ screenWidth > 1008 ? 24 : 6 }>
            <Text className="title-description"><b>SNK</b> available on:</Text>
            <div className="left">
              <Text className="title-description">
                <a href="https://quickswap.exchange/#/swap?inputCurrency=0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309" className="title-description-bold" target="_blank">QuickSwap (Polygon)</a>
              </Text>
            </div>
            <div className="left">
              <Text className="title-description">
                <a href="https://www.gate.io/trade/SNK_USDT" className="title-description-bold" target="_blank">Gate.io (Polygon)</a>
              </Text>
            </div>
          </Col>
        </Col>
      </Col>
      <Col className="snook-mobile">
        <img src={ snook.small } srcSet={ `${ snook.big } 2x` } alt="snook"/>
      </Col>
    </GetSNKSC>
  )
}

export default memo(GetSNK)
