import React, { memo } from "react"
import { BBTIsItRightSC, BBTIsItRightSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Col from "../../ui/Col"
import Text from "../../ui/Text"
import RoundButton from "../../RoundButton"
import { RoundButtonTypeEnum } from "../../RoundButton/styled"
import Row from "../../ui/Row"

export interface BBTIsItRightProps extends BBTIsItRightSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

const coins = new URL("./coins.png?as=webp", import.meta.url).toString()
const coin1 = new URL("./coin1.png?as=webp", import.meta.url).toString()
const coin2 = new URL("./coin2.png?as=webp", import.meta.url).toString()

const rocket = {
  small: new URL("./rocket.png?as=webp", import.meta.url).toString(),
  big: new URL("./rocket-big.png?as=webp", import.meta.url).toString(),
}

function BBTIsItRight({ style, screenWidth }: BBTIsItRightProps) {
  return (
    <Row justify="center">
      <BBTIsItRightSC style={ style } alignItems="center">
        <Row className="is-right-wrapper">
          <Col className="is-right-text-block" gap={ screenWidth > 900 ? 12 : 24 }>
            <Text className="is-right-h2"><span className="colored-title">BBT-TR:</span> { screenWidth < 1401 && <br/> }Is
              it right for me?</Text>
            <Text className="is-right-desc">Yeah. Most def.</Text>
            <a href="https://calendly.com/cryptoanalyst/snook-bbt-themed-rooms" target="_blank"
               className="is-right-btn">
              <RoundButton color={ RoundButtonTypeEnum.ORANGE }>APPLY NOW</RoundButton>
            </a>
          </Col>
          <Col className="is-right-for-mobile">
          </Col>
        </Row>

        <div className="coins">
          <img src={ coins } alt={ "coins" } className="coins-img"/>
          <img src={ coin1 } alt={ "coin1" } className="coin1-img"/>
          <img src={ coin2 } alt={ "coin2" } className="coin2-img"/>
        </div>

        <Row className="themed-bottom-blocks" alignItems="end">
          <img src={ rocket.small } srcSet={ `${ rocket.big } 2x` } alt="rocket" className="rocket-img"/>

        </Row>
      </BBTIsItRightSC>
    </Row>
  )
}

export default memo(BBTIsItRight)
