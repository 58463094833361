import styled, { keyframes } from "styled-components"
import pxToRem from "../../utils/pxToRem"
import { ComboPolygonSC } from "../ComboPoligon/styled"

export interface SpecialCombosSCProps {
}

const animation = keyframes`
  0%, 100% {
    transform: translate(-50%, 0) scale(0.93);
  }

  50% {
    transform: translate(-50%, -${ pxToRem(5) }) scale(0.94);
  }
`

export const SpecialCombosSC = styled.div<SpecialCombosSCProps>`
  padding: 4rem 0;

  .combos-btn {
    margin-top: ${ pxToRem(80) };
    width: ${ pxToRem(371) };
    height: ${ pxToRem(100) };
    border: none;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), #35E328;
    background-blend-mode: overlay, normal;
    box-shadow: inset 0 -${ pxToRem(1) } ${ pxToRem(4) } #000000, inset 0 ${ pxToRem(1) } ${ pxToRem(4) } #FFFFFF;
    border-radius: ${ pxToRem(45) };
  }

  .dragon {
    position: relative;
    margin: 0 auto;
    width: ${ pxToRem(880) };
    height: ${ pxToRem(580) };
    z-index: 3;
  }

  .dragon-img {
    position: absolute;
    z-index: 3;
    top: -18%;
    left: 50%;
    transform: translate(-50%, 0) scale(0.93);

    animation-delay: .5s;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: ${ animation };
  }

  .lines {
    position: absolute;
    z-index: 3;
    top: ${ pxToRem(110) };
    left: -${ pxToRem(215) };
    transform: scale(0.9);
  }

  .r-line {
    position: absolute;
    z-index: 1;
    top: ${ pxToRem(180) };
    right: -${ pxToRem(204) };
  }

  .combo-1 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(70) };
    right: ${ pxToRem(50) };
  }

  .combo-2 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(190) };
    right: -${ pxToRem(77) };
  }

  .combo-3 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(291) };
    right: ${ pxToRem(50) };
  }

  .combo-4 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(400) };
    right: -${ pxToRem(77) };
  }

  .combo-5 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(510) };
    right: ${ pxToRem(50) };
  }

  .combo-6 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(75) };
    left: -${ pxToRem(50) };
  }

  .combo-7 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(183) };
    left: -${ pxToRem(214) };
  }

  .combo-8 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(290) };
    left: -${ pxToRem(50) };
  }

  .combo-9 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(400) };
    left: -${ pxToRem(214) };
  }

  .combo-10 {
    position: absolute;
    z-index: 11;
    top: ${ pxToRem(510) };
    left: -${ pxToRem(50) };
  }

  .combo-mobile-container {
    display: none;
    position: absolute;
    z-index: 12;

    left: calc(50% - 45px);
    top: calc(50% - 45px);
    transform: scale(.7);

    ${ ComboPolygonSC } {
      position: relative;
      z-index: 12;

      transition: transform .3s ease-in-out;

      &.hidden {
        opacity: 0;
        transform: translateX(0) translateY(0) !important;
      }
    }
  }

  .dragon-clickable {
    display: none;
  }

  .combo-text-hint {
    display: none;
  }

  @media screen and (max-width: 1300px) {
    .combo, .lines, .r-line {
      display: none;
    }

    .title-description {
      padding: 0 3rem;
    }

    .combo-text-hint {
      display: block;
      transition: opacity .3s;
      opacity: 1;

      transform: translateY(-2rem) !important;

      &.hidden {
        opacity: 0;
      }
    }

    .dragon-clickable {
      display: block;
      //background-color: rgba(255, 0, 0, .5);
      position: absolute;
      z-index: 14;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .combo-mobile-container {
      display: block;
    }

    .dragon {
      height: auto;
      width: auto;
      max-width: 1008px;

      .dragon-img {
        top: 5vw;
        position: relative;
        left: auto;
        transform: translateX(0) translateY(0);
        animation-name: none;
      }
    }
  }

  @media screen and (max-width: 1008px) {
    .combo-mobile-container {
      left: calc(50% - 40px);
      top: calc(50% - 40px);
      transform: scale(.6);
    }
  }

  @media screen and (max-width: 860px) {
    .dragon {
      .dragon-img {
        top: 5vw;
        width: 100%;
      }
    }
  }
`

export const ButtonSC = styled.div`
  position: relative;
  z-index: 5;
  margin: 4rem 0 0;
`
