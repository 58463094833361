import styled from "styled-components"
import pxToRem from "../../utils/pxToRem"
import { TextSC } from "../ui/Text/styled"

export interface ComboPolygonSCProps {
}

export const ComboPolygonSC = styled.div<ComboPolygonSCProps>`
  .img-polygon {
    position: absolute;
    z-index: 10;
    top: 25px;
    left: 19px;
    width: 94px;
    height: 96px;

    transition: transform .3s ease-in-out;
    transform: scale(0.9);
  }

  .combo-text {
    position: absolute;
    z-index: 10;
    bottom: -160px;
    left: 57px;

    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: ${ pxToRem(20) };
    line-height: ${ pxToRem(25) };
    letter-spacing: -0.05em;

    color: #245FB7;
  }

  .container {
    position: absolute;
    width: 192px;
    transform: scale(0.9);
  }

  .combo-hover, .combo-default, .combo-click {
    position: absolute;
    top: 0;
    left: 0;
  }

  .combo-default {
    transition: transform 0.3s, opacity 0.3s, visibility 0.3s;
  }

  .combo-hover {
    top: -${ pxToRem(10) };
    left: -${ pxToRem(1) };
    opacity: 0;
    width: 134px;
    overflow: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }

  .combo-click {
    top: ${ pxToRem(1) };
    opacity: 0;
    width: 134px;
    overflow: hidden;
    transition: transform 0.3s;

    > img {
      width: 134px;
    }
  }

  .combo-hover img {
    width: 192px;
    max-width: max-content;
  }

  .alt-text {
    position: absolute;

    opacity: 0;

    transition: opacity .3s ease-in-out;
  }

  .hover {
    .img-polygon {
      transform: translateX(${ pxToRem(5) }) rotate(-10deg) translateY(-${ pxToRem(15) }) scale(1.3);
    }

    .alt-text {
      padding: ${ pxToRem(3) } ${ pxToRem(8) };
      background: #245FB7;
      border: ${ pxToRem(2) } solid #3284FF;
      border-radius: ${ pxToRem(8) };

      bottom: -175px;
      left: 91px;
      z-index: 20;
      width: max-content;

      opacity: 1;
    }

    .combo-hover {
      opacity: 1;
    }
  }

  .click {
    .img-polygon {
      transform: translateY(-${ pxToRem(15) }) scale(1.7);
    }

    .alt-text {
      bottom: -175px;
      left: calc(50% - 28px);
      z-index: 20;
      width: max-content;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), linear-gradient(0deg, #D1E4FF, #D1E4FF), #D9D9D9;
      background-blend-mode: overlay, normal, normal;
      border: 1px solid #FFFFFF;
      border-radius: 3px;

      transform: translateX(-50%);
      letter-spacing: -0.03em;
      padding: .5rem 1rem;

      opacity: 1;

      ${ TextSC } {
        font-size: 1.5rem;
        color: #245FB7;
      }
    }

    .combo-default {
      opacity: 0;
      transform: scale(1.3);
    }

    .combo-click {
      opacity: 1;
      transform: scale(1.3);
    }
  }
`
