import React, { CSSProperties, memo } from "react"
import { TraitSC } from "./styled"
import Text from "../../ui/Text"

import RoundButton from "../../RoundButton"
import { RoundButtonTypeEnum } from "../../RoundButton/styled"
import Col from "../../ui/Col"

export interface TraitProps {
  className?: string
  style?: CSSProperties
}

const Image1 = new URL("./1.png?as=webp&width=267", import.meta.url).toString()
const Image2 = new URL("./2.png?as=webp&width=360", import.meta.url).toString()
const Image3 = new URL("./3.png?as=webp&width=414", import.meta.url).toString()
const Image4 = new URL("./4.png?as=webp&width=227", import.meta.url).toString()
const Image5 = new URL("./5.png?as=webp&width=284", import.meta.url).toString()
const Image6 = new URL("./6.png?as=webp&width=476", import.meta.url).toString()

function Trait({ className, style }: TraitProps) {
  return (
    <TraitSC className={ className } style={ style } alignItems="center">
      <Col alignItems="center" gap={ 24 } className="trait-wrapper">
        <Text className="trait-h1">Trait Economy</Text>
        <Text>
          The snook-NFT derives its face-value from the SNK required
          for its minting. However, the mechanism used to appreciate
          its value is based on its skilled use in gameplay. This in turn
          is manifested in Traits associated with each snook-NFT.
          That’s why the Traits are, effectively the metric measuring
          skill and engagement, and why we see them as
          representing the
          underlying value for the snook-NFT. This
          means that they prop the entire economy and act as
          intrinsic collaterals for the circulating value. Also, there’s a reason why it’s not called
          <b>“the Special Skin economy”</b>. From a purely economic POV, the fundamental
          unit of measurement in the NFT side of the economy is the
          Trait. In fact, from that POV, Special Skins, or
          snooks are nothing more than Trait repositories.
        </Text>
        <a href="https://whitepaper.playsnook.com/economy-overview/trait-economy" target="_blank">
          <RoundButton color={ RoundButtonTypeEnum.ORANGE }>WHITEPAPER</RoundButton>
        </a>
      </Col>
      <img src={ Image1 } alt="img1" className="img-1"/>
      <img src={ Image2 } alt="img2" className="img-2"/>
      <img src={ Image3 } alt="img3" className="img-3"/>
      <img src={ Image4 } alt="img4" className="img-4"/>
      <img src={ Image5 } alt="img5" className="img-5"/>
      <img src={ Image6 } alt="img6" className="img-6"/>
    </TraitSC>
  )
}

export default memo(Trait)
