import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../../ui/Col"

export interface OutflowSCProps {
}

export const OutflowSC = styled(Col)<OutflowSCProps>`
  padding: ${ pxToRem(120) } 0 ${ pxToRem(100) } 0;
  background: radial-gradient(50% 50% at 50% 50%, #002051 0%, rgba(0, 32, 81, 0) 100%), #103B7B;

  .outflow-h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(72) };
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
    padding-bottom: ${ pxToRem(24) };
  }

  .outflow-description {
    font-weight: 700;
    font-size: ${ pxToRem(16) };
    line-height: ${ pxToRem(24) };
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .outflow-img {
    width: 1572px;
  }

  .outflow-text {
    padding: 0 ${ pxToRem(32) };
    gap: 80px;
  }

  .outflow-text p {
    text-align: left;
    line-height: ${ pxToRem(30) };
  }

  @media screen and (max-width: 1600px) {
    .outflow-img {
      width: 90%;
    }

    .outflow-text {
      width: 90%;
      padding: 0;
    }
  }

  @media screen and (max-width: 1100px) {
    padding: ${ pxToRem(80) } 0;

    .outflow-h1 {
      font-size: ${ pxToRem(36) };
    }
  }

  @media screen and (max-width: 900px) {
    .outflow-text {
      flex-direction: column;
      gap: 24px;
    }
  }
`
