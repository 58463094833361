import { memo } from "react"
import { ButtonSC, ContainerSC, ImageSC, ScaleOutSC, ScaleOutSCProps, TitleSC } from "./styled"
import { CSSProperties } from "styled-components"
import Modal from "react-modal"
import HexagonButton from "../HexagonButton"
import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../HexagonButton/styled"

export interface ScaleOutProps extends ScaleOutSCProps {
  className?: string
  style?: CSSProperties
  isOpen: boolean
  onClose: () => void
  title: any
  img: any
}

const styles = { overlay: { zIndex: 1000 }, content: { inset: 0, padding: 0, border: 0, borderRadius: 0 } }

function ScaleOut({ className, style, isOpen, onClose, img, title, width }: ScaleOutProps) {
  return (
    <Modal isOpen={ isOpen } style={ styles }>
      <ContainerSC>
        <ScaleOutSC className={ className } style={ style } justify="center" alignItems="center">
          <TitleSC>
            { title }
          </TitleSC>
          <ImageSC width={ width }>
            <img src={ img } alt="Image"/>
          </ImageSC>
          <ButtonSC onClick={ onClose }>
            <HexagonButton
              icon={ HexagonButtonIconEnum.CROSS }
              type={ HexagonButtonTypeEnum.ORANGE }
            />
          </ButtonSC>
        </ScaleOutSC>
      </ContainerSC>
    </Modal>
  )
}

export default memo(ScaleOut)
