import styled, { keyframes } from "styled-components"
import Col from "../ui/Col"
import polygon from "./images/polygon.svg"

const red = new URL("./images/red.png?as=webp", import.meta.url).toString()
const yellow = new URL("./images/yellow.png?as=webp", import.meta.url).toString()
const blue = new URL("./images/blue.png?as=webp", import.meta.url).toString()

import pxToRem from "../../utils/pxToRem"

export interface NavigationSCProps {

}

export const NavigationTopSC = styled(Col)`
  background: url(${ polygon }) top;
  position: relative;

  height: 80px;
  width: 398px;

  z-index: 3;
  top: 1px;
`

export const NavigationMiddleSC = styled(Col)`
  background: url(${ polygon }) center;
  position: relative;

  width: 398px;
  z-index: 4;

  a {
    color: #FFFFFF;
    text-decoration: none;
  }

  .mob-nav-socials {
    padding-left: 30px;
  }
`

export const NavigationBottomSC = styled(Col)`
  background: url(${ polygon }) bottom;
  position: relative;

  height: 80px;
  width: 398px;
  z-index: 3;

  bottom: 1px;
`

export const NavigationLinkSC = styled(Col)`
  font-weight: 700;
  line-height: ${ pxToRem(28) };
  height: calc(${ pxToRem(28) } + 4vh);
  font-size: ${ pxToRem(20) };

  text-align: left;
  padding: 2vh 0;

  transform: translateX(10%);

  cursor: pointer;

  transition: transform .3s ease-in-out, color .3s ease-in-out, background .3s ease-in-out;
  
  outline: none;

  
  &:hover {
    color: #FDC500;
    transform: translateX(15%) scale(1.1);

    &::after {
      content: "";
      background: rgba(184, 199, 255, 0.35);
      filter: blur(2rem);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  position: relative;
`

export const NavigationSC = styled(Col)<NavigationSCProps>`
  position: fixed;

  z-index: 999;

  top: 60%;
  transform: translateY(-60%) translateX(30%);
  right: 0;

  width: 398px;

  transition: transform .4s ease-in-out;

  &.hidden {
    transform: translateY(-60%) translateX(120%);
  }

  &.visible {
    transform: translateY(-60%) translateX(30%);
  }
`

const ball = keyframes`
  0%, 100% {
    transform: scale(.5) translateY(0) translateX(0);
  }

  33.3% {
    transform: scale(.5) translateY(10%);
  }

  66.6% {
    transform: scale(.5) translateY(5%) translateX(5%);
  }
`

export const RedBallSC = styled.div`
  width: 241px;
  height: 241px;

  transform: scale(.5);
  position: absolute;
  top: -35%;
  left: -30%;

  z-index: 5;

  background: url(${ red }) no-repeat;

  animation-name: ${ ball };
  animation-iteration-count: infinite;
  animation-duration: 10s;

  animation-delay: -10s;
`

export const YellowBallSC = styled(RedBallSC)`
  width: 163px;
  height: 163px;

  transform: scale(.5);
  top: -10%;
  left: 0;

  background: url(${ yellow }) no-repeat;

  animation-delay: -6.6s;
`

export const BlueBallSC = styled(RedBallSC)`
  width: 464px;
  height: 464px;

  transform: scale(.5);
  top: -200%;
  left: 10%;

  background: url(${ blue }) no-repeat;

  animation-delay: -3.3s;
`