import styled from 'styled-components'
import pxToRem from "../../../utils/pxToRem";

export interface ColSCProps {
    gap?: number;
    justify?: Justify;
    align?: Align;
    alignItems?: Align;
}

type Justify = 'end' | 'start' | 'center' | 'space-around' | 'space-between' | 'space-evenly'
type Align = 'end' | 'start' | 'center' | 'baseline' | 'stretch'

export const ColSC = styled.div<ColSCProps>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => (gap ? `${pxToRem(gap)}` : 0)};
    justify-content: ${({ justify}) => (justify ? justify : 'start')};
    align-content: ${({ align}) => (align ? align : 'start')};
    align-items: ${({ alignItems}) => (alignItems ? alignItems : '')};
`