import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../../ui/Col"

const bgHex = new URL("../../../../public/bg-hex.png?as=webp&width=1080", import.meta.url).toString()

export interface SupplySCProps {
}

export const SupplySC = styled(Col)<SupplySCProps>`
  padding: ${ pxToRem(100) } 0;
  position: relative;
  z-index: 1;
  background-color: #012254;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    z-index: -1;
    background: linear-gradient(180deg, #3284FF 0%, rgba(50, 132, 255, 0) 25%),
    radial-gradient(50% 50% at 50% 50%, #3284FF 0%, rgba(16, 59, 123, 0) 100%),
    url(${ bgHex }), #3284FF;
    background-blend-mode: normal, normal, luminosity, normal;
  }

  .supply-block-width {
    width: ${ pxToRem(1440) };
    align-items: center;

    overflow: hidden;
    
    p, ul, li {
      text-align: left;
    }

    ul {
      margin: 0;
      padding-left: ${ pxToRem(26) };
    }

    li {
      color: #FDC500;
    }
  }

  .supply-h1 {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 900;
    font-size: ${ pxToRem(72) };
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }

  .supply-h2 {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 900;
    font-size: ${ pxToRem(44) };
    line-height: 98%;
    letter-spacing: -0.05em;
    color: #FDC500;
  }

  .supply-h3 {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 900;
    font-size: ${ pxToRem(40) };
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
  }

  .supply-description {
    font-weight: 700;
    font-size: ${ pxToRem(16) };
    line-height: ${ pxToRem(30) };
  }

  .supply {
    width: 1320px;
  }

  .supply2 {
    width: 616px;
    margin-left: ${ pxToRem(30) };
  }

  .supply3 {
    width: 616px;
    margin-right: ${ pxToRem(30) };
  }

  @media screen and (max-width: 1600px) {
    .supply {
      width: 100%;
    }

    .supply-description {
      width: 90vw;
    }

    .supply-block-width {
      width: 90vw;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 1400px) {
    .supply2 {
      width: 40vw;
    }

    .supply3 {
      width: 40vw;
    }
  }

  @media screen and (max-width: 1100px) {
    padding: ${ pxToRem(60) } 0;

    .supply-h1 {
      font-size: ${ pxToRem(36) };
    }

    .supply-h2 {
      font-size: ${ pxToRem(24) };
    }

    .supply-h3 {
      font-size: ${ pxToRem(24) };
    }

    .supply-block-width {
      flex-direction: column;
      gap: ${ pxToRem(24) };
    }

    .supply2 {
      width: 90vw;
      margin: 0;
    }

    .supply3 {
      width: 90vw;
      margin: 0;
    }
  }
`
