import styled, { keyframes } from "styled-components"
import Row from "../ui/Row"
import pxToRem from "../../utils/pxToRem"

const footer = new URL("../../../public/footer.png?as=webp", import.meta.url).toString()

export interface GetSNKSCProps {
}

const Animate = keyframes`
  0%, 100% {
    transform: rotateZ(0deg) scale(.8) translateX(-.5rem) translateY(.5rem);
  }

  50% {
    transform: rotateZ(2deg) scale(.81) translateX(.5rem) translateY(-.5rem);
  }
`

export const GetSNKSC = styled(Row)<GetSNKSCProps>`
  background: url(${ footer }), #1D155B;
  gap: 4rem;
  padding-bottom: 4rem;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;

  .snook-mobile {
    display: none;
  }

  a {
    color: #fff;

    transition: color .3s ease-in-out;

    &:hover {
      color: #FDC500;
    }
  }

  .snook {
    flex-grow: 1;

    > img {
      transform: rotateZ(0deg) scale(.8) translateX(-.5rem) translateY(.5rem);

      animation-name: ${ Animate };
      animation-duration: 5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;

      height: 100%;
      width: 100%;
    }
  }

  .content {
    flex-grow: 1;
    padding-top: 20vh;
  }

  .content p {
    text-align: left;
    padding-right: 2rem;
  }

  @media screen and (max-width: 1008px) {
    gap: 1rem;

    .snook {
      > img {
        height: 85%;
        width: 85%;
      }
    }

    .content {
      padding-top: 5vh;
      padding-bottom: 3vh;
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    .snook {
      display: none;
    }

    .snook-mobile {
      display: flex;
      padding-left: ${ pxToRem(10) };
      padding-bottom: ${ pxToRem(15) };

      > img {
        transform: rotateZ(0deg) scale(.8) translateX(-.5rem) translateY(.5rem);
        animation-name: ${ Animate };
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        width: 268.7px;
        height: 280px
      }
    }

    .content {
      padding-top: 10vh;
      padding-bottom: 0;
      align-items: center;
    }

    .content p {
      text-align: center;
      padding-right: 0;
    }

    .left p {
      text-align: center;
    }
  }
`
