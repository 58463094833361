import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../Col";

export interface CardSCProps {
}

export const CardSC = styled(Col)<CardSCProps>`
  align-items: center;
  padding: ${pxToRem(48)} ${pxToRem(60)};
  gap: ${pxToRem(48)};
  width: ${pxToRem(1440)};
  overflow-x: hidden;
  background: radial-gradient(50.15% 50% at 50% 50%, rgba(16, 59, 123, 0.3) 0%, rgba(16, 59, 123, 0) 100%), #00122C;
  background-blend-mode: screen, normal;
  border-bottom: ${pxToRem(4)} solid #FDC500;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: ${pxToRem(16)};

  @media screen and (max-width: 1600px) {
    width: 90vw;
  }

  @media screen and (max-width: 1100px) {
    padding: ${pxToRem(24)} ${pxToRem(17)};
    gap: ${pxToRem(24)};
  }
`
