import {CSSProperties, memo, ReactNode} from 'react'
import { TextSC } from './styled'

export interface TextProps {
  className?: string
  style?: CSSProperties
  children?: ReactNode
  color?: string
  weight?: number
  size?: number | string
  lineHeight?: number | string
  letterSpacing?: number | string
  uppercase?: boolean
  align?: 'center' | 'left' | 'right'
}

function Text({
  className,
  style,
  children,
  color,
  lineHeight,
  letterSpacing,
  weight,
  size,
  uppercase,
  align,
  ...rest
}: TextProps) {
  return (
    <TextSC
      className={className}
      color={color}
      weight={weight}
      size={size}
      style={style}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      uppercase={uppercase}
      align={align}
      {...rest}
    >
      {children}
    </TextSC>
  )
}

export default memo(Text)
