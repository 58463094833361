import { memo } from "react"
import { GameModesCardSC, GameModesCardSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import Col from "../ui/Col"

export interface GameModesCardProps extends GameModesCardSCProps {
  className?: string
  style?: CSSProperties
  title?: string
  description?: any
  img?: any
  srcSet?: any
}


function GameModesCard({ className, style, title, description, img, srcSet }: GameModesCardProps) {
  return (
    <GameModesCardSC className={ className } style={ style }>
      <img src={ img } srcSet={ srcSet } alt="img" className="img-title"/>
      <Col gap={ 12 }>
        <div className="title">{ title }</div>
        <div className="description">{ description }</div>
      </Col>
    </GameModesCardSC>
  )
}

export default memo(GameModesCard)
