import { memo } from "react"
import { AnimatedArrowPointingSC, AnimatedArrowPointingSCProps, ArrowSC } from "./styled"
import { CSSProperties } from "styled-components"

export interface AnimatedArrowPointingProps extends AnimatedArrowPointingSCProps {
  className?: string
  style?: CSSProperties
}

function AnimatedArrowPointing({ className, style, amount, speed }: AnimatedArrowPointingProps) {
  return (
    <AnimatedArrowPointingSC className={ className } style={ style }>
      { new Array(amount).fill(null).map((_, i) => <ArrowSC key={ i } index={ i } amount={ amount } speed={ speed }/>) }
    </AnimatedArrowPointingSC>
  )
}

export default memo(AnimatedArrowPointing)
