import React, { memo, useEffect } from "react"
import { CSSProperties } from "styled-components"
import { ThemedRoomsSC, ThemedRoomsSCProps } from "./styled"
import TopRoomBlock from "../../components/ThemedRooms/TopRoomBlock"
import BBT from "../../components/ThemedRooms/BBT"
import BBTIsITRight from "../../components/ThemedRooms/BBTIsITRight"
import Participation from "../../components/ThemedRooms/Participation"

export interface ThemedRoomsProps extends ThemedRoomsSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

declare const window: any
declare const YT: any

function ThemedRooms({ style, screenWidth }: ThemedRoomsProps) {
  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      const tag1 = document.getElementById("bbt-video")!
      tag1.style.width = ""
      tag1.style.height = ""

      const tag2 = document.getElementById("top-video")!
      tag2.style.width = ""
      tag2.style.height = ""

      const common = {
        autoplay: 0,
        playsinline: 1,
        loop: 1,
        controls: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
      }

      new YT.Player("bbt-video", {
        width: "100%", height: "100%", videoId: "MgRYt9l_IqE",
        playerVars: { ...common, playlist: "MgRYt9l_IqE" },
      })

      new YT.Player("top-video", {
        width: "100%", height: "100%", videoId: "qsJIn78yT2Y",
        playerVars: { ...common, playlist: "qsJIn78yT2Y" },
      })
    }

    if (!window.YT) {
      const tag = document.createElement("script")

      tag.src = "https://www.youtube.com/iframe_api"
      const firstScriptTag = document.getElementsByTagName("script")[0]
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)

      console.log(1)

      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
    } else {
      onYouTubeIframeAPIReady()
    }
  }, [])

  return (
    <ThemedRoomsSC style={ style }>
      <TopRoomBlock screenWidth={ screenWidth }/>
      <BBT screenWidth={ screenWidth }/>
      <BBTIsITRight screenWidth={ screenWidth }/>
      <Participation/>

    </ThemedRoomsSC>
  )
}

export default memo(ThemedRooms)
