import React, { memo } from "react"
import { HashLink as Link } from "react-router-hash-link"

import Text from "../../components/ui/Text"
import Terms from "../../components/Terms"

function TermsAndConditions() {
  return (
    <Terms title="Terms and Conditions" updated="July 3rd, 2022" effective="July 3rd, 2022">
      <Text className="h3-term">1. ABOUT SNOOK</Text>

      <Text>
        Welcome to <b>Snook</b>, a multichain, play-to-earn "Snake"-like IO game, available through <a
        href="https://play.snook.gg">https://play.snook.gg</a>, that
        rewards performance achieved through hard work, commitment, skill and talent (the "<b>Game</b>" or "<b>Snook</b>").
      </Text>

      <Text>
        These Terms and Conditions (the "<b>T&C</b>") apply to our website located at <a
        href="https://www.snook.gg">https://www.snook.gg</a> (the "<b>Website</b>") and to
        Snook. Please read them carefully before using the Website or playing Snook. The Website and Snook are property
        of and operated by SGM Innovation Ltd, a company incorporated under the laws of Saint Vincent and the Grenadines
        ("<b>SGM</b>").
      </Text>

      <Text>
        BY USING THIS SITE AND/OR PLAYING SNOOK, YOU AGREE TO THESE TERMS AND CONDITIONS. IN CASE YOU DO NOT UNDERSTAND
        OR AGREE TO ANY OF THE TERMS, YOU SHOULD NOT ACCESS THE WEBSITE OR PLAY SNOOK.
      </Text>

      <Text>
        We reserve the right, in our sole discretion, to change, modify, add or remove portions of these T&C, at any
        time. If we make any modifications, we will notify you by updating the date at the top of these T&C and by
        maintaining online the current version of these T&C. Nonetheless, it is your responsibility to check these T&C
        periodically for changes.
      </Text>

      <Text>
        All modifications will be effective when they are posted, and your continued use of the Website or Snook will
        serve as confirmation of your acceptance of those modifications. If you do not agree with any modifications to
        these T&C, do not use the Website or play the Game.
      </Text>

      <Text className="h3-term">2. THE GAMEPLAY</Text>

      <Text>
        The core gameplay of Snook is to collect color, pattern and SWAG/wearable traits (hereinafter referred to as
        "<b>Traits</b>"). When a Player mints a new snook, the non-fungible token/game-character that is used in the
        Game (hereinafter referred to as "<b>Snook</b>"), it comes with a random Trait. Collecting Traits changes the
        appearance of your snook, and this is how you unlock specific combinations of Traits (hereinafter referred to as
        "<b>Special Skins</b>"). Special Skins are hidden advanced in-game characters that get unlocked when a player
        collects a certain combination of Traits. Once that happens and the final needed Trait is collected, the snook
        changes into the Special skin.
      </Text>

      <Text>
        The Game has three (3) modes: Battle Arena, Big Boys Table and Snook Ladder.
      </Text>

      <Text>
        <b>Battle Arena</b> is a time-limited gaming mode whose purpose is to kill other snooks, collect traits, and
        mutate your snook to a special skin while earning SNK for each kill.
      </Text>

      <Text>
        In order to play Battle Arena, the Player needs to connect a wallet and mint a snook. To enter the Game and mint
        a snook, the Player shall pay a minting fee with SNK token, the utility token on top of which the Game is built
        (hereinafter referred to as "<b>SNK</b>"). As the Player advances in the game mode, his or her achievements are
        immutably recorded on the blockchain.
      </Text>

      <Text>
        A minted snook comes with five (5) lives. Each mint cost is fixed at 1.25 USDC in SNK value and is
        non-refundable and non-reversible. When the snook dies in the Game, it loses one (1) life. If all five (5) lives
        are lost, the snook is dead. A dead snook has one (1) hour to be resurrected by the Player, which shall pay a
        resurrection fee. If it is not resurrected, the snook is burned and the Player cannot access that specific snook
        anymore.
      </Text>

      <Text>
        The <b>Big Boys Table</b> game mode allows the Player to raise the stakes on his/her skills against other
        players.
      </Text>

      <Text>
        In this mode, the Player may either create a public or a private room (which will be open for 1 hour and have an
        opening room fee of 2.5 USD paid in SNK) or join a Big Boys Table already created by another Player.
      </Text>

      <Text>
        The Big Boys Table has three different game modes: Last One Standing, Death Match and Team (where a Player can
        stake and win as a team, meaning that the prize is distributed between all team members equally).
      </Text>

      <Text>
        The <b>Snook Ladder</b> mode works as a seasonal competition. Special Skins will be a requirement for
        participation in this mode. Therefore, Players will have to deposit a Special Skin to the Ladder´s contract in
        order to access this mode.
      </Text>

      <Text>
        Each competition will take place over a period of four (4) weeks, during which the players may compete against
        each other and earn points only in game days (Saturday, Sunday or any other day decided by us). A leaderboard
        will be available throughout the competition.
      </Text>

      <Text>
        At the end of the season, competition rankings will be made available and top-ranking Players will benefit from
        reward distribution.
      </Text>

      <Text>
        For more detailed information on the different Game modes, please see our <a
        href="https://whitepaper.snook.gg/">WhitePaper</a>.
      </Text>

      <Text className="h3-term">3. ELIGIBILITY</Text>

      <Text>
        To access and play the Game, you must be able to form a legally binding contract with us. Accordingly, you
        represent that you are at least of the age of majority in your jurisdiction (e.g., eighteen (18) years old)
        and/or have the full right, power, and authority to enter into and comply with these T&C on behalf of yourself
        under the applicable legislation.
      </Text>

      <Text>
        Nothing in the content of the Game should pose a problem for minors. Nonetheless, Snook requires the ability to
        transact in cryptocurrencies, and therefore you may be required to play under adult supervision according to the
        applicable legislation in your jurisdiction.
      </Text>

      <Text>
        You further represent that your access to the Game will fully comply with all applicable laws and regulations,
        and that you will not access or play the Game to conduct, promote, or otherwise facilitate any illegal activity.
      </Text>

      <Text className="h3-term">4. INTELLECTUAL PROPERTY RIGHTS</Text>

      <Text>
        The contents on the Website (including, without limitation, logos, trade names, texts, images, videos, graphics,
        drawings, sounds, among others) are protected under copyright, trademark and other industrial and intellectual
        property rights and shall not be copied, reproduced or broadcasted, in whole or in part, without SGM's prior
        consent or, when the case may be, of the respective rights holders. SGM hereby authorizes the visualization,
        printing, and storage of the contents of the Website exclusively for personal use and without lucrative intent.
        Any other use or exploitation will be subject to prior written authorization from us or, when the case may be,
        of the respective rights holders.
      </Text>

      <Text>
        All rights, title, and interest in and to the Game, including all intellectual property rights arising out of
        the Game, which include, without limitation, copyrights, trademarks, copyright, service mark, designs, patents,
        trade secret rights, are owned by or otherwise licensed to SGM. Subject to your compliance with these T&C, SGM
        grants you a revocable, non-exclusive, non-sub licensable, limited license to play the Game.
      </Text>

      <Text>
        YOU ACCEPT AND ACKNOWLEDGE THAT THE MATERIAL AND CONTENT CONTAINED IN OR DELIVERED BY THE GAME IS MADE AVAILABLE
        FOR YOUR PERSONAL, LAWFUL, NON-COMMERCIAL USE ONLY, AND THAT YOU MAY ONLY USE SUCH MATERIAL AND CONTENT FOR THE
        PURPOSE OF PLAYING THE GAME AS SET FORTH IN THESE T&C. EXCEPT AS STATED IN THESE T&C, NOTHING IN THESE T&C
        SHOULD BE CONSTRUED AS CONFERRING ANY RIGHT IN OR LICENSE TO THE PLAYER REGARDING SGM'S OR ANY THIRD PARTY’S
        INTELLECTUAL PROPERTY RIGHTS.
      </Text>

      <Text>
        As this is the grant of a license, not a transfer of title, you may not:
      </Text>

      <ol>
        <li><Text>Modify or copy the Game;</Text></li>
        <li><Text>Attempt to decompile or reverse engineer any software contained in the Game; or</Text></li>
        <li><Text>Remove any copyright or other proprietary notations from the Game.</Text></li>
      </ol>

      <Text>
        This license shall automatically terminate if you violate any of these restrictions and may be terminated by SGM
        at any time.
      </Text>

      <Text className="h3-term">5. ADDITIONAL RIGHTS</Text>

      <Text>
        We reserve the following rights, which do not constitute obligations of ours:
      </Text>

      <ol>
        <li>
          <Text>
            With or without notice to you, to modify, substitute, eliminate or add any materials or contents to the
            Game;
          </Text>
        </li>
        <li>
          <Text>
            With or without notice to you, to review, modify, filter, disable, delete and remove any and all materials
            and contents from the Game;
          </Text>
        </li>
        <li>
          <Text>
            With or without notice to you, to provide materials, content and/or services in the Game made available by
            third-parties, including, without limitation, decentralized exchanges, wallets, NFT marketplaces and payment
            solutions; and,
          </Text>
        </li>
        <li>
          <Text>
            With or without notice to you, to cooperate with any governmental or law enforcement investigation or to
            disclose any information it deems necessary to satisfy any applicable law, regulation, legal process or
            governmental request, or to edit, refuse to post or to remove any information from the Game, in whole or in
            part, based on any applicable law, regulation, legal process or governmental request, in our sole and
            absolute discretion.
          </Text>
        </li>
      </ol>

      <Text className="h3-term">6. PRIVACY AND DATA PROTECTION</Text>

      <Text>
        The Website and the Game may directly or indirectly collect personally identifiable information from you for
        operational purposes, namely, for the purpose of identifying blockchain addresses or IP addresses.
      </Text>

      <Text>
        Please note that when you use the Game, you are interacting with a multichain blockchain architecture, which
        provides transparency into your transactions. We do not control and are not responsible for any information you
        make public on the blockchain by taking actions through the Game.
      </Text>

      <Text>
        For more information, please see our <Link to="/privacy#">Privacy Policy</Link>.
      </Text>

      <Text className="h3-term">7. PROHIBITED ACTIVITY</Text>

      <Text>
        You agree not to engage in, or attempt to engage in, any of the following prohibited activities (hereinafter
        referred to as "<b>Prohibited Activities</b>") in relation to your access and use of the Game:
      </Text>

      <ol>
        <li>
          <Text>
            <b>Intellectual Property Infringement</b>. Activity that infringes on or violates any intellectual property
            rights,
            including, without limitation, copyrights, trademarks, service mark, designs, patents, trade secret rights,
            rights
            of publicity, rights of privacy, or other proprietary or intellectual property rights under the law.
          </Text>
        </li>
        <li>
          <Text>
            <b>Cyberattack</b>. Activity that seeks to interfere with or compromise the integrity, security, or proper
            functioning of
            any computer, server, network, personal device, or other information technology system, including (but not
            limited
            to) the deployment of viruses and denial of service attacks.
          </Text>
        </li>
        <li>
          <Text>
            <b>Fraud and Misrepresentation</b>. Activity that seeks to defraud us or any other person or entity,
            including (but not
            limited to) providing any false, inaccurate, or misleading information in order to unlawfully obtain the
            property
            of another.
          </Text>
        </li>
        <li>
          <Text>
            <b>Market Manipulation</b>. Activity that violates any applicable law, rule, or regulation concerning the
            integrity of
            trading markets, including (but not limited to) the manipulative tactics commonly known as spoofing and wash
            trading.
          </Text>
        </li>
        <li>
          <Text>
            <b>Any Other Unlawful Conduct</b>. Activity that violates any applicable law, rule or regulation of any
            applicable
            jurisdiction.
          </Text>
        </li>
      </ol>

      <Text className="h3-term">8. NO PROFESSIONAL ADVICE OR LIABILITY</Text>

      <Text>
        ALL INFORMATION PROVIDED BY OR ON BEHALF OF SGM ON SNOOK IS UNSOLICITED, FOR INFORMATIONAL PURPOSES ONLY AND
        SHOULD NOT BE CONSTRUED AS PROFESSIONAL, ACCOUNTING OR LEGAL ADVICE. WE DO NOT PROVIDE INVESTMENT ADVICE AND ANY
        CONTENT CONTAINED ON THE GAME SHOULD NOT BE CONSIDERED AS A SUBSTITUTE FOR TAILORED INVESTMENT ADVICE.
      </Text>

      <Text>
        PLAYERS SHOULD NOT TAKE OR REFRAIN FROM TAKING ANY ACTION IN RELIANCE ON ANY INFORMATION CONTAINED IN THESE T&C
        OR
        PROVIDED BY OR ON BEHALF OF SGM. BEFORE MAKING ANY FINANCIAL, LEGAL, OR OTHER DECISIONS INVOLVING THE GAME,
        PLAYERS SHOULD SEEK INDEPENDENT PROFESSIONAL ADVICE FROM PERSONS LICENSED AND QUALIFIED IN THE AREA FOR WHICH
        SUCH
        ADVICE WOULD BE APPROPRIATE.
      </Text>

      <Text className="h3-term">9. NO WARRANTIES</Text>

      <Text>
        The Game is provided on an "AS IS" and "AS AVAILABLE" basis. To the fullest extent permitted by law, we disclaim
        any representations and warranties of any kind, whether express, implied, or statutory, including (but not
        limited to) the warranties of merchantability and fitness for a particular purpose.
      </Text>

      <Text>
        You acknowledge and agree that your use of the Game is at your own risk. We do not represent or warrant that:
      </Text>

      <ol>
        <li>
          <Text>
            Access to the Game will be continuous, uninterrupted, timely, or secure;
          </Text>
        </li>
        <li>
          <Text>
            The information contained in the Game will be accurate, reliable, complete, or current; or
          </Text>
        </li>
        <li>
          <Text>
            The Game will be free from errors, defects, viruses, or other harmful elements.
          </Text>
        </li>
      </ol>

      <Text>
        No advice, information, or statement that we make should be treated as creating any warranty concerning the
        Game. Snook is a multiplayer game which relies on online servers in order to operate. The servers and the
        provided services are hosted in different cloud service providers which are not controlled by SGM. Players
        acknowledge and agree that SGM shall not be responsible for the failure or malfunction of servers, and if the
        servers are down when the Player is playing the Game, the Player understands that he or she might lose some
        progression. In such a case, the Player hereby agrees to hold SGM harmless.
      </Text>

      <Text>
        Moreover, Snook relies on the blockchain to store data and game state, and on third-party service providers in
        order to communicate with the blockchain. The multi-chain blockchain architecture of the Game is not operated by
        SGM, and therefore SGM shall not be responsible for the failure or malfunction of the blockchain or of any other
        third-party services, including, without limitation, risks relating to holding and transferring crypto assets.
      </Text>

      <Text>
        To play Snook, Players shall connect a wallet. SGM does not control or operate such wallets but simply allows
        integration of the wallets into the Game, and therefore shall not be responsible for the failure or malfunction
        of the connected wallets.
      </Text>

      <Text>
        SGM is not responsible for any digital asset market and makes no representations or warranties concerning the
        real or perceived value of digital assets as denominated in any quoted currency. Although we may provide
        historical and/or real-time data regarding the price of digital assets, we make no representations regarding the
        quality, suitability, truth, usefulness, accuracy, or completeness of such data, and you should not rely on such
        data for any reason whatsoever.
      </Text>

      <Text>
        Snook is an online game which involves financial aspects. Players might encounter issues related to their own
        infrastructure, electricity, and internet connection. SGM takes no responsibility nor shall be liable in such
        cases.
      </Text>

      <Text>
        Also, we do not endorse, guarantee, or assume responsibility for any advertisements, offers, or statements made
        by third-parties concerning the Game.
      </Text>

      <Text className="h3-term">10. NO FIDUCIARY DUTIES</Text>

      <Text>
        SGM does not facilitate or provide brokerage, exchange, payment, escrow, remittance or merchant services.
        Therefore, these T&C are not intended to, and do not, create or impose any fiduciary duties on us.
      </Text>

      <Text>
        To the fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities
        to you or any third-party, and that to the extent any such duties or liabilities may exist at law or in equity,
        those duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated.
      </Text>

      <Text>
        You further agree that the only duties and obligations that we owe you are those set out expressly in these T&C.
      </Text>

      <Text className="h3-term">11. COMPLIANCE OBLIGATIONS</Text>

      <Text>
        The access to the Game may be restricted in some jurisdictions, according to local laws and/or regulations. By
        accessing or using the Game, you agree that you are solely and entirely responsible for compliance with all laws
        and regulations that may apply to you.
      </Text>

      <Text className="h3-term">12. ASSUMPTION OF RISK</Text>

      <Text>
        Please note that all transactions involving digital assets involve substantial risks. You should therefore
        carefully consider whether playing Snook is suitable for you in light of your financial condition. By accessing
        and playing Snook, you represent that you understand the inherent risks associated with using cryptographic and
        blockchain-based systems. In particular, you understand that blockchain-based transactions are irreversible.
      </Text>

      <Text>
        You further understand that the markets for these digital assets are highly volatile due to factors including,
        without limitation, adoption, speculation, technology, security, and regulation. You acknowledge and accept that
        the cost and speed of transacting with cryptographic and blockchain based systems are variable and may increase
        dramatically at any time.
      </Text>

      <Text>
        You further acknowledge and accept the risk that your digital assets may lose some or all of their value and you
        may suffer loss due to the fluctuation of prices of SNK tokens.
      </Text>

      <Text>
        You further acknowledge that SGM shall not be responsible for any of these variables or risks, and we do not own
        or control SNK tokens value, and therefore SGM cannot be held liable for any resulting losses that you
        experience while accessing or playing the Game.
      </Text>

      <Text>
        Moreover, you acknowledge that we are not responsible for any loss of your digital asset resulting from theft,
        loss, or mishandling of digital asset private keys outside our control. Accordingly, you understand and agree to
        assume full responsibility for all of the risks of accessing and playing the Game.
      </Text>

      <Text>
        Before playing Snook, you should review the relevant documentation to make sure you understand how the Game
        works. For more information on Snook, please see our <a href="https://whitepaper.snook.gg/">WhitePaper</a>.
      </Text>


      <Text className="h3-term">13. THIRD-PARTY RESOURCES</Text>

      <Text>
        The contents on our Website are for informative purposes only. Our Website provides content of its own and
        content made available by third-parties. SGM reserves the right to modify at any time the presentation,
        configuration and location of the Website and/or the respective contents.
      </Text>

      <Text>
        The Game may contain references or links to third-party resources, including, but not limited to, information,
        materials, products, or services, that we do not own or control. SGM has no control over, and assumes no
        responsibility for the function, content, privacy policies, or practices of any such websites.
      </Text>

      <Text>
        SGM does not guarantee the reliability, truthfulness, accuracy, exhaustiveness, and timeliness of third-party
        content and/or services. SGM only provides links to the websites it considers complying with the applicable
        legislation. SGM reserves the right to remove links to any website, for any reason, and without prior notice,
        particularly if they become aware that the activities carried out on such website or its content are illegal or
        infringe the rights of third-parties, or if ordered to do so by court or administrative decision.
      </Text>

      <Text>
        You further acknowledge and agree that SGM shall not be responsible or liable, directly or indirectly, for any
        damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
        goods or service available on or through any such websites or services. You expressly relieve us of any and all
        liability arising from your use of any such third-party services.
      </Text>

      <Text className="h3-term">14. RELEASE OF CLAIMS</Text>

      <Text>
        YOU EXPRESSLY AGREE THAT YOU ASSUME ALL RISKS IN CONNECTION WITH YOUR ACCESS AND USE OF THE GAME. YOU FURTHER
        EXPRESSLY WAIVE AND RELEASE US FROM ANY AND ALL LIABILITY, CLAIMS, CAUSES OF ACTION, OR DAMAGES ARISING FROM OR
        IN ANY WAY RELATING TO YOUR USE OF THE GAME.
      </Text>

      <Text className="h3-term">15. INDEMNITY</Text>

      <Text>
        You agree to hold harmless, release, defend, and indemnify us and our officers, directors, employees,
        contractors, agents, affiliates, and subsidiaries from and against all claims, damages, obligations, losses,
        liabilities, costs, and expenses arising from:
      </Text>

      <ol>
        <li>
          <Text>
            Your access and use of the Game;
          </Text>
        </li>
        <li>
          <Text>
            Your violation of any term or condition of these T&C, the right of any third party, or any other applicable
            law, rule, or regulation; and
          </Text>
        </li>
        <li>
          <Text>
            Any other party's access and use of the Game with your assistance or using any device or account that you
            own or control.
          </Text>
        </li>
      </ol>

      <Text className="h3-term">16. LIMITATION OF LIABILITY</Text>

      <Text>
        Under no circumstances shall we or any of our officers, directors, employees, contractors, agents, affiliates,
        or subsidiaries be liable to you for any indirect, punitive, incidental, special, consequential, or exemplary
        damages, including (but not limited to) damages for loss of profits, goodwill, use, data, or other intangible
        property, arising out of or relating to any access or use of the Game, nor will we be responsible for any
        damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the Game or
        the information contained within it.
      </Text>

      <Text>
        We assume no liability or responsibility for any:
      </Text>

      <ol>
        <li>
          <Text>
            Unsupported software or hardware;
          </Text>
        </li>
        <li>
          <Text>
            Errors, mistakes, or inaccuracies of content;
          </Text>
        </li>
        <li>
          <Text>
            The change in value of any digital asset;
          </Text>
        </li>
        <li>
          <Text>
            Personal injury or property damage, of any nature whatsoever, resulting from any access or use of the Game;
          </Text>
        </li>
        <li>
          <Text>
            Unauthorized access or use of any secure server or database in our control, or the use of any information or
            data stored therein;
          </Text>
        </li>
        <li>
          <Text>
            Interruption or cessation of function related to the Game;
          </Text>
        </li>
        <li>
          <Text>
            The malfunction, unexpected function or unintended function of any computer or cryptocurrency network;
          </Text>
        </li>
        <li>
          <Text>
            Bugs, viruses, trojan horses, or any other malicious programs that may be transmitted to or through the
            Game;
          </Text>
        </li>
        <li>
          <Text>
            Any change in law, regulation or policy;
          </Text>
        </li>
        <li>
          <Text>
            Errors or omissions in, or loss or damage incurred as a result of the use of, any content made available
            through the Game; and
          </Text>
        </li>
        <li>
          <Text>
            The defamatory, offensive, or illegal conduct of any third-party.
          </Text>
        </li>
      </ol>

      <Text>
        Under no circumstances shall we or any of our officers, directors, employees, contractors, agents, affiliates,
        or subsidiaries be liable to you for any claims, proceedings, liabilities, obligations, damages, losses, or
        costs. This limitation of liability applies regardless of whether the alleged liability is based on contract,
        tort, negligence, strict liability, or any other basis, and even if we have been advised of the possibility of
        such liability.
      </Text>

      <Text>
        Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of certain
        liabilities and damages. Accordingly, some of the disclaimers and limitations set forth in these T&C may not
        apply to you. This limitation of liability shall apply to the fullest extent permitted by law.
      </Text>

      <Text className="h3-term">17. GOVERNING LAW, DISPUTE RESOLUTION AND JURISDICTION</Text>

      <Text>
        These T&C and your use of the Website and the Game shall be governed by and construed in accordance with the
        laws of Saint Vincent and the Grenadines as to all matters, including matters of validity, construction, effect,
        enforceability, performance and remedies, without regard to its conflicts of laws principles.
      </Text>

      <Text>
        All disputes arising out of or in connection with these T&C, including without limitation your access or use of
        the Website and/or the Game, shall be settled through friendly negotiation. In case no settlement can be
        reached, the dispute shall then be referred to and finally resolved by the courts of Saint Vincent and the
        Grenadines, except where otherwise required by applicable law (including international private law rules and
        principles).
      </Text>
    </Terms>
  )
}

export default memo(TermsAndConditions)
