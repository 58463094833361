import styled, { keyframes } from "styled-components"

export interface AnimatedArrowRoundSCProps {
  delay?: number
  r?: number
  reverse: boolean
}

export interface DotSCProps {
  x: number
  y: number
  r: number
}

const animate = (radius: number) => keyframes`
  25%, 100% {
    transform: translateX(0) translateY(${ Math.abs(radius) }rem) rotateZ(-13deg);
    opacity: 0;
  }

${ new Array(24).fill(null).map((_, i) => {
  const gap = 15
  const angle = (180 - gap * 2) / 25
  const reverse = radius < 0 ? -1 : 1
  const x = Math.abs(radius) * Math.sin(Math.PI * 2 * (gap + (angle * i)) / 360)
  const y = Math.abs(radius) * Math.cos(Math.PI * 2 * (gap + (angle * i)) / 360)
  return `
      ${ 24 - i }% {
        opacity: 1;
        transform: translateX(${ reverse * x }rem) translateY(${ y }rem) rotateZ(${ (reverse < 1 ? -8 : 30) + reverse * (-angle * i) }deg);
      }
    `
}) }
`

export const AnimatedArrowRoundSC = styled.div<AnimatedArrowRoundSCProps>`
  > .animated-arrow-container {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;

    .earn-arrow {
      width: 24px;
      height: 24px;
      left: 0;
      position: relative;

      //margin: 0 auto;

      z-index: 1;

      transform: translateX(0) translateY(${ props => -Math.abs(props.r!) }rem) rotateZ(${ props => props.r! < 0 ? 5 : -5 }deg);

      animation-name: ${ (props) => animate(props.r!) };
      animation-iteration-count: infinite;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-delay: ${ props => props.delay || 0 }s;
    }
  }
`

export const DotSC = styled.div<DotSCProps>`
  position: absolute;

  margin: 0 auto;

  z-index: 0;

  > img {
    position: relative;

    width: 0.5rem;
    margin: 0 1.5rem;
    height: 0.5rem;

    top: 0;
    left: 0;

    transform: translateX(${ props => props.x }rem) translateY(${ props => props.y }rem);
  }
`
