import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Row from "../../ui/Row"

const spaceBG = new URL("./spaceBG.png?as=webp&width=1080", import.meta.url).toString()

export interface BBTSCProps {
}

export const BBTSC = styled(Row)<BBTSCProps>`
  padding: 10rem ${ pxToRem(32) } 16rem;
  position: relative;
  z-index: 1;
  background-color: #00122C;
  border-top: 8px solid #FDC500;
  border-bottom: 8px solid #FDC500;

  .bbt-wrapper {
    width: ${ pxToRem(1440) };

    p {
      text-align: left;
    }

    > div {
      width: 50%;
    }
  }

  .bbt-text-block {
    width: 50%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .4;
    z-index: -1;
    background: url(${ spaceBG }), radial-gradient(69.17% 69.17% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #00122C;
    background-blend-mode: normal, normal, luminosity, normal;
  }

  .bbt-h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 72px;
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }

  .bbt-video {
    border: 4px solid #FDC500;
    border-radius: 16px;

    box-sizing: border-box;

    position: relative;

    .bbt-video-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  @media screen and (max-width: 1600px) {
    .bbt-wrapper {
      width: 90vw;
    }
  }

  @media screen and (max-width: 1100px) {
    .bbt-wrapper {
      flex-direction: column;
    }

    .bbt-wrapper {
      > div {
        width: 100%;
      }
    }

    padding: 4rem 1rem;
  }
`
