import { Blend } from "../Blend"
import { Props } from "../Blend/styled"
import blueImage from "./blue.svg"
import pinkImage from "./pink.svg"

export type ComponentProps = Props

export function ExclaimBlend({ blue, pink }: ComponentProps) {
  return (
    <>
      <Blend blue={ { ...blue, image: blueImage } } pink={ { ...pink, image: pinkImage } }/>
    </>
  )
}
