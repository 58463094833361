import { memo } from "react"
import { AbsoluteContainer, RelativeContainer, SnookCommunitySC, SnookCommunitySCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Col from "../ui/Col"
import Text from "../ui/Text"
import Row from "../ui/Row"
import twitter from "../../../public/twitter.svg"
import discord from "../../../public/discord.svg"
import { HexBlend } from "../HexBlend"
import { ExclaimBlend } from "../ExclaimBlend"
import RoundButton from "../RoundButton"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import classNames from "classnames"

export interface SnookCommunityProps extends SnookCommunitySCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

const snook = {
  small: new URL("community.png?as=webp&width=680", import.meta.url).toString(),
  big: new URL("community.png?as=webp&width=1360", import.meta.url).toString(),
}

function SnookCommunity({ className, style, screenWidth }: SnookCommunityProps) {
  return (
    <SnookCommunitySC className={ classNames(className) } style={ style }>
      <div id="community" />
      <Col className="community-content">
        <Text className="community-content-small-title">Keep up to date with the</Text>
        <Text className="community-content-big-title">Snook { screenWidth < 700 ? <br/> : "" } Community!</Text>
        <Col gap={ 24 }>
         <a href='https://discord.gg/snook-819160388725637131' target="_blank">
           <RoundButton color={ RoundButtonTypeEnum.VIOLET } withIcon={ false } style={ { width: "60%" } }>
            <Row gap={ 16 } style={ { flexDirection: "row" } }>
              <Col className="button-icon" justify="center">
                <img src={ discord } alt="discord" className="discord-icon"/>
              </Col>
              <Col>
                <Text weight={ 400 } size={ screenWidth > 1400 ? 16 : 14 } lineHeight={ 16 }>JOIN OUR</Text>
                <Text weight={ 800 } size={ screenWidth > 1400 ? 34 : 20 }
                      lineHeight={ screenWidth > 1400 ? 33 : 21 }>DISCORD</Text>
              </Col>
            </Row>
          </RoundButton>
         </a>
          <a href='https://twitter.com/snook_gg' target="_blank">
            <RoundButton color={ RoundButtonTypeEnum.ALT_BLUE } withIcon={ false } style={ { width: "60%" } }>
              <Row gap={ 16 } style={ { flexDirection: "row" } }>
                <Col className="button-icon" justify="center">
                  <img src={ twitter } alt="twitter" className="twitter-icon"/>
                </Col>
                <Col>
                  <Text weight={ 400 } size={ screenWidth > 1400 ? 16 : 14 } lineHeight={ 16 }>FOLLOW US ON</Text>
                  <Text weight={ 800 } size={ screenWidth > 1400 ? 34 : 20 }
                        lineHeight={ screenWidth > 1400 ? 33 : 21 }>twitter</Text>
                </Col>
              </Row>
            </RoundButton>
          </a>
        </Col>
      </Col>
      <div className="mobile-community-img"></div>
      <RelativeContainer className="community-img">
        <AbsoluteContainer>
          {/* 1st left */ }
          <div style={ { position: "absolute", left: "10%", top: "-10%" } }>
            <ExclaimBlend
              blue={ { to: { y: 30, r: 5 }, delay: 5 } }
              pink={ { from: { x: 5 }, to: { x: 5, y: 15, r: 5 }, delay: 5 } }
            />
          </div>

          {/* 2st left */ }
          <div style={ { position: "absolute", left: "-2%", top: "10%" } }>
            <ExclaimBlend
              blue={ { from: { x: 5, y: 20, r: -5 }, to: { y: 20, r: -10 }, scale: 1.3 } }
              pink={ { from: { x: 5, r: -5 }, to: { x: 5, y: 15, r: -10 }, scale: 1.3 } }
            />
          </div>

          {/* 3st left */ }
          <div style={ { position: "absolute", left: "5%", top: "45%" } }>
            <HexBlend
              blue={ { from: { y: 25, r: 5 }, to: { y: 95, r: 5 } } }
              pink={ { from: { x: 10, r: 20 }, to: { x: 10, y: 85, r: -45 } } }
            />
          </div>

          {/* 4st left */ }
          <div style={ { position: "absolute", left: "0%", top: "75%" } }>
            <HexBlend
              blue={ { from: { x: 25, y: 110, r: -45 }, to: { x: 25, y: 140 }, scale: 0.9, delay: 5 } }
              pink={ { from: { r: -25 }, to: { y: 40, r: -45 }, scale: 0.8 } }
            />
          </div>

          {/* 1st right */ }
          <div style={ { position: "absolute", right: "35%", top: "-10%" } }>
            <HexBlend
              blue={ { from: { y: 10, r: 0 }, to: { y: 25, r: 75 } } }
              pink={ { from: { x: 5, r: 15 }, to: { x: 5, y: 35, r: 15 } } }
            />
          </div>

          {/* 2st right */ }
          <div style={ { position: "absolute", right: "15%", top: "-5%" } }>
            <ExclaimBlend
              blue={ { from: { x: -5, y: 10, r: 35 }, to: { x: -10, y: 15, r: 40 }, scale: 1.5 } }
              pink={ { from: { x: 5, r: 35 }, to: { x: -5, y: 20, r: 40 }, scale: 1.5 } }
            />
          </div>

          {/* 3st right */ }
          <div style={ { position: "absolute", right: "7%", top: "35%" } }>
            <HexBlend
              blue={ { from: { y: 10, r: 60 }, to: { y: 20, r: 5 }, scale: 1.5, delay: 5 } }
              pink={ { from: { x: 10, r: 5 }, to: { x: 10, y: 35, r: 60 }, scale: 1.5, delay: 5 } }
            />
          </div>

          {/* 4st right */ }
          <div style={ { position: "absolute", right: "7%", top: "86%" } }>
            <HexBlend
              blue={ { from: { y: 10, r: 5 }, to: { y: 65, r: 45 }, scale: 1.2 } }
              pink={ { from: { x: 10, r: 60 }, to: { x: 10, y: 55, r: 15 }, scale: 1.2 } }
            />
          </div>
        </AbsoluteContainer>
        <img src={ snook.small } srcSet={ `${ snook.big } 2x` } alt="image" height="100%"/>
      </RelativeContainer>
    </SnookCommunitySC>
  )
}

export default memo(SnookCommunity)
