import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem";
import Row from "../../ui/Row";

export interface ParticipationSCProps {
}

export const ParticipationSC = styled(Row)<ParticipationSCProps>`
  padding: 12rem ${pxToRem(32)} 8rem ${pxToRem(32)};
  
  .participation-text {
    width: 100%;
  }

  .participation-wrapper {
    gap: 3rem;
    
    width: ${pxToRem(1440)};
    p {
      text-align: left;
    }
  }
  
  .participation-h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 72px;
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
    padding-top: ${pxToRem(120)};
  }
  
  .participation-btn {
    width: ${pxToRem(249)};
    margin-top: ${pxToRem(8)};
  }
  
  .participation-cards {
    position: relative;
    width: 100%;
    height: 611px;
  }
  
  .card1 {
    position: absolute;
    top: 131px;
    left: 67px;
    width: 220px;
    height: 220px;
  }

  .card2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 324px;
    height: 324px;
  }

  .card3 {
    position: absolute;
    bottom: 0;
    left: 154px;
    width: 220px;
    height: 220px;
  }

  .card4 {
    position: absolute;
    bottom: 67px;
    right: 78px;
    width: 162px;
    height: 162px;
  }
  
  .participation-cards-mob {
    display: none
  }
  
  @media screen and (max-width: 1600px) {
    .participation-wrapper {
      flex-direction: column;
      width: 90vw;
      align-items: center;
    }

    .participation-cards {
      width: 700px;
    }
  }
  @media screen and (max-width: 700px) {
    .participation-h2 {
      font-size: 36px;
    }
  }
 

  @media screen and (max-width: 800px) {
    padding: 10rem 1rem 4rem;
    
    .participation-cards {
      display: none;
    }

    .participation-cards-mob {
      display: flex;
    }

    .participation-h2 {
      padding-top: ${pxToRem(28)};
    }
  }
`
