import { memo } from "react"
import { BigWrapperSC, HowToEarnSC, HowToEarnSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import Row from "../ui/Row"
import Col from "../ui/Col"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import RoundButton from "../RoundButton"
import AnimatedArrowStraight from "../AnimatedArrowStraight"
import AnimatedArrowRound from "../AnimatedArrowRound"

export interface HowToEarnProps extends HowToEarnSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

const earn1 = {
  small: new URL("images/snook-1.png?as=webp&width=368", import.meta.url).toString(),
  big: new URL("images/snook-1.png?as=webp&width=736", import.meta.url).toString(),
}

const earn2 = {
  small: new URL("images/snook-2.png?as=webp&width=368", import.meta.url).toString(),
  big: new URL("images/snook-2.png?as=webp&width=736", import.meta.url).toString(),
}

const earn3 = {
  small: new URL("images/snook-3.png?as=webp&width=368", import.meta.url).toString(),
  big: new URL("images/snook-3.png?as=webp&width=736", import.meta.url).toString(),
}

function HowToEarn({ className, style, screenWidth }: HowToEarnProps) {
  let isMobile = screenWidth < 1009

  return (
    <HowToEarnSC className={ className } style={ style }>
      <div id="how-to-earn"></div>
      <BigWrapperSC className="container big-wrapper">
        <Text className="h1">How to <span className="colored-title">Earn?</span></Text>
        <Text className="title-description"><b>More kills, more moolah!</b></Text>
        <Row className="inner-content" justify="space-between">
          <Col className="block block-up" justify="center">
            <img src={ earn1.small } srcSet={ `${ earn1.big } 2x` } alt="earn1" className="earn"/>
            <Text className="h3">Have the Skill to Kill</Text>
            <AnimatedArrowRound reverse={ false }/>
            <Text>The more snooks you kill, the more <b>SNK</b> you can earn. You think you
              have the skill? </Text>
          </Col>
          <Col className="animate-block">
            { isMobile || <AnimatedArrowStraight delay={ 0 }/> }
          </Col>
          <Col className="block">
            <img src={ earn2.small } srcSet={ `${ earn2.big } 2x` } alt="earn2" className="earn"/>
            <Text className="h3">Instant Reward per Kill</Text>
            <AnimatedArrowRound reverse={ true } className={ "reverse" } delay={ .75 }/>
            <Text>Kill a snook = immediate pay in <b>SNK.</b></Text>
          </Col>
          <Col className="animate-block">
            { isMobile || <AnimatedArrowStraight className="revert" delay={ .75 }/> }
          </Col>
          <Col className="block block-up">
            <img src={ earn3.small } srcSet={ `${ earn3.big } 2x` } alt="earn3" className="earn"/>
            <Text className="h3">It’s All About the Traits</Text>
            <Text>Keep an eye for random Trait drops. Those are the key for
              unlocking exclusive special skins.</Text>
          </Col>
        </Row>
        <a href='https://play.snook.gg/' target="_blank"><RoundButton color={ RoundButtonTypeEnum.ORANGE } sub="Go on…I know you want to">CLICK ME!</RoundButton></a>
      </BigWrapperSC>
    </HowToEarnSC>
  )
}

export default memo(HowToEarn)
