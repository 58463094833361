import { memo, useCallback, useMemo } from "react"
import { FooterSC, FooterSCProps, Input } from "./styled"
import { CSSProperties } from "styled-components"
import Row from "../ui/Row"
import Text from "../ui/Text"
import locationFooter from "../../../public/locationFooter.svg"
import Col from "../ui/Col"
import youtubeFooter from "../../../public/youtubeFooter.svg"
import twitterFooter from "../../../public/twitterFooter.svg"
import telegramFooter from "../../../public/telegramFooter.svg"
import discordFooter from "../../../public/discordFooter.svg"
import discFooter from "../../../public/discFooter.svg"
import RoundButton from "../RoundButton"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import arrowSend from "../../../public/arrowSend.svg"
import { HashLink as Link } from "react-router-hash-link"

export interface FooterProps extends FooterSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

const footerLogo = {
  small: new URL("../../../public/footerLogo.png?as=webp&width=256", import.meta.url).toString(),
  big: new URL("../../../public/footerLogo.png?as=webp&width=512", import.meta.url).toString(),
}

function Footer({ className, style, screenWidth }: FooterProps) {
  const scrollWithOffset = useCallback((el: any, offset: number) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY
    window.scrollTo({ top: yCoordinate + offset, behavior: "smooth" })
  }, [])

  const gap = useMemo(() => screenWidth <= 700 ? -100 : 0, [ screenWidth ])

  return (
    <FooterSC className={ className } style={ style }>
      <div className="small-wrapper">
        <Row justify="space-between" className="mobile-block" gap={ screenWidth > 800 ? 0 : 16 }>
          <Col style={ { width: screenWidth > 800 ? "50%" : "100%" } } gap={ 12 }>
            <Text className="h3 mobile-h3" align="left">
              Join Our E-mail List
            </Text>
            <Text size={ 16 } lineHeight={ 24 } weight={ screenWidth > 800 ? 600 : 400 } align="left">
              There’s nothing wrong with being 50...
            </Text>
          </Col>
          <form
            id="mailing"
            action="https://playsnook.us1.list-manage.com/subscribe/post?u=260357b0573c4ab820efeee1f&amp;id=493f816775"
            method="post"
            name="mc-embedded-subscribe-form"
            className="newsletter__form"
            target="_blank"
            noValidate={ true }
          >
            <Input>
              <input name="EMAIL" type="email" placeholder="Your e-mail"/>
              <RoundButton color={ RoundButtonTypeEnum.BLUE } withIcon={ false }>
                <img
                  src={ arrowSend } alt="arrow" className="arrow-send"
                  onClick={ () => (document.getElementById("mailing") as HTMLFormElement)?.submit() }
                />
              </RoundButton>
            </Input>
            <input type="hidden" name="u" value="260357b0573c4ab820efeee1f"/>
            <input type="hidden" name="id" value="493f816775"/>
          </form>
        </Row>
        <Row className="footer-center mobile-block" justify="space-between">
          <Col>
            <img src={ footerLogo.small } srcSet={ `${ footerLogo.big } 2x` } alt="logo" className="footer-logo"/>
            <Row gap={ 24 } justify={ screenWidth > 800 ? "start" : "center" } className="socials-icons">
              <Row gap={ 24 }>
                <a href="https://discord.gg/snook-819160388725637131" target="_blank">
                  <img src={ discordFooter } alt="discord" className="icon-footer discord-footer"/>
                </a>
                <a href="https://t.me/+Psf47QU5p-I1ZWM0" target="_blank">
                  <img src={ telegramFooter } alt="telegram" className="icon-footer telegram-footer"/>
                </a>
                <a href="https://twitter.com/snook_gg" target="_blank">
                  <img src={ twitterFooter } alt="twitter" className="icon-footer twitter-footer"/>
                </a>
              </Row>
              <Row gap={ 24 }>
                <a href="https://www.youtube.com/channel/UCV7jZkCkPkqMs08UDIEltsA" target="_blank">
                  <img src={ youtubeFooter } alt="youtube" className="icon-footer youtube-footer"/>
                </a>
                <a href="https://www.twitch.tv/playsnook " target="_blank"><img src={ discFooter } alt="discord"
                                                                                className="icon-footer disc-footer"/></a></Row>
            </Row>
          </Col>
          <Row gap={ 72 } className="footer-text">
            <Col>
              <Link to="/#play" scroll={ el => scrollWithOffset(el, gap - 40) }>
                <Text>Playing Snook</Text>
              </Link>
              <Link to="/#how-to-earn" scroll={ el => scrollWithOffset(el, gap + 0) }>
                <Text>How to Earn!</Text>
              </Link>
              <Link to="/#special-skins" scroll={ el => scrollWithOffset(el, gap - 40) }>
                <Text>Special Skins</Text>
              </Link>
              <Link to="/#game-modes" scroll={ el => scrollWithOffset(el, gap - 40) }>
                <Text>Game Modes</Text>
              </Link>
            </Col>
            <Col>
              <Link to="/#community" scroll={ el => scrollWithOffset(el, gap - 120) }>
                <Text>Community</Text>
              </Link>
              <span className="disable-links"> <a href="#"> <Text>Marketplace</Text></a></span>
            </Col>
            <Col>
              <span className="disable-links">
                <a href="#"><Text>Deep End</Text></a>
              </span>
              <Link to="/tokenomics#">
                <Text className="footer-text-normal">Tokenomics</Text>
              </Link>
              <Link to="/themed-rooms#">
                <Text className="footer-text-normal">BBT Themes</Text>
              </Link>
              <span className="disable-links">
                <a href="#"><Text className="footer-text-normal">Staking</Text></a>
              </span>
              <a href="https://whitepaper.snook.gg/" target="_blank"><Text>Whitepaper</Text></a>
            </Col>
            <Col>
              <Link to="/privacy#">
                <Text>Privacy Policy</Text>
              </Link>
              <Link to="/terms#">
                <Text>Terms & Conditions</Text>
              </Link>
            </Col>
          </Row>
        </Row>
        <Row justify="space-between" className="mobile-block">
          <Text color="#1756B3" weight={ 400 } size={ screenWidth > 1100 ? 16 : 14 } lineHeight={ 24 }>
            Copyrights © 2022 Snook. All rights reserved.
          </Text>
          <Row gap={ 8 }>
            <img src={ locationFooter } alt="location-icon" className="locationFooter"/>
            <Text color="#1756B3" weight={ screenWidth > 1100 ? 400 : 600 } size={ screenWidth > 1100 ? 16 : 14 }
                  lineHeight={ 24 }>
              238, Kingstown, St. Vincent and the Grenadines
            </Text>
          </Row>
        </Row>
      </div>
    </FooterSC>
  )
}

export default memo(Footer)
