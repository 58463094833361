import React, { CSSProperties, memo, useCallback, useMemo, useState } from "react"
import { SupplySC } from "./styled"
import Text from "../../ui/Text"
import Card from "../../ui/Card"
import Row from "../../ui/Row"
import Col from "../../ui/Col"

import supply from "./supply.svg"
import supplyMob from "./supplyMob.svg"
import supply2Mob from "./supply2Mob.svg"
import supply3Mob from "./supply3Mob.svg"
import ScaleOut from "../../ScaleOut"

export interface SupplyProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

function Supply({ className, style, screenWidth }: SupplyProps) {
  const [ isOpenSupplyDemand, setSupplyDemandOpen ] = useState<boolean>(false)
  const [ isOpenSupply, setSupplyOpen ] = useState<boolean>(false)
  const [ isOpenDemand, setDemandOpen ] = useState<boolean>(false)

  const supplyImg = useMemo(() => screenWidth > 700 ? supply : supplyMob, [ screenWidth ])

  const openSupplyDemand = useCallback(() => setSupplyDemandOpen(true), [])
  const closeSupplyDemand = useCallback(() => setSupplyDemandOpen(false), [])

  const openSupply = useCallback(() => setSupplyOpen(true), [])
  const closeSupply = useCallback(() => setSupplyOpen(false), [])

  const openDemand = useCallback(() => setDemandOpen(true), [])
  const closeDemand = useCallback(() => setDemandOpen(false), [])

  return (
    <SupplySC className={ className } style={ style } alignItems="center" gap={ 24 }>
      <Text className="supply-h1">Supply-demand Equilibrium</Text>
      <Col gap={ screenWidth > 1100 ? 60 : 36 } alignItems="center">
        <Text className="supply-description">In the Snook-verse the Battle Arena is chiefly the source of supply to the
          Ladder and the Ladder is the source of
          demand for the Battle Arena. As such they form a balanced economic dyad. </Text>
        <Card>
          <Text className="supply-h2">
            Supply and Demand balance between Battle Arena and Snook’s Ladder
          </Text>
          <img src={ supplyImg } alt="supply" className="supply" onClick={ openSupplyDemand }/>
          <ScaleOut
            title="Supply and Demand balance between Battle Arena and Snook’s Ladder"
            img={ supply }
            isOpen={ isOpenSupplyDemand }
            onClose={ closeSupplyDemand }
          />
          { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
        </Card>
        <Row gap={ 92 } className="supply-block-width">
          { screenWidth < 1101 &&
            <Text className="supply-h3"><span className="colored-title">Supply</span> – Battle Arena</Text> }
          <div>
            <img src={ supply2Mob } alt="supply2" className="supply2" onClick={ openSupply }/>
            <ScaleOut
              title={ <><span className="colored-title">Supply</span> – Battle Arena</> }
              img={ supply2Mob }
              width={ 800 }
              isOpen={ isOpenSupply }
              onClose={ closeSupply }
            />
          </div>
          { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
          <Col gap={ 24 }>
            { screenWidth > 1100 &&
              <Text className="supply-h3"><span className="colored-title">Supply</span> – Battle Arena</Text> }
            <Text>
              Minting is the main and most dominant source of Traits in the Snook-verse. It may
              sound trivial, however, there are other, a bit more “exotic” sources of Traits.
              Nevertheless, those are negligible in comparison to the simple act of minting a snook
              that “births” a new random Trait into existence. Because playing in the Battle Arena is
              the main reason for minting, it is fair to say that the Battle Arena is the engine driving
              the supply side of the Trait economy. Minting is the main and most dominant source of
              Traits in the Snook-verse. It may sound trivial, however, there are other, a bit more
              “exotic” sources of Traits. Nevertheless, those are negligible in comparison to the
              simple act of minting a snook that “births” a new random Trait into existence. Because
              playing in the Battle Arena is the main reason for minting, it is fair to say that the Battle
              Arena is the engine driving the supply side of the Trait economy.
            </Text>
            <div>
              <Text><b>Other sources of Traits:</b></Text>
              <ul>
                <li>Winning matches in the Battle
                  Arena will sometimes include a
                  special Trait that is only available
                  by winning or through the Vault.
                </li>
                <li>The Vault.</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row gap={ 92 } className="supply-block-width">
          { screenWidth < 1101 &&
            <Text className="supply-h3"><span className="colored-title">Demand</span> – Snook’s Ladder</Text> }
          { screenWidth < 1101 && <img src={ supply3Mob } alt="supply3" className="supply3" onClick={ openDemand }/> }
          { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
          <Col gap={ 24 }>
            { screenWidth > 1100 &&
              <Text className="supply-h3"><span className="colored-title">Demand</span> – Snook’s Ladder</Text> }
            <Text>
              Demand is the other side of consumption (or death if one is inclined to be morbid).
              Therefore, demand is stimulated almost always when a snook dies (either in the Battle
              Arena or the Ladder). And we mean proper death, yeah? Not just losing a life.
            </Text>
            <Text>
              <b>1. Dying without resurrecting:</b>
              <ul>
                <li>
                  <b>Snook Ladder</b> - When a Special Skin dies in the Snook Ladder and is not resurrected it is burned
                  and its
                  Traits are “burned” with it.
                </li>
                <li>
                  <b>Battle Arena</b> - When a snook (Special Skin or not) dies in the Battle Arena and is not
                  resurrected it too is
                  burned and its Traits are “burned” with it.
                </li>
              </ul>
            </Text>
            <Text>
              <b> 2. Resurrection/Fusion</b> - Destroys at least one Trait in the process itself.
            </Text>
            <Text>
              <b> 3. Unclaimed Traits</b> - In the Battle Arena when a snook dies it sometimes drops a Trait (or
              Traits). If that Trait is
              not picked up by another snook (within a fairly short time frame) it disappears (into the void of
              Cthulhu).
            </Text>
          </Col>
          { screenWidth > 1100 && (
            <div>
              <img src={ supply3Mob } alt="supply3" className="supply3" onClick={ openDemand }/>
            </div>
          ) }
          <ScaleOut
            title={ <><span className="colored-title">Demand</span> – Snook’s Ladder</> }
            img={ supply3Mob }
            width={ 800 }
            isOpen={ isOpenDemand }
            onClose={ closeDemand }
          />
        </Row>
      </Col>
    </SupplySC>
  )
}

export default memo(Supply)
