import React, { memo } from "react"
import { PrincipleSC, PrincipleSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../../ui/Text"
import Col from "../../ui/Col"
import RoundButton from "../../RoundButton"
import { RoundButtonTypeEnum } from "../../RoundButton/styled"
import Row from "../../ui/Row"
import PrincipleMobile from "./PrincipleMobile"

export interface PrincipleProps extends PrincipleSCProps {
  className?: string
  style?: CSSProperties
}

const snakeFirst = new URL("./snake-first.png?as=webp", import.meta.url).toString()

function Principle({ style }: PrincipleProps) {
  return (
    <PrincipleSC style={ style } justify="center">
      <Row className="principle-wrapper" gap={ 350 }>
        <Col className="tokenomics-block" gap={ 24 }>
          <Text className="tokenomics-title">
            Tokenomics
          </Text>
          <Text>This page provides an overview of the key elements and mechanics of the Snook economy. A more complete
            description, review and analysis of the economy can be found in the whitepaper.</Text>
          <a href="https://whitepaper.playsnook.com/economy-overview/snk-cash-flow-analysis" target="_blank" style={ { textAlign: "left" } }>
            <RoundButton color={ RoundButtonTypeEnum.ORANGE } className="tokenomics-btn">WHITEPAPER</RoundButton>
          </a>
        </Col>
        <Col className="principle-wrap"> </Col>
        <Col className="principle-block">
          <Col className="principle-text" gap={ 24 }>
            <Text className="principle-title">
              Our general approach follows <br/>
              this principle:
            </Text>
            <Text>
              The only important economic engine and true and sustainable source
              of value is the effort invested through purposeful action. In the Snook verse it is
              playing Snook. Snook uses two tokens, the <b>ERC20</b> SNK and the <b>ERC721 snook-NFT</b>.
              Playing Snook directly stores value in the snook NFT and indirectly in SNK.
            </Text>
            <div>
              <Text>
                Since Traits represent the most basic unit of underlying value of the
                snook-NFT, an apt description of the Snook economy must apply a double prism:
              </Text>
              <ul>
                <li><b>SNK cashflow</b></li>
                <li><b>The Trait Economy</b> (based on supply-demand balance)</li>
              </ul>
              <Text> Capisce?</Text>
            </div>
          </Col>
          <img src={ snakeFirst } alt="snake" className="snake-first"/>
        </Col>
      </Row>
      <PrincipleMobile className="principle-wrapper-mobile"/>
      <Col className="cashflow" gap={ 24 }>
        <Text className="tokenomics-title">SNK Cashflow</Text>
        <Text>
          Much the same way a company’s cash flow can provide deep insight into its strategy, mechanics and design, it
          can
          do the same to an economy. Below we describe the main channels through which different monetary vehicles flow
          in
          and out of the Snook economy.
        </Text>
      </Col>
    </PrincipleSC>
  )
}

export default memo(Principle)
