import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { TopRoomBlockSC, TopRoomBlockSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../../ui/Text"
import RoundButton from "../../RoundButton"
import { RoundButtonTypeEnum } from "../../RoundButton/styled"
import Col from "../../ui/Col"
import Row from "../../ui/Row"

export interface TopRoomBlockProps extends TopRoomBlockSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

const aspectRatio = 83 / 47

function TopRoomBlock({ style, screenWidth }: TopRoomBlockProps) {
  const calculateWidth = useCallback((screenWidth: number) => {
    if (screenWidth <= 1100) {
      return document.querySelector(".top-block-wrapper")?.getBoundingClientRect().width || screenWidth
    } else {
      return screenWidth / 2
    }
  }, [])

  const width = useMemo(() => calculateWidth(screenWidth), [ screenWidth ])

  const [ dimensions, setDimensions ] = useState({
    width: width,
    height: width / aspectRatio,
  })

  useEffect(() => {
    setDimensions({
      width: width,
      height: width / aspectRatio,
    })
  }, [ screenWidth ])

  useEffect(() => {
    setTimeout(() => {
      const width = calculateWidth(screenWidth)
      setDimensions({ width: width, height: width / aspectRatio })
    }, 0)
  }, [])

  return (
    <TopRoomBlockSC style={ style }>
      <Row style={ { alignSelf: "center" } }>
        { screenWidth <= 1600 && screenWidth > 1100 && <Text className="topRoomBlock-h1">Themed Rooms</Text> }
      </Row>
      <Row className="top-room-wrapper" gap={ screenWidth > 1100 ? 48 : 28 }>
        <Col className="top-block-wrapper top-block-wrapper-text" gap={ 24 }>
          { screenWidth > 1600 && <Text className="topRoomBlock-h1">Themed Rooms</Text> }
          <Text>
            We at Snook are proponents of collaboration and aim to foster crypto communities.
            So, we created a mini-Snook-verse. One in which any group, community, collection or brand,
            could personalize and make their own. We called it Themed Rooms
            [Yeah, we’re not thrilled about the name either.
            Please feel free to suggest an alternative] or BBT-TR for short.
          </Text>
          <Text>
            How is this fostering crypto-communities? Don’t be a cynic. It’s unbecoming.
            Imagine a snook playing-ground with your distinct branding and graphic language (the snooks too).
            Imagine a familiar environment, one that makes you proud,
            one that showcases your work to community members and to others. Can you see it?
          </Text>
          <Row justify={ screenWidth > 1600 ? "start" : "center" }>
            <a href="https://calendly.com/cryptoanalyst/snook-bbt-themed-rooms" target="_blank">
              <RoundButton
                color={ RoundButtonTypeEnum.ORANGE }
                className="top-room-btn"
              >
                TELL ME MORE
              </RoundButton>
            </a>
          </Row>
        </Col>
        <Col className="top-block-wrapper top-block-wrapper-video">
          <div style={ { width: dimensions.width, height: dimensions.height } }>
            <div style={ { width: dimensions.width, height: dimensions.height } } className="top-video" id="top-video">

            </div>
          </div>
        </Col>
        { screenWidth <= 1100 && <Text className="topRoomBlock-h1">Themed Rooms</Text> }
      </Row>
    </TopRoomBlockSC>
  )
}

export default memo(TopRoomBlock)
