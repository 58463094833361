import styled from "styled-components"
import triangle from "./images/triangle.svg"
import pxToRem from "../../utils/pxToRem"

export enum RoundButtonTypeEnum {
  BLUE = "blue",
  ORANGE = "orange",
  GREEN = "green",
  ALT_BLUE = "alt-blue",
  VIOLET = "violet",
}

export interface RoundButtonSCProps {
}

export const RoundButtonSC = styled.div<RoundButtonSCProps>`
  display: inline-block;
  position: relative;

  border: ${ pxToRem(4) } solid rgba(0, 0, 0, 0.16);
  border-radius: ${ pxToRem(45) };

  cursor: pointer;

  .text {
    padding: ${ pxToRem(32) } ${ pxToRem(34) };

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: ${ pxToRem(34) };
    line-height: 98%;
    position: relative;

    display: flex;
    gap: ${ pxToRem(24) };
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
    }

    .sub {
      font-size: ${ pxToRem(16) };
    }
  }

  &.with-sub {
    transform: translateY(-${ pxToRem(13) });

    .text {
      padding: ${ pxToRem(22) } ${ pxToRem(34) };
      line-height: 78%;
      
      .sub {
        line-height: 1rem;
      }
    }
  }

  transition: opacity .3s ease-in-out, background-color .3s ease-in-out;

  .icon {
    mask: url(${ triangle }) no-repeat 50% 50%;
    background-color: #FFFFFF;
    width: 16px;
    height: 18px;
  }

  .buttons > div {
    background-blend-mode: overlay, normal;
    box-shadow: 0 0 -${ pxToRem(1) } ${ pxToRem(4) } #000000 inset, 0 0 ${ pxToRem(1) } ${ pxToRem(4) } #FFFFFF inset;
    border-radius: ${ pxToRem(45) };

    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
  }

  .idle {
    background-blend-mode: overlay;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  }

  .hover {
    background-blend-mode: overlay;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    opacity: 0;
  }

  .active {
    background-blend-mode: overlay;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    opacity: 0;
  }

  &:hover {
    .hover {
      display: block;
      opacity: 1;
    }
  }

  &:active {
    .active {
      display: block;
      opacity: 1;
    }
  }

  &.orange {
    color: #FFFFFF;

    .buttons > div {
      background-color: #FF9E2D;
      background-blend-mode: overlay;
    }

    &:hover .icon {
      background-color: #FFFFFF;
    }

    &:active .icon, .icon {
      background-color: #FFF0A4;
    }

    &:active .text {
      color: #FFF0A4;
    }
  }

  &.green {
    color: #FFFFFF;

    .buttons > div {
      background-color: #35E328;
      background-blend-mode: overlay;
    }

    &:hover .icon {
      background-color: #FFFFFF;
    }

    &:active .icon, .icon {
      background-color: #CCFFA4;
    }

    &:active .text {
      color: #CCFFA4;
    }
  }

  &.blue {
    color: #FFFFFF;

    .buttons > div {
      background-color: #3284FF;
      background-blend-mode: overlay;
    }

    &:hover .icon {
      background-color: #FFFFFF;
    }

    &:active .icon, .icon {
      background-color: #C2D4ED;
    }

    &:active .text {
      color: #C2D4ED;
    }
  }

  &.alt-blue {
    color: #FFFFFF;

    .buttons > div {
      background-color: #60C2FF;
      background-blend-mode: overlay;
    }

    &:hover .icon {
      background-color: #FFFFFF;
    }

    &:active .icon, .icon {
      background-color: #CBEBFF;
    }

    &:active .text {
      color: #CBEBFF;
    }
  }

  &.violet {
    color: #FFFFFF;

    .buttons > div {
      background-color: #BC9CFF;
      background-blend-mode: overlay;
    }

    &:hover .icon {
      background-color: #FFFFFF;
    }

    &:active .icon, .icon {
      background-color: #DCCCFF;
    }

    &:active .text {
      color: #DCCCFF;
    }
  }
  
   @media screen and (max-width: 1100px) {
     border-radius: 33.75px;
     
     .buttons > div {
      border-radius: ${ pxToRem(33.75) };
     }
     
    .text {
      padding: ${ pxToRem(25) } ${ pxToRem(25.5) };
      font-size: ${ pxToRem(25.5) };
    }
  }

  @media screen and (max-width: 700px) {
    &.with-sub {
      .text {
        padding: ${ pxToRem(21) } ${ pxToRem(25.5) } ${ pxToRem(18) } ${ pxToRem(25.5) };
      }
    }

    .text {
      .sub {
        font-size: ${ pxToRem(12) };
        font-weight: 400;
        //line-height: 1.2rem;
        padding-top: ${ pxToRem(2) };
      }
    }
  }
`
