import { memo } from "react"
import { ContainerSC, TitleSC, TotalElementSC, TotalElementSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"

export interface TotalElementProps extends TotalElementSCProps {
  className?: string
  style?: CSSProperties
  img?: any
  text: string
  value: string
}

function TotalElement({ className, style, img, text, value }: TotalElementProps) {
  return (
    <ContainerSC>
      <TitleSC className="titlesc" justify="center" alignItems="center">
        <Text className="capture">
          { text }
        </Text>
      </TitleSC>
      <TotalElementSC className={ className } style={ style } justify="center" alignItems="center">
        <Text className="numbers">{ value }</Text>
        <img src={ img } alt={ img } className="bg-img"/>
      </TotalElementSC>
    </ContainerSC>

  )
}

export default memo(TotalElement)
