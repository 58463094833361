import { memo } from "react"
import { CSSProperties } from "styled-components"

import { RoundButtonSC, RoundButtonSCProps, RoundButtonTypeEnum } from "./styled"
import classNames from "classnames"

export interface RoundButtonProps extends RoundButtonSCProps {
  className?: string
  children?: any
  style?: CSSProperties
  color: RoundButtonTypeEnum
  sub?: string
  withIcon?: boolean
}

function RoundButton({ className, style, children, color, sub, withIcon = true }: RoundButtonProps) {
  return (
    <RoundButtonSC className={ classNames(className, color, sub ? "with-sub" : null) } style={ style }>
      <div className="buttons">
        <div className="idle"></div>
        <div className="hover"></div>
        <div className="active"></div>
      </div>
      <div className="text">
        {
          sub ? (
            <div>
              <div>{ children }</div>
              <div className="sub">{ sub }</div>
            </div>
          ) : children
        }
        { withIcon ? <div className="icon"/> : <></> }
      </div>
    </RoundButtonSC>
  )
}

export default memo(RoundButton)
