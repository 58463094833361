import styled from "styled-components"
import Col from "../ui/Col"
import pxToRem from "../../utils/pxToRem"

export interface WannaDiveSCProps {

}

export const WannaDiveSC = styled(Col)<WannaDiveSCProps>`
  padding: 4rem 0 8rem;

  .dive-cards {
    padding: 4rem 0;
    width: 100%;
  }

  .deepbtn {
    width: 100%;
    flex-grow: 1;
    height: ${ pxToRem(100) };
    border: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), #FF9E2D;
    background-blend-mode: overlay, normal;
    box-shadow: inset 0 -${ pxToRem(1) } ${ pxToRem(4) } #000000, inset 0 ${ pxToRem(1) } ${ pxToRem(4) } #FFFFFF;
    border-radius: ${ pxToRem(45) };
  }

  .dive-btn {
    padding: 4rem 0 8rem;
  }

  @media screen and (max-width: 1400px) {
    .dive-card-text {
      font-size: 2rem !important;
    }
  }

  @media screen and (max-width: 1100px) {
    .dive-cards {
      gap: 1.5rem;
    }

    .dive-card-text {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (max-width: 1008px) {
    .dive-cards {
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 5rem 0 3rem;
    .dive-cards {
      padding: 2rem 0 4rem !important;
    }

    .dive-btn {
      padding: 4rem 0 8rem;
    }
  }

`

export const SmallWrapperSC = styled(Col)`
  @media screen and (max-width: 1300px) {
    width: 100vw !important;
    gap: 3rem;
    padding: 4rem 1.5rem;
  }
`
