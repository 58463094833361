import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Row from "../../ui/Row"

const bg = new URL("./bg.png?as=webp&width=1920", import.meta.url).toString()
const bgHex = new URL("../../../../public/bg-hex.png?as=webp&width=1080", import.meta.url).toString()

export interface PrincipleSCProps {
}

export const PrincipleSC = styled(Row)<PrincipleSCProps>`
  border-bottom: ${ pxToRem(2) } solid #FDC500;
  position: relative;
  gap: ${ pxToRem(66) };
  background: linear-gradient(180deg, #150051 0%, rgba(21, 0, 81, 0) 100%), #002051;

  z-index: 2;

  > * {
    z-index: 3;
  }

  &::after {
    content: "";
    background: url(${ bg });
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    background-blend-mode: luminosity;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .principle-wrapper {
    padding: ${ pxToRem(260) } ${ pxToRem(20) } ${ pxToRem(400) } ${ pxToRem(20) };
    width: ${ pxToRem(1440) };
    display: flex;
  }

  p {
    text-align: left;
  }

  .cashflow {
    position: absolute;
    z-index: 6;
    bottom: ${ pxToRem(-160) };
    left: 50%;
    transform: translate(-50%, 0);
    width: ${ pxToRem(1440) };
    padding: ${ pxToRem(88) } 3rem ${ pxToRem(88) };
    background: linear-gradient(180deg, rgba(0, 6, 157, 0) 0%, rgba(0, 6, 157, 0.5) 100%), #3284FF;
    border-bottom: ${ pxToRem(8) } solid #FDC500;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: ${ pxToRem(16) };

    p {
      text-align: center;
    }

    &::after {
      content: "";
      background: url(${ bgHex });
      opacity: 0.04;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 6;
      background-blend-mode: luminosity;
    }
  }

  .tokenomics-block {
    flex-grow: 1;
    width: 100%;
    padding-right: ${ pxToRem(60) };
  }

  .principle-wrap {
    flex-grow: 2;
    width: 100%;
  }

  .principle-block {
    position: absolute;
    right: 0;
    top: ${ pxToRem(180) };
    width: 60vw;
    max-width: ${ pxToRem(1100) };
    z-index: 5;
    background: radial-gradient(100% 474.59% at 100% 18.53%, rgba(16, 59, 123, 0.29) 0%, rgba(16, 59, 123, 0) 100%),
    linear-gradient(89.98deg, #050720 0.02%, rgba(5, 7, 32, 0) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #00122C;
    background-blend-mode: screen, normal, normal, multiply, normal;
    border-bottom: ${ pxToRem(4) } solid #FDC500;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: ${ pxToRem(16) } 0px 0px ${ pxToRem(16) };
    padding: ${ pxToRem(51) } ${ pxToRem(66) };

    .principle-text {
      width: ${ pxToRem(668) };
    }

    &::after {
      content: "";
      background: url(${ bgHex });
      opacity: 0.01;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 6;
      background-blend-mode: luminosity;
    }

    li {
      text-align: left;
      color: #FDC500;
    }

    .principle-title {
      font-family: 'Source Sans Pro', sans-serif;;
      font-weight: 900;
      font-size: ${ pxToRem(40) };
      line-height: 98%;
      letter-spacing: -0.05em;
      text-transform: capitalize;
    }

    .snake-first {
      position: absolute;
      right: 0;
      bottom: ${ pxToRem(-280) };
      width: 480px;
      transform: rotate(-0.22deg);
    }
  }

  .tokenomics-title {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 900;
    font-size: ${ pxToRem(72) };
    line-height: 98%;
    text-align: left;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }

  .tokenomics-btn {
    width: ${ pxToRem(341) };
    margin-top: ${ pxToRem(12) };
  }

  @media screen and (max-width: 1600px) {
    .principle-block {
      .snake-first {
        right: -${ pxToRem(50) };
      }

      .principle-text {
        width: ${ pxToRem(568) };
      }
    }

    .cashflow {
      width: 90vw;
    }

    .tokenomics-block {
      padding-left: 2.5rem;
      padding-right: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    .snake-first {
      display: none;
    }
  }

  @media screen and (max-width: 1100px) {
    .principle-wrapper {
      display: none;
    }

    .cashflow {
      padding: ${ pxToRem(60) } 3rem;
    }
  }

  @media screen and (max-width: 700px) {
    .cashflow {
      padding: ${ pxToRem(37) } ${ pxToRem(16) };
      bottom: ${ pxToRem(-100) };
    }

    .tokenomics-title {
      font-size: ${ pxToRem(36) };
    }
  }

`
