import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"

export interface TextSCProps {
  color?: string
  weight?: number
  size?: number | string
  lineHeight?: number | string
  letterSpacing?: number | string
  uppercase?: boolean
  align?: "center" | "left" | "right"
}

export const TextSC = styled.p<TextSCProps>`
  text-transform: ${ ({ uppercase }) => (uppercase ? "uppercase" : "none") };
  color: ${ ({ color }) => color ? color : "#fff" };
  font-weight: ${ ({ weight }) => weight ? weight : 400 };
  font-size: ${ ({ size }) => size ? `${ pxToRem(size) }` : `${ pxToRem(16) }` };
  line-height: ${ ({ lineHeight }) => lineHeight ? `${ lineHeight }px` : `${ pxToRem(24) }` };
  letter-spacing: ${ ({ letterSpacing }) => letterSpacing ? letterSpacing : "normal" };
  text-align: ${ ({ align }) => align ? align : "center" };
`
