import React, { CSSProperties, memo, useCallback, useState } from "react"
import { IntegratedSC } from "./styled"
import Text from "../../ui/Text"
import integrated from "./integratedImg.svg"
import ScaleOut from "../../ScaleOut"

export interface IntegratedProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

function Integrated({ className, style, screenWidth }: IntegratedProps) {
  const [ isOpen, setOpen ] = useState<boolean>(false)
  const open = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])

  return (
    <IntegratedSC className={ className } style={ style } alignItems="center" gap={ 100 }>
      <Text className="integrated-h1">Integrated View of <span
        className="colored-title">Key Economic Dynamics</span></Text>
      <img src={ integrated } alt="integrated" className="integrated-img" onClick={ open }/>
      <ScaleOut
        title={ <>Integrated View of <span className="colored-title">Key Economic Dynamics</span></> }
        img={ integrated }
        isOpen={ isOpen }
        onClose={ close }
      />
      { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
    </IntegratedSC>
  )
}

export default memo(Integrated)
