import React, { memo } from "react"
import { TermsSC, TermsSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import Col from "../ui/Col"

export interface TermsProps extends TermsSCProps {
  className?: string
  style?: CSSProperties
  title: string
  updated: string
  effective?: string
  children: any
}

const police = {
  small: new URL("police.png?as=webp&width=472", import.meta.url).toString(),
  big: new URL("police.png?as=webp&width=944", import.meta.url).toString(),
}

function Terms({ className, style, title, updated, effective, children }: TermsProps) {
  return (
    <TermsSC className={ className } style={ style } gap={ 30 }>
      <Col gap={ 10 }>
        <Text className="h1-term">{ title }</Text>
        <Text className="description">
          Effective date: { effective ?? updated }
        </Text>
        <Text className="description" style={ { marginTop: "-1rem" } }>
          Last updated: { updated }
        </Text>
      </Col>

      { children }

      <img src={ police.small } srcSet={ `${ police.big } 2x` } alt="police" className="police"/>
    </TermsSC>
  )
}

export default memo(Terms)
