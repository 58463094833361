import React, { memo, useCallback, useEffect, useMemo } from "react"
import {
  BlueBallSC,
  NavigationBottomSC,
  NavigationLinkSC,
  NavigationMiddleSC,
  NavigationSC,
  NavigationSCProps,
  NavigationTopSC,
  RedBallSC,
  YellowBallSC,
} from "./styled"
import { CSSProperties } from "styled-components"
import classNames from "classnames"
import { HashLink as Link } from "react-router-hash-link"
import HexagonButton from "../HexagonButton"
import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../HexagonButton/styled"
import Row from "../ui/Row"

export interface NavigationProps extends NavigationSCProps {
  className?: string
  style?: CSSProperties
  show: boolean
  toggleNav: () => void
  screenWidth: number
}

function Navigation({ className, style, show, toggleNav, screenWidth }: NavigationProps) {
  useEffect(() => {
    const nav = document.querySelector(".navigation")
    const navToggle = document.querySelector(".navigation-toggle")
    const callback = (event: MouseEvent) => {
      if (event.target && nav && navToggle) {
        const isClickInside = nav.contains(event.target as any)
        const isClickToggle = navToggle.contains(event.target as any)
        if (!isClickToggle && !isClickInside) {
          show && toggleNav()
        }
      }
    }
    document.addEventListener("click", callback)

    return () => {
      document.removeEventListener("click", callback)
    }
  }, [ show ])

  const scrollWithOffset = useCallback((el: any, offset: number) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY
    window.scrollTo({ top: yCoordinate + offset, behavior: "smooth" })
  }, [])

  const gap = useMemo(() => screenWidth <= 700 ? -100 : 0, [ screenWidth ])

  return (
    <NavigationSC className={ classNames("navigation", className, show ? "visible" : "hidden") } style={ style }>
      <NavigationTopSC>

      </NavigationTopSC>
      <NavigationMiddleSC>
        <RedBallSC/>
        <Link smooth to="/#play" onClick={ toggleNav } scroll={ el => scrollWithOffset(el, gap - 40) }>
          <NavigationLinkSC>Playing Snook</NavigationLinkSC>
        </Link>
        <Link smooth to="/#how-to-earn" onClick={ toggleNav } scroll={ el => scrollWithOffset(el, gap + 0) }>
          <NavigationLinkSC>How to Earn!</NavigationLinkSC>
        </Link>
        <Link smooth to="/#special-skins" onClick={ toggleNav } scroll={ el => scrollWithOffset(el, gap - 40) }>
          <NavigationLinkSC>Special Skins</NavigationLinkSC>
        </Link>
        <Link smooth to="/#game-modes" onClick={ toggleNav } scroll={ el => scrollWithOffset(el, gap - 40) }>
          <NavigationLinkSC>Game Modes</NavigationLinkSC>
        </Link>
        <a href="https://whitepaper.snook.gg/" target="_blank">
          <NavigationLinkSC>
            Whitepaper
          </NavigationLinkSC>
        </a>
        <Link to="/privacy#" onClick={ toggleNav }>
          <NavigationLinkSC>Privacy Policy</NavigationLinkSC>
        </Link>
        <Link to="/terms#" onClick={ toggleNav }>
          <NavigationLinkSC>Terms & Conditions</NavigationLinkSC>
        </Link>
        { screenWidth < 900 && (
          <Row gap={ 8 } justify="start" className="mob-nav-socials">
            <a href="https://discord.gg/snook-819160388725637131" target="_blank">
              <HexagonButton
                icon={ HexagonButtonIconEnum.DISCORD }
                type={ HexagonButtonTypeEnum.BLUE }
                scale={ 0.8 }
              />
            </a>
            <a href="https://twitter.com/snook_gg" target="_blank">
              <HexagonButton
                icon={ HexagonButtonIconEnum.TWITTER }
                type={ HexagonButtonTypeEnum.BLUE }
                scale={ 0.8 }
              />
            </a>
            <a href="https://t.me/+Psf47QU5p-I1ZWM0" target="_blank">
              <HexagonButton
                icon={ HexagonButtonIconEnum.TELEGRAM }
                type={ HexagonButtonTypeEnum.BLUE }
                scale={ 0.8 }
              />
            </a>
          </Row>
        ) }
      </NavigationMiddleSC>
      <NavigationBottomSC>
        <YellowBallSC/>
        <BlueBallSC/>
      </NavigationBottomSC>
    </NavigationSC>
  )
}

export default memo(Navigation)
