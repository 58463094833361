import { memo } from "react"
import { GameModesSC, GameModesSCProps, SmallWrapperSC } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import GameModesCard from "../GameModesCard"

const battle = {
  small: new URL("./battle.png?as=webp&width=596", import.meta.url).toString(),
  big: new URL("./battle.png?as=webp&width=1192", import.meta.url).toString(),
}

const battleMobile = {
  big: new URL("./battle-mobile.png?as=webp&width=492", import.meta.url).toString(),
}

const boys = {
  small: new URL("./boys.png?as=webp&width=596", import.meta.url).toString(),
  big: new URL("./boys.png?as=webp&width=1192", import.meta.url).toString(),
}

const boysMobile = {
  big: new URL("./boys-mobile.png?as=webp&width=492", import.meta.url).toString(),
}


export interface GameModesProps extends GameModesSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

function GameModes({ className, style, screenWidth }: GameModesProps) {
  return (
    <GameModesSC className={ className } style={ style } alignItems="center">
      <div id="game-modes" />
      <Text className="h1">Game <span className="colored-title">Modes</span></Text>
      <SmallWrapperSC gap={ 98 } className="game-mods-card small-wrapper" justify={ "center" }>
        <GameModesCard
          title="Battle Arena"
          description={ (<Text className="description">Play with players around the world in the same shrinking map.
            Earn <b>SNK</b>, collect traits and build your Special Skins as you go.
            The last snook in this time-limited gaming mode wins!</Text>) }
          img={ screenWidth > 860 ? battle.small : battleMobile.big }
          srcSet={ screenWidth > 860 ? `${ battle.big } 2x` : `${ battleMobile.big } 1x` }
        />
        <GameModesCard
          title="Big Boys Table"
          description="Unbeatable? Feeling confident?
          The Big Boys Table game mode allows you to raise the stakes on your skill against other players.
          Simply join or create your own room."
          img={ screenWidth > 860 ? boys.small : boysMobile.big }
          srcSet={ screenWidth > 860 ? `${ boys.big } 2x` : `${ boysMobile.big } 1x` }
        />
      </SmallWrapperSC>
    </GameModesSC>
  )
}

export default memo(GameModes)
