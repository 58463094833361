import { memo } from "react"
import { AnimatedArrowStraightSC, AnimatedArrowStraightSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import dot from "./images/earn-dot.svg"
import arrow from "./images/earn-arrow.svg"

export interface AnimatedArrowStraightProps extends AnimatedArrowStraightSCProps {
  className?: string
  style?: CSSProperties
}

function AnimatedArrowStraight({ className, style, delay }: AnimatedArrowStraightProps) {
  return (
    <AnimatedArrowStraightSC className={ className } style={ style } delay={ delay }>
      <div className="animated-arrow-container">
        <img src={ arrow } alt="arrow" className="earn-arrow"/>
        <img src={ dot } alt="dot" className="earn-dot"/>
        <img src={ dot } alt="dot" className="earn-dot"/>
        <img src={ dot } alt="dot" className="earn-dot"/>
      </div>
    </AnimatedArrowStraightSC>
  )
}

export default memo(AnimatedArrowStraight)
