import React, { memo } from "react"
import { ParticipationSC, ParticipationSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../../ui/Text"
import RoundButton from "../../RoundButton"
import { RoundButtonTypeEnum } from "../../RoundButton/styled"
import Col from "../../ui/Col"
import Row from "../../ui/Row"

import card1Mob from "./card1-mob.svg"
import card2Mob from "./card2-mob.svg"
import card3Mob from "./card3-mob.svg"
import card4Mob from "./card4-mob.svg"

const card1 = new URL("./card1.png?as=webp", import.meta.url).toString()
const card2 = new URL("./card2.png?as=webp", import.meta.url).toString()
const card3 = new URL("./card3.png?as=webp", import.meta.url).toString()
const card4 = new URL("./card4.png?as=webp", import.meta.url).toString()

export interface ParticipationProps extends ParticipationSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

function Participation({ style, screenWidth }: ParticipationProps) {
  return (
    <ParticipationSC style={ style } justify="center">
      <Row className="participation-wrapper" gap={ screenWidth > 1600 ? 124 : 28 }>
        <Col className="participation-cards">
          <img src={ card1 } alt="card1" className="card1"/>
          <img src={ card2 } alt="card2" className="card2"/>
          <img src={ card3 } alt="card3" className="card3"/>
          <img src={ card4 } alt="card4" className="card4"/>
        </Col>
        <Row className="participation-cards-mob" gap={ 24 }>
          <Col gap={ 24 }>
            <img src={ card1Mob } alt="card1" className="card1-mob"/>
            <img src={ card2Mob } alt="card2" className="card2-mob"/>
          </Col>
          <Col gap={ 24 }>
            <img src={ card3Mob } alt="card3" className="card3-mob"/>
            <img src={ card4Mob } alt="card4" className="card4-mob"/>
          </Col>
        </Row>
        <Col className="participation-text" gap={ 24 }>
          <Text className="participation-h2">Participation</Text>
          <Text>As mentioned above, the BBT-TR is designed to empower crypto-communities and brands.
            That first term, “crypto-communities” is purposefully ambiguous.
            We do not want to limit the participation. In fact, we want to set a low bar for participation.
            Therefore, if a group of crypto-enthusiasts wants to set up a BBT-TR they can fairly easily create
            a small NFT collection and apply for it to be listed in Snook.
            There is also the matter of Staking which we describe next. (We said “low bar”. We didn’t say no bar.)
          </Text>
          <Row justify={ screenWidth > 1600 ? "start" : "center" }>
            <a href="https://calendly.com/cryptoanalyst/snook-bbt-themed-rooms" target="_blank" className="participation-btn">
              <RoundButton color={ RoundButtonTypeEnum.ORANGE }>Go on...</RoundButton>
            </a>
          </Row>
        </Col>
      </Row>
    </ParticipationSC>
  )
}

export default memo(Participation)
