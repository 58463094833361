import styled from "styled-components"
import pxToRem from "../../../../utils/pxToRem"
import Col from "../../../ui/Col"

const bgHex = new URL("../../../../../public/bg-hex.png?as=webp&width=1080", import.meta.url).toString()

export interface PrincipleMobileSCProps {
}

export const PrincipleMobileSC = styled(Col)<PrincipleMobileSCProps>`
  padding: ${ pxToRem(200) } ${ pxToRem(65) } ${ pxToRem(400) } ${ pxToRem(65) };
  display: none;
  align-items: center;

  position: relative;
  z-index: 7;

  > * {
    z-index: 7;
  }

  &::after {
    content: "";
    background: url(${ bgHex });
    opacity: 0.01;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 6;
    background-blend-mode: luminosity;
  }

  .tokenomics-title-mobile {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(55) };
    line-height: 98%;
    text-align: center;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }

  .tokenomics-description-mobile {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
  }

  .snake-mobile {
    display: none;
  }

  .principle-mobile {
    position: relative;
    z-index: 5;
    background: radial-gradient(100% 474.59% at 100% 18.53%, rgba(16, 59, 123, 0.29) 0%, rgba(16, 59, 123, 0) 100%),
    linear-gradient(89.98deg, #050720 0.02%, rgba(5, 7, 32, 0) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #00122C;
    border-bottom: 4px solid #FDC500;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    padding: ${ pxToRem(32) } ${ pxToRem(51) };

    li {
      text-align: left;
      color: #FDC500;
    }

    .principle-mobile-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 900;
      font-size: ${ pxToRem(40) };
      line-height: 98%;
      letter-spacing: -0.05em;
      text-transform: capitalize;
    }

    .principle-mobile-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 1100px) {
    display: flex;
    padding: ${ pxToRem(360) } 3rem ${ pxToRem(300) };

  }

  @media screen and (max-width: 700px) {
    padding: ${ pxToRem(160) } 3rem ${ pxToRem(200) };

    .tokenomics-description-mobile {
      width: 296px;
    }

    .principle-mobile {
      width: 100vw;
    }

    .snake-mobile-wrap {
      position: relative;
      height: 80vw;
      z-index: 5;
    }

    .snake-mobile {
      display: block;
      position: absolute;
      top: -20vw;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
      width: 100vw;
      //height: 150vw;
    }
  }

  @media screen and (max-width: 500px) {
    padding: ${ pxToRem(160) } 3rem ${ pxToRem(300) };
  }
`
