import React, { memo } from "react"
import Text from "../../components/ui/Text"
import Terms from "../../components/Terms"

function PrivacyPolicy() {
  return (
    <Terms title="Privacy Policy" updated="July 3rd, 2022" effective="July 3rd, 2022">
      <Text className="h3-term">1. INTRODUCTION</Text>

      <Text>
        Welcome to <b>Snook</b>, a multichain, play-to-earn "Snake"-like IO game, available through <a
        href="https://play.snook.gg">https://play.snook.gg</a>, that
        rewards performance achieved through hard work, commitment, skill and talent (the "<b>Game</b>" or "<b>Snook</b>").
      </Text>

      <Text>
        We are strongly committed to transparency and take the protection of your privacy and personal data seriously.
        To further this commitment, we have adopted this Privacy Policy to guide you on how we may use the information
        we collect from you.
      </Text>

      <Text>
        This Privacy Policy applies to our website located at <a
        href="https://www.snook.gg">https://www.snook.gg</a> (the "<b>Website</b>") and to Snook. Please
        read it
        carefully before using the Website or playing Snook. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
        PLEASE DO NOT USE THE WEBSITE OR PLAY THE GAME.
      </Text>

      <Text>
        BY USING THE WEBSITE OR PLAYING SNOOK, YOU ARE EXPRESSING YOUR AGREEMENT TO THIS POLICY AND THE PROCESSING OF
        YOUR DATA, INCLUDING YOUR PERSONAL INFORMATION AS DESCRIBED IN THE POLICY.
      </Text>

      <Text className="h3-term">2. INFORMATION WE MAY COLLECT</Text>

      <Text>
        To enable us to operate Snook effectively, and to provide you with the best experience on the Game, we need some
        basic information from you.
      </Text>

      <Text>
        For such, we may collect:
      </Text>

      <ul>
        <li>
          <Text>
            Device and usage data: we may collect data about your device (e.g., your IP address, browser type, operating
            system, etc.) and how you and your device interact with the Game;
          </Text>
        </li>
        <li>
          <Text>
            Your crypto wallet address.
          </Text>
        </li>
      </ul>

      <Text>
        WE DO NOT COLLECT OR USE INFORMATION THAT IDENTIFIES YOU PERSONALLY (e.g., NAME, ADDRESS, EMAIL, PHONE NUMBER,
        ETC.).
      </Text>

      <Text>
        You may find below information on the legal basis for the processing, as well as the purposes for which your
        data may be processed.
      </Text>

      <table>
        <tr>
          <th>Legal basis</th>
          <th>Purpose</th>
          <th>Period of time</th>
        </tr>
        <tr>
          <td>Performance of a contract</td>
          <td>Provide you with the Game at your request</td>
          <td>We do not store any of your personal data</td>
        </tr>
        <tr>
          <td>Consent</td>
          <td>
            To run analytics and understand how users interact with the Game in order to to improve it continuously
          </td>
          <td>We do not store any of your personal data</td>
        </tr>
      </table>

      <Text className="h3-term">3. USE OF YOUR DATA BY THIRD PARTIES</Text>

      <Text>
        We may involve third-party tracking and data collection and analytics tools from our service providers. Such
        third parties may use cookies and SDKs to enable them to collect and analyze user and device-related data and
        information on our behalf. With your consent, these third-parties may have access to and collect data and
        information about you. Such information includes, but is not limited to the following:
      </Text>

      <ul>
        <li><Text>IP address;</Text></li>
        <li><Text>Device events;</Text></li>
        <li><Text>Hardware settings;</Text></li>
        <li><Text>Browser type;</Text></li>
        <li><Text>Browser language;</Text></li>
        <li><Text>The date and time of request and referral URL;</Text></li>
        <li><Text>Cookies that may uniquely identify your browser.</Text></li>
      </ul>

      <Text>
        If you prefer that this data is not collected, you can at any time request the different providers to cease the
        collection and processing of such data.
      </Text>

      <Text>
        Below is a list of third parties we currently use on the Game:
      </Text>

      <ul>
        <li>
          <Text>
            GameAnalytics - <a href="https://gameanalytics.com/privacy/">https://gameanalytics.com/privacy/</a>
          </Text>
        </li>
      </ul>

      <Text className="h3-term">4. WHO DO WE SHARE YOUR DATA WITH</Text>

      <Text>
        We may share your personal data as necessary to make the Game available to you. We may also share your data when
        required by law; to maintain the security and integrity of the Game; and to protect our rights to the Website
        and Snook.
      </Text>

      <Text>
        If we are involved in defending a legal claim, we may disclose information about you that is relevant to the
        claim to third parties as a result of, or in connection with, the associated legal proceedings.
      </Text>

      <Text>
        We are not expecting to transfer any of your personal data outside the European Economic Area ("EEA"). In any
        case, if this should happen, special safeguards are foreseen to ensure that the protection travels with the
        data. These safeguards include entering into a contract incorporating the standard data protection clauses
        adopted by the Commission ("standard contractual clauses"). Such clauses contain contractual obligations on the
        data exporter and the data importer, and rights for the individuals whose personal data is transferred.
      </Text>

      <Text className="h3-term">5. HOW WE WILL PROTECT YOUR DATA</Text>

      <Text>
        We have implemented reasonable and appropriate technical and organizational measures to ensure a level of
        security appropriate to the risk, especially with the aim of protecting your personal data against accidental or
        unlawful destruction, loss, alteration, unauthorized disclosure or access.
      </Text>

      <Text>
        However, no method of transmission over the Internet and no method of storage (either electronic or physical) is
        completely secure. Although we will do our best to protect your personal data, we cannot guarantee the absolute
        security and integrity of the transmission of your data to our systems. Any transmission is at your own risk.
      </Text>

      <Text className="h3-term">6. CHANGES TO THIS POLICY</Text>

      <Text>
        We reserve the right to make any alterations and/or amendments to this Policy. We will notify you of any changes
        by updating the "Effective Date" at the top of this Privacy Policy. You are advised to consult it periodically.
      </Text>

      <Text>
        Changes to this Privacy Policy are effective when they are posted on this page.
      </Text>

      <Text className="h3-term">7. HAVE ANY QUESTIONS?</Text>

      <Text>
        If you have any questions regarding the processing of your data, please contact us at <a
        href="mailto:nfo@snook.gg">info@snook.gg</a>.
      </Text>
    </Terms>
  )
}

export default memo(PrivacyPolicy)
