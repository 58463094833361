import styled from "styled-components"
import Col from "../ui/Col"
import pxToRem from "../../utils/pxToRem"

export interface ScaleOutSCProps {
  width?: number
}

export const ContainerSC = styled.div`
  position: relative;
  background: #00122C;
  height: 100%;
`

export const ScaleOutSC = styled(Col)`
  background-blend-mode: screen;
  background: radial-gradient(50.15% 50% at 50% 50%, rgba(16, 59, 123, 0.3) 0%, rgba(16, 59, 123, 0) 100%);
  height: 100%;

  padding-top: 2rem;
  padding-bottom: 2rem;

  gap: 2rem;
`

export const ImageSC = styled.div<ScaleOutSCProps>`
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;

  padding-left: 2rem;
  padding-right: 2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  > img {
    width: ${ props => props.width || 1600 }px
  }
`

export const TitleSC = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: ${ pxToRem(24) };
  font-style: normal;
  line-height: 98%;

  padding-left: 2rem;
  padding-right: 2rem;

  text-align: center;
  letter-spacing: -0.05em;

  color: #FDC500;
`

export const ButtonSC = styled.div`
  display: inline-block;
`
