import styled from 'styled-components'
import pxToRem from "../../../utils/pxToRem";

export interface BurgerSCProps {}

export const BurgerSC = styled.div<BurgerSCProps>`

  .burger {
    width: ${pxToRem(74)};
    height: ${pxToRem(84)};
  }
`
