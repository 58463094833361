import styled from "styled-components"

import pxToRem from "../../utils/pxToRem"

export interface HeroSectionSCProps {
}

export const HeroSectionContainerSC = styled.div`
  position: relative;

  min-height: 100vh;
  padding-top: 26rem;
  margin-bottom: 2rem;
  //transform: translateY(-50%);

  //background: linear-gradient(0deg, #103B7B 0%, #103B7B 10%, rgba(16, 59, 123, 0) 50%);

  z-index: 2;

  .btn {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .title {
    font-family: 'Source Sans Pro', sans-serif;;
    font-style: normal;
    font-weight: 900;
    font-size: ${ pxToRem(102) };
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: #FDC500;
  }

  .sub-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: ${ pxToRem(40) };
    line-height: 98%;
    text-align: center;
    color: #FFFFFF;

    padding: ${ pxToRem(8) } 0 ${ pxToRem(24) } 0;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    //background: linear-gradient(0deg, #103B7B 0%, #103B7B 20%, rgba(16, 59, 123, 0) 100%);

    .title {
      font-size: ${ pxToRem(82) };
    }

    .sub-title {
      font-size: ${ pxToRem(25) };
    }
  }

  @media screen and (max-width: 1000px) {
    //background: linear-gradient(0deg, #103B7B 0%, #103B7B 40%, rgba(16, 59, 123, 0) 100%);

    .title {
      font-size: ${ pxToRem(62) };
    }
  }

  @media screen and (max-width: 700px) {
    //background: linear-gradient(0deg, #103B7B 0%, #103B7B 35%, rgba(16, 59, 123, 0) 60%);
    padding-top: 9rem;

    .title {
      font-size: ${ pxToRem(50) };
    }

    .btn {
      margin-top: 20rem;
    }

    .mobile-description {
      padding-bottom: 8rem;
    }
  }

  @media screen and (max-width: 400px) {
    .btn {
      margin-top: 13rem;
    }
  }
`

export const HeroSectionSC = styled.div<HeroSectionSCProps>`
  position: relative;

`
