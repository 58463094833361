import styled from "styled-components"
import Col from "../ui/Col"
import { RoundButtonSC } from "../RoundButton/styled"
import pxToRem from "../../utils/pxToRem"

export interface FooterSCProps {
}

export const FooterSC = styled(Col)<FooterSCProps>`
  background: #002051;
  border-top: ${ pxToRem(1) } solid #FDC500;
  padding: 4rem 0;

  form {
    width: 50%;
  }

  span.disable-links {
    pointer-events: none;
  }

  .footer-logo {
    margin-left: -1.1rem;
  }

  .footer-center {
    border-top: ${ pxToRem(1) } solid #004171;
    border-bottom: ${ pxToRem(1) } solid #004171;
    padding: ${ pxToRem(36) } 0;
    margin: ${ pxToRem(36) } 0;
  }

  .footer-text p {
    font-weight: 700;
    font-size: ${ pxToRem(16) };
    line-height: ${ pxToRem(28) };
    color: #7D9DFF;
    text-align: left;

    cursor: pointer;

    transition: color .3s ease-in-out;

    :hover {
      color: #FDC500;
    }
  }

  .footer-text-normal {
    font-weight: 400 !important;
  }

  @media screen and (max-width: 1108px) {
    .footer-text {
      gap: 1rem;
    }

    .footer-text p {
      font-size: ${ pxToRem(14) };
    }
  }


  @media screen and (max-width: 800px) {
    padding-top: ${ pxToRem(64) };
    .socials-icons {
      flex-direction: column;
      align-items: center;
    }

    .mobile-block {
      flex-direction: column;
      align-items: center;
    }

    .footer-text {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${ pxToRem(24) } ${ pxToRem(61) };

      padding-top: ${ pxToRem(70) };
    }

    .mobile-block .footer-text p {
      text-align: left;
    }

    .mobile-block p {
      text-align: center;
    }

    .mobile-h3 {
      font-size: ${ pxToRem(32) };
    }

    .footer-center {
      border: none;
    }

    .footer-logo {
      margin-left: 0;
    }

    form {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

`

export const Input = styled.div`
  position: relative;
  flex-grow: 1;

  > input {
    background: #071935;
    border: ${ pxToRem(4) } solid rgba(0, 0, 0, 0.16);
    border-radius: 6.5rem;
    padding: 1.3rem 2rem;
    color: #FFFFFF;
    width: 100%;

    &::after {
      box-sizing: border-box;
    }

    ::placeholder {
      color: #93ADFF;
    }
  }

  ${ RoundButtonSC } {
    position: absolute;
    right: 0;

    .text {
      padding: 1rem 2rem;
    }
  }

  @media screen and (max-width: 800px) {
    > input {
      width: 100%;
    }
  }
`
