import {memo, ReactNode} from "react";
import { RowSC, RowSCProps} from "./styled";
import {CSSProperties} from "styled-components";

export interface RowProps extends RowSCProps {
  className?: string
  style?: CSSProperties
  children: ReactNode
  gap?: number;
  justify?: Justify;
  align?: Align;
  alignItems?: Align;
}

type Justify = 'end' | 'start' | 'center' | 'space-around' | 'space-between' | 'space-evenly'
type Align = 'end' | 'start' | 'center' | 'baseline' | 'stretch'

function Row({ className, style, children, gap, justify, align, alignItems }: RowProps) {
  return (
    <RowSC className={className} style={style} gap={gap} justify={justify} align={align} alignItems={alignItems}>
      {children}
    </RowSC>
  )
}
export default memo(Row)

