import { CSSProperties } from "styled-components"
import { memo } from "react"
import { TotalsSC, TotalsSCProps } from "./styled"
import TotalElement from "../TotalElement"
import total from "../HeroSection/total.svg"
import total1 from "../HeroSection/total1.svg"
import total2 from "../HeroSection/total2.svg"
import Row from "../ui/Row"

export interface TotalsProps extends TotalsSCProps {
  className?: string
  style?: CSSProperties
}

function Totals({ className, style }: TotalsProps) {
  return (
    <TotalsSC className={ className } style={ style }>
      <Row className="small-wrapper totals-block" gap={ 48 } justify="space-between">
        <TotalElement img={ total } text="Games Played" value="495,621"/>
        <TotalElement img={ total1 } text="Earned SNK" value="159,124"/>
        <TotalElement img={ total2 } text="Total Players" value="18,143"/>
      </Row>
    </TotalsSC>
  )
}

Totals

export default memo(Totals)