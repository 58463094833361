import React, { memo } from "react"
import { HeaderSC, HeaderSCProps } from "./styled"
import Row from "../ui/Row"
import Burger from "../ui/Burger"
import HexagonButton from "../HexagonButton"
import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../HexagonButton/styled"
import { HashLink as Link } from "react-router-hash-link"

const logo = new URL("./logo.png?as=webp", import.meta.url).toString()

export interface HeaderProps extends HeaderSCProps {
  screenWidth: number
  toggleNav: () => void
}

function Header({ screenWidth, toggleNav }: HeaderProps) {
  let scale = screenWidth > 1008 ? 1 : 0.8
  let burgerScale = screenWidth > 1008 ? 1.45 : 1
  let gapScale = screenWidth > 1008 ? 1 : 0.3

  return (
    <HeaderSC>
      <div className="mobile-filter"></div>
      <Row className="header-wrapper">
        <Link to="/#">
          <img id="logo" src={ logo } alt="site-logo" className="logo"/>
        </Link>
        <Row gap={ 28 * gapScale } className="icons">
          <Row gap={ 13 * gapScale } className="socials-icons">
            <a href="https://discord.gg/snook-819160388725637131" target="_blank">
              <HexagonButton
                icon={ HexagonButtonIconEnum.DISCORD }
                type={ HexagonButtonTypeEnum.BLUE }
                scale={ scale }
              />
            </a>
            <a href="https://twitter.com/snook_gg" target="_blank">
              <HexagonButton
                icon={ HexagonButtonIconEnum.TWITTER }
                type={ HexagonButtonTypeEnum.BLUE }
                scale={ scale }
              />
            </a>
            <a href="https://t.me/snookgg" target="_blank">
              <HexagonButton
                icon={ HexagonButtonIconEnum.TELEGRAM }
                type={ HexagonButtonTypeEnum.BLUE }
                scale={ scale }
              />
            </a>
          </Row>
          <Burger className="navigation-toggle" scale={ burgerScale } onClick={ toggleNav }/>
        </Row>
      </Row>
    </HeaderSC>
  )
}

export default memo(Header)
