import React, { memo } from "react"
import { CSSProperties } from "styled-components"

import { HexagonButtonSC, HexagonButtonSCProps } from "./styled"

export interface HexagonButtonMenuProps extends Omit<HexagonButtonSCProps, "scale"> {
  className?: string
  style?: CSSProperties
  scale?: number
}

function Button({ className, style, icon, flip, type, scale = 1 }: HexagonButtonMenuProps) {
  return (
    <HexagonButtonSC className={ className } type={ type } icon={ icon } flip={ flip } scale={ scale } style={ style }>
      <div className="idle"/>
      <div className="hover"/>
      <div className="active"/>
      <div className="icon"/>
    </HexagonButtonSC>
  )
}

export default memo(Button)