import styled from "styled-components"
import Col from "../ui/Col"
import pxToRem from "../../utils/pxToRem"

const imageDark = new URL("../../../public/image-dark.png?as=webp", import.meta.url).toString()

export interface TermsSCProps {
}

export const TermsSC = styled(Col)<TermsSCProps>`
  background: linear-gradient(360deg, #103B7B 1.06%, rgba(16, 59, 123, 0) 13.27%),
  linear-gradient(180deg, #103B7B 13.27%, rgba(16, 59, 123, 0) 49.97%),
  radial-gradient(50% 50% at 50% 50%, #103B7B 0%, rgba(16, 59, 123, 0) 100%),
  url(${ imageDark }), linear-gradient(0deg, #103B7B, #103B7B), #242424;
  background-blend-mode: normal, normal, normal, luminosity, normal, normal;

  a {
    color: #FFFFFF;
    text-decoration: underline;
  }

  p {
    text-align: left;
    font-weight: 400;
    font-size: ${ pxToRem(24) };
    line-height: ${ pxToRem(36) };
  }

  table {
    border: 1px solid #FFFFFF;
    border-collapse: collapse;

    th, td {
      border: 1px solid #FFFFFF;
      border-collapse: collapse;
      padding: 1rem;
    }
  }

  ol {
    list-style: lower-alpha;
  }

  position: relative;
  padding: ${ pxToRem(230) } ${ pxToRem(364) } 8rem ${ pxToRem(240) };

  .police {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 472px;
    height: 589px;
  }

  .h1-term {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(96) };
    letter-spacing: -0.05em;
    color: #FDC500;
    line-height: 98%;
  }

  .description {
    font-family: 'Source Sans Pro', sans-serif;;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 98%;
    padding-bottom: ${ pxToRem(36) };
  }

  .h3-term {
    font-weight: 700;
    font-size: 36px;
    ${ pxToRem(36) };
    line-height: ${ pxToRem(54) };
    padding-top: ${ pxToRem(30) };
  }

  @media screen and (max-width: 1100px) {
    p {
      font-size: ${ pxToRem(20) };
      line-height: ${ pxToRem(32) };
    }

    padding: ${ pxToRem(130) } ${ pxToRem(100) } 8rem ${ pxToRem(40) };

    .police {
      width: 302px;
      height: 419px;
    }

    .h1-term {
      font-size: ${ pxToRem(86) };
    }

    .h3-term {
      font-size: 36px;
      ${ pxToRem(30) };
      line-height: ${ pxToRem(48) };
      padding-top: ${ pxToRem(20) };
    }
  }

  @media screen and (max-width: 700px) {
    p {
      font-size: ${ pxToRem(16) };
      line-height: ${ pxToRem(28) };
    }

    .police {
      width: 200px;
      height: 269px;
    }

    .h1-term {
      font-size: ${ pxToRem(36) };
    }

    .description {
      padding-bottom: ${ pxToRem(16) };
    }

    .h3-term {
      font-size: 36px;
      ${ pxToRem(20) };
      line-height: ${ pxToRem(38) };
      padding-top: ${ pxToRem(10) };
    }
  }
`
