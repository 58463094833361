import { memo, useEffect, useState } from "react"
import {
  CarouseImageSC,
  CarouselSC,
  CountdownSC,
  LadderSC,
  LadderSCProps,
  PrizePoolSC,
  PrizePoolTitleSC,
  RibbonSC,
  RibbonTextSC,
  SlideInnerBlockSC,
  SlideInnerSC,
  SlideSC,
} from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import RoundButton from "../RoundButton"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../HexagonButton/styled"
import HexagonButton from "../HexagonButton"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import { Navigation } from "swiper"
import Col from "../ui/Col"

import RibbonBlue from "./ribbon-blue.svg"
import RibbonGrey from "./ribbon-grey.svg"

const Season1 = new URL("./season-1.png?as=webp", import.meta.url).toString()
const SeasonNext = new URL("./season-next.png?as=webp", import.meta.url).toString()

export interface LadderProps extends LadderSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

const title = {
  big: new URL("./title.png?as=webp", import.meta.url).toString(),
  small: new URL("./title.png?as=webp&width=516", import.meta.url).toString(),
}

const merchant = {
  big: new URL("./merchant.png?as=webp", import.meta.url).toString(),
  small: new URL("./merchant.png?as=webp&width=322", import.meta.url).toString(),
}

const dateFuture = 1664582400_000

function Ladder({ className, style, screenWidth }: LadderProps) {
  const [ diff, setDiff ] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })
  useEffect(() => {
    const timer = setInterval(() => {
      const dateNow = Date.now()

      let seconds = Math.floor((dateFuture - dateNow) / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      let days = Math.floor(hours / 24)

      hours = hours - (days * 24)
      minutes = minutes - (days * 24 * 60) - (hours * 60)
      seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60)

      setDiff({ days, hours, minutes, seconds })
    }, 1_000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  if (screenWidth < 1300) {
    return (
      <LadderSC className={ className } style={ style } justify={ "space-between" }>
        <Col className="left-ladder" alignItems={ "center" }>
          <img src={ title.small } srcSet={ `${ title.big } 2x` } alt="ladder" className="title"/>
          <br/>
          <SlideSC>
            <RibbonSC>
              <img src={ RibbonBlue } alt="Ribbon" width="275" height="86"/>
              <RibbonTextSC>Season 2</RibbonTextSC>
            </RibbonSC>
            <SlideInnerSC>
              <img src={ Season1 } alt="season-1" className="season-1"/>
              <SlideInnerBlockSC>
                <PrizePoolSC>
                  <PrizePoolTitleSC>
                    Season Prize Pool
                  </PrizePoolTitleSC>
                  108,666.99 SNK
                </PrizePoolSC>
                <CountdownSC>
                  Starts <span>soon</span>
                </CountdownSC>
              </SlideInnerBlockSC>
            </SlideInnerSC>
          </SlideSC>
          <br/>
          <br/>
          <br/>
          <div className="text">
            <Text>
              Killin’ it in Battle Arena? Want to step your game against other skilled players for the
              mother-of-all-prizes?
              Stock & pile your Special Skins for the main event, the seasonal Ladder.
            </Text>
            <Text>
              Lock N’ Load your Special Skins to the ladder every weekend to compete with the best players in Snook.
              Accumulate points through the season by participating in games and killing other snooks to increase your
              standing in the ladder for a better share in the grand prize.
            </Text>
          </div>
          <a href="https://play.snook.gg/" target="_blank"><RoundButton
            color={ RoundButtonTypeEnum.ORANGE }>C’MON...</RoundButton></a>
          <img src={ merchant.small } srcSet={ `${ merchant.big } 2x` } alt="ladder" className="merchant"/>
        </Col>
      </LadderSC>
    )
  } else {
    return (
      <LadderSC className={ className } style={ style } justify={ "space-between" }>
        <Col className="left-ladder" alignItems={ "center" }>
          <img src={ title.small } srcSet={ `${ title.big } 2x` } alt="ladder" className="title"/>
          <div className="text">
            <Text>
              Killin’ it in Battle Arena? Want to step your game against other skilled players for the
              mother-of-all-prizes?
              Stock & pile your Special Skins for the main event, the seasonal Ladder.
            </Text>
            <Text>
              Lock N’ Load your Special Skins to the ladder every weekend to compete with the best players in Snook.
              Accumulate points through the season by participating in games and killing other snooks to increase your
              standing in the ladder for a better share in the grand prize.
            </Text>
          </div>
          <a href="https://play.snook.gg/" target="_blank"><RoundButton
            color={ RoundButtonTypeEnum.ORANGE }>C’MON...</RoundButton></a>
          <img src={ merchant.small } srcSet={ `${ merchant.big } 2x` } alt="ladder" className="merchant"/>
        </Col>
        <Col className="right-ladder">
          { false && <CarouselSC>
            <div className="navigation">
              <HexagonButton
                className="skin-prev-button"
                type={ HexagonButtonTypeEnum.ORANGE }
                icon={ HexagonButtonIconEnum.TRIANGLE }
                flip={ true }
              />
            </div>
            <Swiper
              modules={ [ Navigation ] }
              spaceBetween={ 0 }
              slidesPerView={ 1 }
              loop={ true }
              navigation={ {
                nextEl: ".skin-next-button",
                prevEl: ".skin-prev-button",
              } }
            >
              <SwiperSlide>
                <CarouseImageSC>
                  <SlideSC>
                    <RibbonSC>
                      <img src={ RibbonBlue } alt="Ribbon" width="275" height="86"/>
                      <RibbonTextSC>Season 1</RibbonTextSC>
                    </RibbonSC>
                    <SlideInnerSC>
                      <img src={ Season1 } alt="season-1" className="season-1"/>
                      <SlideInnerBlockSC>
                        <PrizePoolSC>
                          <PrizePoolTitleSC>
                            Season Prize Pool
                          </PrizePoolTitleSC>
                          9,999,999.09 SNK
                        </PrizePoolSC>
                        <CountdownSC>
                          Ends
                          in: <span>{ diff.days ? diff.days : "" } { diff.days > 1 ? "days" : diff.days === 1 ? "day" : "" } { diff.hours.toString().padStart(2, "0") }:{ diff.minutes.toString().padStart(2, "0") }:{ diff.seconds.toString().padStart(2, "0") }</span>
                        </CountdownSC>
                      </SlideInnerBlockSC>
                    </SlideInnerSC>
                  </SlideSC>
                </CarouseImageSC>
              </SwiperSlide>
              <SwiperSlide>
                <CarouseImageSC>
                  <SlideSC className="next">
                    <RibbonSC>
                      <img src={ RibbonGrey } alt="Ribbon" width="275" height="86"/>
                      <RibbonTextSC>Season 2</RibbonTextSC>
                    </RibbonSC>
                    <SlideInnerSC>
                      <img src={ SeasonNext } alt="season-next" className="season-next"/>
                      <SlideInnerBlockSC>
                        <PrizePoolSC>
                          <PrizePoolTitleSC>
                            Season Prize Pool
                          </PrizePoolTitleSC>
                          ???
                        </PrizePoolSC>
                        <CountdownSC>
                          {/*Starts*/ }
                          {/*in: <span>{ diff.days ? diff.days : "" } { diff.days > 1 ? "days" : diff.days === 1 ? "day" : "" } { diff.hours.toString().padStart(2, "0") }:{ diff.minutes.toString().padStart(2, "0") }:{ diff.seconds.toString().padStart(2, "0") }</span>*/ }
                          &nbsp;
                        </CountdownSC>
                      </SlideInnerBlockSC>
                    </SlideInnerSC>
                  </SlideSC>
                </CarouseImageSC>
              </SwiperSlide>
            </Swiper>
            <div className="navigation">
              <HexagonButton
                className="skin-next-button"
                type={ HexagonButtonTypeEnum.ORANGE }
                icon={ HexagonButtonIconEnum.TRIANGLE }
              />
            </div>
          </CarouselSC> }

          <CarouseImageSC>
            <SlideSC>
              <RibbonSC>
                <img src={ RibbonBlue } alt="Ribbon" width="275" height="86"/>
                <RibbonTextSC>Season 2</RibbonTextSC>
              </RibbonSC>
              <SlideInnerSC>
                <img src={ Season1 } alt="season-1" className="season-1"/>
                <SlideInnerBlockSC>
                  <PrizePoolSC>
                    <PrizePoolTitleSC>
                      Season Prize Pool
                    </PrizePoolTitleSC>
                    108,666.99 SNK
                  </PrizePoolSC>
                  <CountdownSC>
                    Starts <span>soon</span>
                  </CountdownSC>
                </SlideInnerBlockSC>
              </SlideInnerSC>
            </SlideSC>
          </CarouseImageSC>
        </Col>
      </LadderSC>
    )
  }
}

export default memo(Ladder)
