import styled, { keyframes } from "styled-components"
import Row from "../ui/Row"

import pxToRem from "../../utils/pxToRem"

const image = new URL("../../../public/image-dark.png?as=webp&width=1080", import.meta.url).toString()

export interface LadderSCProps {
}

const merchantAnimation = keyframes`
  50% {
    transform: scale(1.05) rotateZ(1deg);
  }
`

export const LadderSC = styled(Row)<LadderSCProps>`
  z-index: 1;

  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: -1;
    background: radial-gradient(50% 50% at 50% 50%, rgba(47, 20, 122, 0.7) 0%, rgba(47, 20, 122, 0) 100%),
    linear-gradient(0deg, rgba(255, 132, 19, 0.2) 0%, rgba(255, 132, 19, 0) 20.96%),
    linear-gradient(270deg, #050720 0%, rgba(5, 7, 32, 0) 50%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${ image }), #1E133D;

    background-blend-mode: normal, normal, normal, normal, darken, multiply, normal;
  }

  border-width: 0 0 ${ pxToRem(4) };
  border-style: solid;
  border-color: #FDC500;
  padding-top: 5rem;
  padding-bottom: 5rem;
  overflow: hidden;

  .left-ladder {
    position: relative;
    width: 50%;
    padding-left: 15%;
    padding-right: 0;

    .title {
      margin-top: -3rem;
    }

    > .text {
      margin-top: -1rem;
      margin-bottom: 3rem;

      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: ${ pxToRem(30) };

      text-align: center;

      color: #FFFFFF;

      padding-left: 10%;
      padding-right: 10%;

      > p {
        margin-bottom: 1rem;
        display: block;
      }
    }
  }

  .merchant {
    position: absolute;
    bottom: -5rem;
    left: 0;

    animation-name: ${ merchantAnimation };
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .ladderTitle {
    width: ${ pxToRem(516) };
    height: ${ pxToRem(236) };
  }

  .right-ladder {
    width: 50%;
    padding: 0 6rem 0 3rem;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;

    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .left-ladder {
      width: 100% !important;
    }

    .right-ladder {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 1500px) {
    .left-ladder {
      width: 40%;
      padding-left: 0;

      .title {
        width: 100%;
        max-width: 350px;
      }
    }

    .right-ladder {
      width: 60%;

      padding: 0 3rem;
    }

    .merchant {
      display: none;
    }
  }
`

export const CarouselSC = styled(Row)`
  .navigation {
    margin-top: 2.5rem;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;

    .skin-prev-button, .skin-next-button {
      flex-shrink: 0;
    }
  }
`

export const SlideInnerSC = styled.div`
  border: ${ pxToRem(2) } solid #CB9BFE;
  border-radius: .5rem;
  overflow: hidden;
  position: relative;

  > img {
    width: 100%;
    transform: scale(1.05);
  }

  @media screen and (max-width: 655px) {
    > img {
      height: 24rem;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
`

export const SlideInnerBlockSC = styled.div`
  position: absolute;

  bottom: 0;
  left: 50%;

  transform: translateX(-50%) translateY(-1rem);

  display: inline-block;
  white-space: nowrap;

  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 98%;
  text-align: center;
  letter-spacing: -0.05em;

  width: 60%;
`

export const PrizePoolSC = styled.div`
  background-color: #1E133D;
  border: ${ pxToRem(4) } solid #FFCD00;
  border-radius: .5rem;

  font-size: 2rem;
  text-align: center;

  padding: 4rem 1rem;

  @media screen and (max-width: 1500px) {
    padding: 3rem 1rem;
    font-size: 1.5rem;
    line-height: .5rem;
  }

  @media screen and (max-width: 1300px) {
    padding: 3rem 1rem;
    font-size: 1.5rem;
    line-height: .5rem;
  }
`

export const PrizePoolTitleSC = styled.div`
  position: absolute;
  left: 50%;
  top: -3rem;

  transform: translateX(-50%) translateY(50%);

  background: linear-gradient(180deg, #FEFF3B 0%, rgba(254, 255, 59, 0) 100%), #FFA900;
  box-shadow: 0 0 ${ pxToRem(2) } 0 #FFFFFF inset;
  border-radius: ${ pxToRem(8) };
  font-size: 2rem;
  padding: 1rem;
  color: #DC5012;
`

export const CountdownSC = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 98%;

  text-align: center;
  letter-spacing: -0.05em;

  margin-top: 1rem;

  color: #FFFFFF;

  > span {
    color: #FFFF99;
    text-shadow: 0 0 ${ pxToRem(12) } #FF6666;
  }
`

export const RibbonImageSC = styled.img`

`

export const RibbonTextSC = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-65%);
  width: 100%;

  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 98%;

  text-align: center;
  letter-spacing: -0.05em;
  text-transform: uppercase;

  color: #FFFFFF;
`

export const RibbonSC = styled.div`
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  z-index: 2;
`

export const CarouseImageSC = styled.div`
  img {
    position: relative;
  }
`

export const SlideSC = styled.div`
  position: relative;
  box-sizing: border-box;

  margin-top: ${ pxToRem(43) };

  @media screen and (max-width: 1300px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media screen and (max-width: 655px) {
    width: 100%;
  }

  &.next {
    width: 100%;

    background: #315577;
    border: ${ pxToRem(2) } solid #C1D6E8;

    ${ SlideInnerSC } {
      border: ${ pxToRem(2) } solid #C1D6E8;
    }

    ${ PrizePoolSC } {
      background-color: #1E133D;
      border: ${ pxToRem(4) } solid #7FA3C5;
    }

    ${ PrizePoolTitleSC } {
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #3C546C;
      background-blend-mode: overlay, normal;
      box-shadow: 0 0 ${ pxToRem(2) } 0 #FFFFFF inset;
      color: #FFFFFF;
    }
  }

  background-color: #510D9B;
  border: ${ pxToRem(2) } solid #CB9BFE;
  border-radius: 1rem;

  padding: 1rem;
`
