import React, { memo } from "react"
import { CSSProperties } from "styled-components"

import { TokenomicsSC, TokenomicsSCProps } from "./styled"

import Principle from "../../components/Tokenomics/Principle"
import Inflow from "../../components/Tokenomics/Inflow"
import Outflow from "../../components/Tokenomics/Outflow"
import Trait from "../../components/Tokenomics/Trait"
import Supply from "../../components/Tokenomics/Supply"
import Integrated from "../../components/Tokenomics/Integrated"


export interface TokenomicsProps extends TokenomicsSCProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

function Tokenomics({ style, screenWidth }: TokenomicsProps) {
  return (
    <TokenomicsSC style={ style }>
      <Principle/>
      <Inflow screenWidth={ screenWidth }/>
      <Outflow screenWidth={ screenWidth }/>
      <Trait/>
      <Supply screenWidth={ screenWidth }/>
      <Integrated screenWidth={ screenWidth }/>
    </TokenomicsSC>
  )
}

export default memo(Tokenomics)
