import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../../ui/Col"

const bgHex = new URL("../../../../public/bg-hex.png?as=webp&width=1080", import.meta.url).toString()

export interface BBTIsItRightSCProps {
}

export const BBTIsItRightSC = styled(Col)<BBTIsItRightSCProps>`
  position: relative;
  z-index: 1;
  background-color: #3284FF;
  border-bottom: 8px solid #FDC500;

  width: 100%;

  .is-right-wrapper {
    padding: ${ pxToRem(60) } 0;

    width: ${ pxToRem(1440) };

    position: relative;
    z-index: 6;

    p {
      text-align: left;
    }
  }

  .coins {
    width: 100%;
    overflow: hidden;
    height: 373px;

    position: absolute;
    bottom: 0;
  }

  .is-right-text-block {
    width: 100%;
  }

  .is-right-h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: 72px;
    line-height: 98%;
    letter-spacing: -0.05em;
  }

  .is-right-desc {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .is-right-btn {
    width: ${ pxToRem(325) };
    margin-top: ${ pxToRem(21) };
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 6, 157, 0) 0%, rgba(0, 6, 157, 0.3) 100%), url(${ bgHex }), #3284FF;
    background-blend-mode: normal, normal, luminosity, normal;
  }

  .coins-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 373px;
  }

  .coin2-img {
    position: absolute;
    bottom: 0;
    right: 373px;
    width: 373px;
  }

  .coin1-img {
    position: absolute;
    bottom: 0;
    right: 746px;
    width: 373px;
  }

  .is-right-for-mobile {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1600px) {
    .is-right-wrapper {
      width: 90vw;
    }
  }

  @media screen and (max-width: 1400px) {
    .is-right-wrapper {
      flex-direction: column;
    }

    .is-right-for-mobile {
      height: 100px;
    }
  }

  @media screen and (max-width: 1200px) {
    .is-right-for-mobile {
      height: 110px;
    }
  }

  @media screen and (max-width: 1100px) {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    margin: 2rem 2rem 0;

    .is-right-wrapper {
      padding: 2rem 2rem 0;
    }

    .coins {
      border-radius: 10px;
    }

    .coin1-img {
      display: none;
    }
  }

  @media screen and (max-width: 900px) {
    .is-right-h2 {
      font-size: 36px;
    }

    .is-right-btn {
      width: ${ pxToRem(260) };
      margin-top: ${ pxToRem(12) };
    }
  }

  @media screen and (max-width: 700px) {
    .coins-img {
      width: 273px;
    }

    .coin2-img {
      right: 273px;
      width: 273px;
    }
  }

  @media screen and (max-width: 500px) {
    .is-right-for-mobile {
      height: 170px;
    }

    .coins-img {
      width: 230px;
    }

    .coin2-img {
      right: 230px;
      width: 230px;
    }
  }

  .themed-bottom-blocks {
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    bottom: 360px;
    right: 240px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .1;
      z-index: -1;
      background: radial-gradient(69.17% 69.17% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(${ bgHex }), #00122C;
      background-blend-mode: normal, normal, luminosity, normal;
    }

    .rocket-img {
      position: absolute;
      top: -260px;
      width: 840px;
      right: -30px;
      z-index: 5;
    }

    @media screen and (max-width: 1400px) {
      bottom: 480px;
      .rocket-img {
        top: -140px;
      }
    }
    @media screen and (max-width: 1200px) {
      .rocket-img {
        top: -40px;
      }
    }
    @media screen and (max-width: 1100px) {
      bottom: 580px;

      right: 15px;
      .rocket-img {
        top: 20px;
        width: 740px;
      }
    }

    @media screen and (max-width: 900px) {
      bottom: 480px;

      .rocket-img {
        top: 40px;
        width: 640px;
      }
    }

    @media screen and (max-width: 700px) {
      bottom: 480px;
      .rocket-img {
        top: 60px;
        width: 95vw;
      }
    }

    @media screen and (max-width: 600px) {
      bottom: 420px;
      .rocket-img {
        top: 100px;
      }
    }

    @media screen and (max-width: 500px) {
      bottom: 360px;
      .rocket-img {
        top: 100px;
      }
    }
  }
`
