import styled, { keyframes } from "styled-components"

export type Location = {
  x?: number
  y?: number
  r?: number
}

export type Figure = {
  from?: Location,
  to?: Location,
  scale?: number
  delay?: number
}

export type Props = {
  blue: Figure
  pink: Figure
}

export const RelativeContainer = styled.div`
  position: relative;
  z-index: 1;
`

const Image = styled.div<Figure>`
  position: absolute;

  top: 0;
  left: 0;

  z-index: 2;

  mix-blend-mode: screen;

  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`

const animate = (props: Figure) => keyframes`
  from, to {
    transform: translateX(${ props?.from?.x ?? 0 }px) translateY(${ props?.from?.y ?? 0 }px) rotateZ(${ props?.from?.r ?? 0 }deg) scale(${ props?.scale ?? 1 });
  }
  50% {
    transform: translateX(${ props?.to?.x ?? 0 }px) translateY(${ props?.to?.y ?? 0 }px) rotateZ(${ props?.to?.r ?? 0 }deg) scale(${ props?.scale ?? 1 });
  }
`

export const Blue = styled(Image)`
  animation-name: ${ (props: Figure) => animate(props) };
  animation-delay: -${ (props: Figure) => props.delay ?? 0 }s;
`

export const Pink = styled(Image)`
  animation-name: ${ (props: Figure) => animate(props) };
  animation-delay: -${ (props: Figure) => props.delay ?? 0 }s;
`
