import React, { CSSProperties, memo, useCallback, useState } from "react"
import { OutflowSC } from "./styled"
import Text from "../../ui/Text"
import Row from "../../ui/Row"
import Col from "../../ui/Col"
import ScaleOut from "../../ScaleOut"

import outflow from "./outflow.svg"
import outflowMob from "./outflowMob.svg"

export interface OutflowProps {
  className?: string
  style?: CSSProperties
  screenWidth?: any
}

function Outflow({ className, style, screenWidth }: OutflowProps) {
  const [ isOpen, setOpen ] = useState<boolean>(false)
  const open = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])

  let outflowImg = screenWidth > 900 ? outflow : outflowMob

  return (
    <OutflowSC className={ className } style={ style } alignItems="center" gap={ screenWidth > 900 ? 60 : 40 }>
      <Col>
        <Text className="outflow-h1">SNK Outflow</Text>
        <Text className="outflow-description">An additional relevant mechanism is the burning of SNK. <br/>
          Whenever a new snook is minted 20% of the SNK inflow is burned. </Text>
      </Col>
      <Col gap={ 16 } className="outflow-img">
        <img src={ outflowImg } alt="outflow" onClick={ open }/>
        <ScaleOut
          title="SNK Outflow"
          img={ outflow }
          isOpen={ isOpen }
          onClose={ close }
        />
        { screenWidth < 901 && <Text className="click-text">Click for expanded preview</Text> }
      </Col>

      <Row className="outflow-text">
        <Col>
          <Text>Outflow of SNK is predominantly realized through:</Text>
          <Text><b>1. PPK Rewards</b><span
            className="colored-title"> (60% of minting and 79% of Resurrection/Fusion).</span></Text>
          <Text><b>2. Snook Ladder Prize pool</b><span className="colored-title"> (12% of minting and 15.8% of Resurrection/Fusion).</span></Text>
        </Col>
        <Col>
          <Text>There are two additional cash outflow channels:</Text>
          <Text><b>3. Ecosystem Fund</b><span className="colored-title"> (4% of minting).</span></Text>
          <Text><b>4. Staking rewards</b><span
            className="colored-title"> (4% of minting and 5.2% of Resurrection/Fusion).</span></Text>
        </Col>
      </Row>
    </OutflowSC>
  )
}

export default memo(Outflow)
