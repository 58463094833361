import styled, { keyframes } from "styled-components"
import arrow from "./images/arrow.svg"

export interface AnimatedArrowPointingSCProps {
  amount: number
  speed: number
  index?: number
}

const animate = keyframes`
  0%, 50%, 100% {
    transform: scale(.25);
    opacity: .25;
  }

  37.5% {
    transform: scale(.5);
    opacity: .5;
  }

  25% {
    transform: scale(.75);
    opacity: .75;
  }

  12.5% {
    transform: scale(1);
    opacity: 1;
  }
`

export const AnimatedArrowPointingSC = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

export const ArrowSC = styled.div<AnimatedArrowPointingSCProps>`
  width: 13px;
  height: 23px;
  background-image: url(${ arrow });
  background-size: contain;
  transform: scale(.1);
  opacity: .25;
  background-position: center center;

  animation-name: ${ animate };
  animation-iteration-count: infinite;
  animation-duration: ${ (props) => props.speed }s;
  animation-timing-function: ease-in-out;

  animation-delay: ${ (props) => (props.index! - props.amount) * (props.speed / props.amount) }s;
`
