import styled from "styled-components"
import Row from "../ui/Row"
import pxToRem from "../../utils/pxToRem"

export interface TotalsSCProps {
}

export const TotalsSC = styled(Row)<TotalsSCProps>`
  position: relative;
  z-index: 2;
  
  .btn {
    margin-top: ${ pxToRem(30) };
  }

  @media screen and (max-width: 1100px) {
    //padding-top: 32rem;
    .totals-block {
      flex-direction: column;
      justify-items: center;
    }
  }

  @media screen and (max-width: 700px) {
    //padding-top: 18rem;
  }


`
