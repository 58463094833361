import { memo } from "react"
import { DiveCardSC, DiveCardSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import HexagonButton from "../HexagonButton"
import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../HexagonButton/styled"
import Row from "../ui/Row"
import Col from "../ui/Col"
import { HashLink as Link } from "react-router-hash-link"

export interface DiveCardProps extends DiveCardSCProps {
  className?: string
  style?: CSSProperties
  title?: string
  subtitle?: string
  url?: string
  link?: string
  screenWidth: number
}

function DiveCard({ className, style, title, subtitle, screenWidth, url, link }: DiveCardProps) {
  const button = (
    <HexagonButton
      icon={ HexagonButtonIconEnum.ARROW }
      type={ HexagonButtonTypeEnum.ORANGE }
      scale={ screenWidth > 700 ? 1.45 : 1.1 }
    />
  )

  return (
    <DiveCardSC className={ className } style={ style } gap={ 12 } justify="center">
      <Row gap={ 10 } justify="space-between" className="relative">
        <Col gap={ 10 } align="start">
          <Text className="dive-card-text">{ title }</Text>
          { subtitle && <Text className="mob-description">{ subtitle }</Text> }
        </Col>
        { link ? <Link to={ link }>{ button }</Link> : url ? <a href={ url } target="_blank">{ button }</a> : button }
        { subtitle && <Text className="title-description-middle absolute-description">{ subtitle }</Text> }
      </Row>
    </DiveCardSC>
  )
}

export default memo(DiveCard)
