import styled from "styled-components"
import Col from "../ui/Col"
import Row from "../ui/Row"
import pxToRem from "../../utils/pxToRem";

export interface TotalElementSCProps {
}

export const ContainerSC = styled.div`
  position: relative;
  width: 100%;
`

export const TitleSC = styled(Row)`
  cursor: default;
  
  width: 80%;
  height: ${pxToRem(48)};
  background: #FFFFFF;
  border: ${pxToRem(2)} solid #FDC500;
  box-shadow: 0 ${pxToRem(8)} ${pxToRem(16)} rgba(0, 0, 0, 0.16);
  border-radius: ${pxToRem(16)};
  position: absolute;
  top: -${pxToRem(20)};
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  .capture {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: ${pxToRem(22)};
    line-height: ${pxToRem(33)};
    color: #245FB7;
  }

  @media screen and (max-width: 700px) {
    height: ${pxToRem(32)};
    border-radius: ${pxToRem(10.5714)};
    border: ${pxToRem(1.32143)} solid #FDC500;
    box-shadow: 0 ${pxToRem(5.28571)} ${pxToRem(10.5714)} rgba(0, 0, 0, 0.16);
    .capture {
      font-size: ${pxToRem(15)};
      line-height: ${pxToRem(22.5)};
    }
  }
`

export const TotalElementSC = styled(Col)<TotalElementSCProps>`
  background: #3284FF;
  box-shadow: 0 ${pxToRem(8)} ${pxToRem(16)} rgba(0, 0, 0, 0.16);
  border-radius: ${pxToRem(16)};
  position: relative;
  width: 100%;
  overflow: hidden;

  padding: 2rem;

  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: #0066FF;

    .numbers {
      color: #FFF0A4;
    }
  }

  .wrapper {
    position: relative;
  }

  .bg-img {
    position: absolute;
    left: -${pxToRem(5)};
    bottom: -${pxToRem(5)};
    offset: none;
  }

  .numbers {
    cursor: default;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${pxToRem(64)};
    line-height: ${pxToRem(80)};
    letter-spacing: -0.05em;

    transition: color .3s ease-in-out;
  }

  @media screen and (max-width: 700px) {
    padding: ${pxToRem(24)} 0 ${pxToRem(23)} 0;
    
    .numbers {
      font-size: ${pxToRem(42)};
      line-height: ${pxToRem(53)};
    }
    
    .bg-img {
      width: 62.38px;
      height: 77.91px;
      left: -${pxToRem(2)};
      bottom: -${pxToRem(2)};
    }
  }
`
