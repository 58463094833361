import { memo } from "react"
import { AnimatedArrowRoundSC, AnimatedArrowRoundSCProps, DotSC } from "./styled"
import { CSSProperties } from "styled-components"
import dot from "./images/earn-dot.svg"
import arrow from "./images/earn-arrow.svg"

export interface AnimatedArrowRoundProps extends AnimatedArrowRoundSCProps {
  className?: string
  style?: CSSProperties
}

function AnimatedArrowRound({ className, style, delay, reverse }: AnimatedArrowRoundProps) {
  const radius = reverse ? -11 : 11
  const number = 12

  const gap = 15

  const angle = (180 - gap * 2) / number
  return (
    <AnimatedArrowRoundSC className={ className } style={ style } delay={ delay } r={ radius } reverse={ reverse }>
      <div className="animated-arrow-container">
        <img src={ arrow } alt="arrow" className="earn-arrow"/>
        { new Array(number).fill(null).map((_, i) => (
          <DotSC
            key={ i }
            x={ radius * Math.sin(Math.PI * 2 * (gap + (angle * i)) / 360) }
            y={ radius * Math.cos(Math.PI * 2 * (gap + (angle * i)) / 360) }
            r={ radius }
          >
            <img src={ dot } alt="dot"/>
          </DotSC>
        )) }
      </div>
    </AnimatedArrowRoundSC>
  )
}

export default memo(AnimatedArrowRound)
