import React, { memo } from "react"
import { PrincipleMobileSC, PrincipleMobileSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../../../ui/Text"
import Col from "../../../ui/Col"
import RoundButton from "../../../RoundButton"
import { RoundButtonTypeEnum } from "../../../RoundButton/styled"

const nerd = new URL("../nerd.png?as=webp", import.meta.url).toString()

export interface PrincipleMobileProps extends PrincipleMobileSCProps {
  className?: string
  style?: CSSProperties
}

function PrincipleMobile({ style }: PrincipleMobileProps) {
  return (
    <PrincipleMobileSC style={ style } justify="center" gap={ 24 }>
      <Text className="tokenomics-title-mobile">
        Tokenomics
      </Text>
      <Text className="tokenomics-description-mobile">This page provides an overview of the key elements and mechanics
        of the Snook economy.
        A more complete description, review and analysis of the economy can be found in the whitepaper.</Text>
      <a href="https://whitepaper.playsnook.com/economy-overview/snk-cash-flow-analysis" target="_blank">
        <RoundButton color={ RoundButtonTypeEnum.ORANGE } className="tokenomics-btn">WHITEPAPER</RoundButton>
      </a>
      <div className="snake-mobile-wrap"><img src={ nerd } alt="snake" className="snake-mobile"/></div>
      <Col className="principle-mobile" gap={ 24 }>
        <Text className="principle-mobile-title">
          Our general approach follows <br/>
          this PrincipleMobile:
        </Text>
        <Text className="principle-mobile-description">
          The only important economic engine and true and sustainable source
          of value is the effort invested through purposeful action. In the Snook verse it is
          playing Snook. Snook uses two tokens, the <b>ERC20</b> SNK and the <b>ERC721 snook-NFT</b>.
          Playing Snook directly stores value in the snook NFT and indirectly in SNK.
        </Text>
        <div>
          <Text>
            Since Traits represent the most basic unit of underlying value of the
            snook-NFT, an apt description of the Snook economy must apply a double prism:
          </Text>
          <ul>
            <li><b>SNK cashflow</b></li>
            <li><b>The Trait Economy</b> (based on supply-demand balance)</li>
          </ul>
          <Text> Capisce?</Text>
        </div>
      </Col>
    </PrincipleMobileSC>
  )
}

export default memo(PrincipleMobile)
