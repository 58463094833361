import styled, { keyframes } from "styled-components"
import Col from "../ui/Col"
import Row from "../ui/Row"
import pxToRem from "../../utils/pxToRem"

export interface SpecialSkinsSCProps {
}

export const SpecialSkinsSC = styled(Col)<SpecialSkinsSCProps>`
  align-items: center;
  padding-top: 4rem;
  margin-top: -${ pxToRem(1) };
`

export const SmallWrapperSC = styled(Col)`
  padding: 0 !important;
`

const swingLeft = keyframes`
  0% {
    margin-left: 2rem;
  }
  100% {
    margin-left: 0
  }
`

const swingRight = keyframes`
  0% {
    margin-left: -2rem;
  }
  100% {
    margin-left: 0;
  }
`

export const CarouselSC = styled(Row)`
  margin-top: 2rem;

  @media screen and (max-width: 1300px) {
    display: none;
  }

  .navigation {
    margin-top: 2.5rem;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;

    .skin-prev-button, .skin-next-button {
      flex-shrink: 0;
    }
  }

  .swing-start-left {
    margin-left: 2rem;
  }

  .swing-start-right {
    margin-left: -2rem;
  }

  .swing-left {
    animation-name: ${ swingLeft };
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }

  .swing-right {
    animation-name: ${ swingRight };
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
  }
`

export const CarouseImageSC = styled.div`
  display: inline-block;

  img {
    width: 100%;
    position: relative;

    transition: transform .5s ease-in-out;
  }

  .hover {
    margin-top: 2.5rem;
    position: relative;
    border: ${ pxToRem(2) } solid transparent;
    border-radius: ${ pxToRem(16) };

    cursor: pointer;

    padding: .5rem;

    .text {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.21em;
      width: 100%;

      position: absolute;
      color: transparent;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);

      opacity: 0;

      transition: opacity .5s ease-in-out;
    }

    transition: border-color .3s ease-in-out, background-color .3s ease-in-out, color .3s ease-in-out;

    &.active {
      background-color: #276CD4;
      border: ${ pxToRem(2) } solid #2F7CF2;

      img {
        transform: translateY(-2.5rem);
      }

      .text {
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }
`

export const CarouselMobileSC = styled.div`
  overflow: auto visible;
  flex-direction: row;
  width: 100vw;

  display: none;
  @media screen and (max-width: 1300px) {
    display: block;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    display: flex;
    gap: 1rem;

    padding: 0 1rem;
  }

  ${ CarouseImageSC } {
    display: inline-block;
    flex: 1 0;

    img {
      width: 250px;
    }

    &:last-child {
      padding-right: 1.5rem;
    }
  }
`