import React, { memo, useCallback, useEffect, useState } from "react"
import { CSSProperties } from "styled-components"
import { Route, Routes } from "react-router"
import { BrowserRouter as Router } from "react-router-dom"

import { LayoutSC, LayoutSCProps } from "./styled"
import Header from "../Header"
import Footer from "../Footer"
import Navigation from "../Navigation"
import Main from "../../pages/Main"


import TermsAndConditions from "../../pages/TermsAndConditions"
import PrivacyPolicy from "../../pages/PrivacyPolicy"
import Tokenomics from "../../pages/Tokenomics"
import ThemedRooms from "../../pages/ThemedRooms";

export interface LayoutProps extends LayoutSCProps {
  className?: string
  style?: CSSProperties
}

function Layout({ className, style }: LayoutProps) {
  const [ nav, setNav ] = useState(false)

  const [ dimensions, setDimensions ] = React.useState({
    width: window.innerWidth,
  })

  const toggleNav = useCallback(() => setNav(nav => !nav), [])

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      })
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  let screenWidth = dimensions.width

  return (
    <LayoutSC className={ className } style={ style }>
      <Router>
        <div id="home"/>
        <Navigation show={ nav } toggleNav={ toggleNav } screenWidth={ screenWidth }/>
        <Header screenWidth={ screenWidth } toggleNav={ toggleNav }/>
        <Routes>
          <Route path="/terms" element={ <TermsAndConditions/> }/>
          <Route path="/privacy" element={ <PrivacyPolicy/> }/>
          <Route path="/tokenomics" element={ <Tokenomics screenWidth={ screenWidth }/> }/>
          <Route path="/themed-rooms" element={ <ThemedRooms screenWidth={ screenWidth }/> }/>
          <Route path="/*" element={ <Main screenWidth={ screenWidth }/> }/>
        </Routes>
        <Footer screenWidth={ screenWidth }/>
      </Router>
    </LayoutSC>
  )
}

export default memo(Layout)
