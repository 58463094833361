import styled from "styled-components"
import pxToRem from "../../../utils/pxToRem"
import Col from "../../ui/Col"

export interface IntegratedSCProps {
}

export const IntegratedSC = styled(Col)<IntegratedSCProps>`
  background: #00112C;
  border-top: ${ pxToRem(8) } solid #FDC500;
  padding: ${ pxToRem(100) } 0;
  position: relative;

  .integrated-h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(72) };
    line-height: 98%;
    letter-spacing: -0.05em;
  }

  .integrated-img {
    width: 1468px;
  }

  @media screen and (max-width: 1600px) {
    .integrated-img {
      width: 90%;
    }
  }

  @media screen and (max-width: 1100px) {
    gap: ${ pxToRem(40) };
    padding: ${ pxToRem(60) } 0;
    .integrated-h1 {
      font-size: ${ pxToRem(36) };
    }
  }
`
