import { memo } from "react"
import {
  BiteCardSC,
  BlockSC,
  ButtonSC,
  CardWrapperSC,
  EarnCardSC,
  LeftBlockSC,
  MiddleBlockSC,
  PlayingSnookSC,
  PlayingSnookSCProps,
  RightBlockSC, ScrollSC,
  ShineSC,
  SmallWrapperSC,
  WitchCardSC,
} from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import Row from "../ui/Row"
import Col from "../ui/Col"
import { RoundButtonTypeEnum } from "../RoundButton/styled"

import skull1 from "./skull1.svg"
import skull2 from "./skull2.svg"
import skull3 from "./skull3.svg"
import triangle from "./triangle.svg"

import RoundButton from "../RoundButton"
import AnimatedArrowPointing from "../AnimatedArrowPointing"

export interface PlayingSnookProps extends PlayingSnookSCProps {
  className?: string
  style?: CSSProperties
}

const witch = {
  small: new URL("./witch.png?as=webp&width=278", import.meta.url).toString(),
  big: new URL("./witch.png?as=webp&width=556", import.meta.url).toString(),
}

const bite = {
  small: new URL("./bite.png?as=webp&width=260", import.meta.url).toString(),
  big: new URL("./bite.png?as=webp&width=520", import.meta.url).toString(),
}

const earn = {
  small: new URL("./earn.png?as=webp&width=336", import.meta.url).toString(),
  big: new URL("./earn.png?as=webp&width=672", import.meta.url).toString(),
}

const snookCard1 = {
  big: new URL("./snookcard1.png?as=webp", import.meta.url).toString(),
}

const snookCard2 = {
  big: new URL("./snookcard2.png?as=webp", import.meta.url).toString(),
}

function PlayingSnook({ className, style }: PlayingSnookProps) {
  return (
    <PlayingSnookSC className={ className } style={ style }>
      <Col className="playing-title">
        <div id="play"/>
        <Text className="h1"><span className="colored-title">Playing Snook is</span> Easy!</Text>
        <Text className="title-description-middle-bold">But will you be the next Snook God?</Text>
      </Col>
      <ScrollSC>
        <SmallWrapperSC className="small-wrapper" justify="center" alignItems="start">
          <Col gap={ 12 } style={ { flexGrow: 1, width: "100%" } }>
            <CardWrapperSC>
              <WitchCardSC>
                <img src={ witch.small } srcSet={ `${ witch.big } 2x` } alt="witch" className="witch"/>
                <img src={ snookCard2.big } width="160" alt="snookCard2" className="snookCard2"/>
                <img src={ snookCard1.big } width="138" alt="snookCard1" className="snookCard1"/>
              </WitchCardSC>
            </CardWrapperSC>
            <BlockSC>
              <LeftBlockSC>
                <div className="left-stock">
                  <div className="text">
                    <AnimatedArrowPointing speed={ 1 } amount={ 8 }/>
                    <Text className="h3">Stock-up</Text>
                    <Text>
                      Hit the <button>PLAY SNOOK <img src={ triangle } alt="triangle"/></button> button, stock-up <br/>
                      with a few freshly minted snooks, and <br/>
                      drop into the map.
                    </Text>
                  </div>
                  <ShineSC/>
                </div>
                <div className="right-stock">
                  <div className="active active-1">
                    <div/>
                  </div>
                </div>
              </LeftBlockSC>
            </BlockSC>
            <Row>
              <div className="left-part">
                <Text weight={ 500 } size={ 16 } lineHeight={ 24 } color="#3284FF">
                  we’re trying to get you to play...<br/>
                  So, we made it stupid easy
                </Text>
              </div>
            </Row>
          </Col>
          <Col gap={ 12 } style={ { flexGrow: 1, width: "100%" } }>
            <CardWrapperSC>
              <BiteCardSC>
                <img src={ bite.small } srcSet={ `${ bite.big } 2x` } alt="bite" className="bite"/>
                <div className="skulls">
                  <img src={ skull1 } alt="skull" className="skull1"/>
                  <img src={ skull2 } alt="skull" className="skull2"/>
                  <img src={ skull3 } alt="skull" className="skull3"/>
                </div>
              </BiteCardSC>
            </CardWrapperSC>
            <BlockSC>
              <MiddleBlockSC>
                <div className="left-stock">
                  <div className="text">
                    <AnimatedArrowPointing speed={ 1 } amount={ 8 }/>
                    <Text className="h3">Eat, Play, Earn</Text>
                    <Text>
                      You’re spawned into battle! Eat orbs,<br/> avoid snooks, kill and earn <b>SNK</b>,<br/> collect
                      Traits.
                    </Text>
                  </div>
                  <ShineSC/>
                </div>
                <div className="right-stock">
                  <div className="active active-2">
                    <div/>
                  </div>
                </div>
              </MiddleBlockSC>
            </BlockSC>
            <Row>
              <div className="left-part">
                <Text weight={ 500 } size={ 16 } lineHeight={ 24 } color="#3284FF">
                  “…the fiery crucible in which true<br/>
                  heroes are forged.”
                </Text>
              </div>
            </Row>
          </Col>
          <Col gap={ 12 } style={ { flexGrow: 1, width: "100%" } }>
            <CardWrapperSC>
              <EarnCardSC>
                <img src={ earn.small } srcSet={ `${ earn.big } 2x` } alt="earn" className="earn"/>
              </EarnCardSC>
            </CardWrapperSC>
            <BlockSC>
              <RightBlockSC>
                <div className="left-stock">
                  <div className="text">
                    <AnimatedArrowPointing speed={ 1 } amount={ 8 }/>
                    <Text className="h3">Cash Out & Repeat</Text>
                    <Text>
                      Every kill earns you cash <b>SNK.</b> Every Trait<br/> you collect brings you closer to
                      unlocking<br/> a
                      rare Special Skin.
                    </Text>
                  </div>
                  <ShineSC/>
                </div>
                <div className="right-stock">
                  <div className="active active-3">
                    <div/>
                  </div>
                </div>
              </RightBlockSC>
            </BlockSC>
            <Row>
              <div className="left-part">
                <Text weight={ 500 } size={ 16 } lineHeight={ 24 } color="#3284FF">
                  NFTs are hot now…
                </Text>
              </div>
            </Row>
          </Col>
        </SmallWrapperSC>
      </ScrollSC>
      <ButtonSC>
        <a href='https://play.snook.gg/' target="_blank"><RoundButton color={ RoundButtonTypeEnum.ORANGE }>PLAY SNOOK</RoundButton></a>
      </ButtonSC>
    </PlayingSnookSC>
  )
}

export default memo(PlayingSnook)
