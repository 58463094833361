import { memo } from "react"
import { BurgerSC, BurgerSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import { HexagonButtonIconEnum, HexagonButtonTypeEnum } from "../../HexagonButton/styled"
import HexagonButton from "../../HexagonButton"

export interface BurgerProps extends BurgerSCProps {
  className?: string
  style?: CSSProperties
  scale?: number
  onClick: () => void
}

function Burger({ className, style, scale, onClick }: BurgerProps) {
  return (
    <BurgerSC className={ className } style={ style } onClick={ onClick }>
      <HexagonButton
        icon={ HexagonButtonIconEnum.BURGER }
        type={ HexagonButtonTypeEnum.ALT_BLUE }
        scale={ scale }
      />
    </BurgerSC>
  )
}

export default memo(Burger)
