import styled from "styled-components"
import pxToRem from "../../utils/pxToRem"

const image = new URL("../../../public/image.png?as=webp&width=1080", import.meta.url).toString()

export interface GameModesCardSCProps {
}

export const GameModesCardSC = styled.div<GameModesCardSCProps>`
  background-color: #3284FF;
  border-bottom: ${ pxToRem(16) } solid #FDC500;
  border-radius: ${ pxToRem(16) };
  position: relative;
  padding: 0 ${ pxToRem(34) } ${ pxToRem(34) };

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(0, 6, 157, 0) 0%, rgba(0, 6, 157, 0.5) 100%), url(${ image }), #3284FF;
    background-blend-mode: normal, luminosity, normal;
    opacity: 0.06;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-size: 160%;
    z-index: 1;
  }

  cursor: default;
  transition: background .3s ease-in-out;

  .title {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(56) };
    line-height: 98%;
    letter-spacing: -0.05em;
    color: #FDC500;

    transition: color .3s ease-in-out;
  }

  .description {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: ${ pxToRem(16) };
    line-height: ${ pxToRem(24) };
    color: #FFFFFF;

    transition: color .3s ease-in-out;
  }

  &:hover {
    background: #FFFFFF;

    &::after {
      content: "";
      background: linear-gradient(180deg, rgba(253, 197, 0, 0.5) 0%, rgba(253, 197, 0, 0) 49.88%), url(${ image }), #FFFFFF;
      background-blend-mode: normal, luminosity, normal;
      opacity: 0.06;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      background-size: 160%;
      z-index: 1;
    }

    .title {
      color: #245FB7;
    }

    .description {
      color: #245FB7;
    }
  }

  .img-title {
    position: relative;
    transform: translateY(-50%);
    margin-bottom: -25%;
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    .img-title {
      max-width: ${ pxToRem(350) };
    }

    .title {
      font-size: ${ pxToRem(36) };
    }

    .description {
      font-weight: 500;
    }
  }
`
