import styled from "styled-components"

import * as blue from "./images/blue"
import * as orange from "./images/orange"
import * as altBlue from "./images/alt-blue"
import * as icons from "./images"

const colors = { blue, orange, "alt-blue": altBlue }

export enum HexagonButtonIconEnum {
  ARROW = "arrow",
  BURGER = "burger",
  DISCORD = "discord",
  TELEGRAM = "telegram",
  TRIANGLE = "triangle",
  TWITTER = "twitter",
  CROSS = "cross"
}

export enum HexagonButtonTypeEnum {
  BLUE = "blue",
  ORANGE = "orange",
  ALT_BLUE = "alt-blue",
}

export interface HexagonButtonSCProps {
  icon: HexagonButtonIconEnum
  type: HexagonButtonTypeEnum
  scale: number
  flip?: boolean
}

export const HexagonButtonSC = styled.div<HexagonButtonSCProps>`
  width: 56px;
  height: 56px;

  cursor: pointer;

  position: relative;
  transform: scale(${ props => props.scale });

  div {
    width: 100%;
    height: 100%;
    position: absolute;

    transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
  }

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }

  .icon {
    background-color: ${ props => props.type === HexagonButtonTypeEnum.BLUE ? "#2373DE" : props.type === HexagonButtonTypeEnum.ORANGE ? "#FFF0A4" : "#FFFFFF" };
    mask: url(${ props => icons[props.icon] }) no-repeat ${ props => [ HexagonButtonIconEnum.TELEGRAM ].includes(props.icon) ? 45 : [ HexagonButtonIconEnum.TRIANGLE ].includes(props.icon) ? 54 : 50 }% 50%;
    transform: scale(${ props => (props.flip ? -1 : 1) * ([ HexagonButtonIconEnum.ARROW, HexagonButtonIconEnum.BURGER ].includes(props.icon) ? .6 : .9) }) ${ props => props.icon === HexagonButtonIconEnum.CROSS ? "translate(0.5px, 0.5px)" : "" };
  }

  &:hover {
    .hover {
      opacity: 1;
    }

    .active {
      opacity: 0;
    }

    .icon {
      background-color: ${ props => props.type === HexagonButtonTypeEnum.BLUE ? "#1361CA" : props.type === HexagonButtonTypeEnum.ORANGE ? "#FFFFFF" : "#FFFFFF" };
    }
  }

  &:active {
    .hover {
      opacity: 0;
    }

    .active {
      opacity: 1;
    }

    .icon {
      background-color: ${ props => props.type === HexagonButtonTypeEnum.BLUE ? "#2373DE" : props.type === HexagonButtonTypeEnum.ORANGE ? "#FFF0A4" : "#C2D4ED" };
    }
  }

  .idle {
    background-image: url(${ props => colors[props.type].idle });
  }

  .hover {
    background-image: url(${ props => colors[props.type].hover });
    opacity: 0;
  }

  .active {
    background-image: url(${ props => colors[props.type].active });
    opacity: 0;
  }
`

