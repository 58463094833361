import styled from "styled-components"
import Col from "../ui/Col"
import pxToRem from "../../utils/pxToRem"

const wdcardbg = new URL("../../../public/wdcardbg.png?as=webp", import.meta.url).toString()

export interface DiveCardSCProps {
}

export const DiveCardSC = styled(Col)<DiveCardSCProps>`
  width: 100%;
  height: ${ pxToRem(206) };

  background-color: #3284FF;

  border-bottom: ${ pxToRem(4) } solid #FDC500;
  box-shadow: 0 ${ pxToRem(8) } ${ pxToRem(16) } rgba(0, 0, 0, 0.16);
  border-radius: ${ pxToRem(16) };
  padding: 0 ${ pxToRem(42) };

  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
    z-index: -1;

    background: linear-gradient(126.1deg, #2D80FE 50%, rgba(45, 128, 254, 0) 78.91%),
    url(${ wdcardbg }), #3284FF;
    background-blend-mode: normal, luminosity, normal;
  }

  .dive-card-text {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 900;
    font-size: ${ pxToRem(50) };
    line-height: 98%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
  }

  .poligon20 {
    width: ${ pxToRem(74) };
    height: ${ pxToRem(86) };
  }

  .relative {
    position: relative;
  }

  .absolute-description {
    position: absolute;
    top: ${ pxToRem(56) };
    left: 0;
    letter-spacing: -0.05em;
  }

  .mob-description {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    .absolute-description {
      display: none;
    }

    .mob-description {
      display: flex;
      letter-spacing: -0.05em;
    }

    .relative {
      align-items: center;
    }

    .dive-card-text {
      text-align: left;
    }
  }

  @media screen and (max-width: 1008px) {
    width: ${ pxToRem(296) };
    height: ${ pxToRem(100) };

    .dive-card-text {
      font-size: ${ pxToRem(24) };
    }

    padding: 0 ${ pxToRem(24) } 0 ${ pxToRem(24) };
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`
