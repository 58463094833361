import styled from "styled-components"
import Col from "../ui/Col"
import { AnimatedArrowStraightSC } from "../AnimatedArrowStraight/styled"
import pxToRem from "../../utils/pxToRem"
import { AnimatedArrowRoundSC } from "../AnimatedArrowRound/styled"

export interface HowToEarnSCProps {
}

const earnBG = new URL("../../../public/earnBG.png?as=webp", import.meta.url).toString()

export const HowToEarnSC = styled(Col)<HowToEarnSCProps>`
  align-items: center;
  justify-items: center;

  .earn {
    width: 100%;
  }

  .container {
    padding: 3rem 5rem;
    border: ${ pxToRem(6) } solid #FDC500;
    box-shadow: 0 ${ pxToRem(16) } ${ pxToRem(36) } rgba(0, 0, 0, 0.24);
    border-radius: ${ pxToRem(36) };

    background: #08295A url(${ earnBG }) no-repeat center;
    background-size: cover;
  }

  .inner-content {
    position: relative;
    margin: 2rem 0;
    gap: 3rem;

    .block {
      flex: 1;
      z-index: 1;
    }

    .h3 {
      font-size: 2.3rem;
      margin-bottom: 1rem;
    }

    .animate-block {
      position: relative;

      ${ AnimatedArrowStraightSC } {
        margin: 9vw -6rem 0;
        transform: rotateZ(24deg);
        z-index: 0;

        &.revert {
          transform: rotateZ(-24deg);
        }
      }
    }

    .block {
      .earn {
        position: relative;
        z-index: 2;
        transition: transform .3s ease-in-out, filter .3s ease-in-out;
        margin: 0 auto;
      }

      &:hover {
        .earn {
          transform: scale(1.1) rotateZ(-5deg);
          filter: drop-shadow(0 ${ pxToRem(12) } ${ pxToRem(16) } rgba(255, 255, 255, 0.25));
        }
      }
    }

    .block.block-up {
      transform: translateY(-5rem);
    }
  }

  @media screen and (max-width: 1008px) {
    .inner-content {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 860px) {
    padding: 0 1rem;
  }
`

export const BigWrapperSC = styled.div`
  ${ AnimatedArrowRoundSC } {
    display: none;
  }

  @media screen and (max-width: 1008px) {
    width: 100% !important;

    .inner-content {
      gap: .5rem !important;
    }

    img.earn {
      width: 250px;
    }

    .block {
      width: 250px;
      margin: 0 auto;
    }

    .block-up {
      transform: translateX(0) !important;
    }

    ${ AnimatedArrowRoundSC } {
      display: block;
    }
  }

  @media screen and (max-width: 1150px) {
    .inner-content {
      gap: .5rem !important;
    }

    .animate-block {
      ${ AnimatedArrowStraightSC } {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    width: 100% !important;

    &.container {
      padding: 3rem !important;
    }

    img.earn {
      min-width: 250px;
    }

    .animate-block {
      ${ AnimatedArrowStraightSC } {
        margin: 9vw -6rem 0 !important;

        position: absolute;
        left: -2rem;
        transform: scale(.8) rotateZ(24deg) !important;

        &.revert {
          transform: scale(.8) rotateZ(-24deg) !important;
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    ${ AnimatedArrowStraightSC } {
      margin: 6vw -6rem 0 !important;
    }
  }

  @media screen and (max-width: 450px) {
    img.earn {
      width: 100%;
      min-width: auto;
    }

    .block {
      width: auto
    }
  }
`
