import { Blue, Pink, Props, RelativeContainer } from "./styled"

export type ComponentProps = Props & {
  blue: Props["blue"] & {
    image: any
  },
  pink: Props["pink"] & {
    image: any
  },
}

export function Blend(
  {
    blue: { image: iBlue, ...blueRest },
    pink: { image: iPink, ...pinkRest },
  }: ComponentProps,
) {
  return (
    <RelativeContainer>
      <Blue { ...blueRest }>
        <img src={ iBlue } alt="blue"/>
      </Blue>
      <Pink { ...pinkRest }>
        <img src={ iPink } alt="pink"/>
      </Pink>
    </RelativeContainer>
  )
}