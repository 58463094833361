import { memo } from "react"
import { WannaDiveSC, WannaDiveSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import Row from "../ui/Row"
import DiveCard from "../DiveCard"
import SnookCommunity from "../SnookCommunity"
import RoundButton from "../RoundButton"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import classNames from "classnames"

export interface WannaDiveProps extends WannaDiveSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

function WannaDive({ className, style, screenWidth }: WannaDiveProps) {
  return (
    <WannaDiveSC className={ classNames(className, "small-wrapper") } style={ style } alignItems="center">
      <Text className="h1" color="#FDC500">Wanna Dive Deeper?</Text>
      <Text className="title-description-middle-bold">We get it. Fear is nothing to be ashamed of…</Text>
      <Row gap={ 48 } justify="space-between" className="dive-cards">
        <DiveCard
          title="Tokenomics"
          screenWidth={ screenWidth }
          link="/tokenomics#"
        />
        <DiveCard
          title="BBT Themes"
          screenWidth={ screenWidth }
          link="/themed-rooms#"
          // url="https://whitepaper.snook.gg/gameplay/game-modes/big-boys-table/bbt-themed-rooms-bbt-tr"
        />
        <DiveCard
          title="Staking"
          screenWidth={ screenWidth }
          subtitle="Coming Soon"
        />
      </Row>
      <Text className="title-description-middle-bold">Or why don’t you play first and read later?</Text>
      <a href="https://play.snook.gg/" target="_blank">
        <div className="dive-btn">
          <RoundButton color={ RoundButtonTypeEnum.ORANGE }>
            { screenWidth > 700 ? "PLAY" : "PLAY SNOOK" }
          </RoundButton>
        </div>
      </a>
      <SnookCommunity screenWidth={ screenWidth }/>
    </WannaDiveSC>
  )
}

export default memo(WannaDive)
