import { memo } from "react"
import { HeroSectionContainerSC, HeroSectionSC, HeroSectionSCProps } from "./styled"
import { CSSProperties } from "styled-components"
import Text from "../ui/Text"
import { RoundButtonTypeEnum } from "../RoundButton/styled"
import RoundButton from "../RoundButton"

export interface HeroSectionProps extends HeroSectionSCProps {
  className?: string
  style?: CSSProperties
  screenWidth: number
}

function HeroSection({ className, style, screenWidth }: HeroSectionProps) {
  return (
    <HeroSectionSC className={ className } style={ style }>
      <HeroSectionContainerSC>
        <Text className="title">
          Play Hard!
          { (screenWidth < 701) ? <br/> : <>&nbsp;</> }
          Earn Hard!
        </Text>
        <Text className="sub-title">Don’t miss out on Snook’s new { (screenWidth < 701) && <br/> } revamped
          economy.</Text>
        { (screenWidth > 700) && (
          <Text className="title-description">
            Snook is the real deal. Play hard and show your skill with a trail of dead snooks to earn more than respect.<br/>
            Appreciate your snook-NFT, unlock rare Special Skins, get cash <b>SNK</b> with Pay-Per-Kill. We’re not
            f#@!ng around.
          </Text>
        ) }
        <a href="https://play.snook.gg/" target="_blank">
          <RoundButton
            color={ RoundButtonTypeEnum.ORANGE }
            sub={ (screenWidth < 701) ? "ON DESKTOP" : "" }
            className="btn"
          >
            PLAY NOW
          </RoundButton>
        </a>
        { (screenWidth < 701) && (
          <Text className="title-description mobile-description">Snook is the real deal. Play hard and <br/>
            show your skill with a trail of dead<br/>
            snooks to earn more than respect.<br/>
            Appreciate your snook-NFT, unlock <br/>
            rare Special Skins, get cash <b>SNK</b> with <br/>
            Pay-Per-Kill. We’re not f#@!ng<br/>
            around.</Text>
        ) }
      </HeroSectionContainerSC>
    </HeroSectionSC>
  )
}

export default memo(HeroSection)
