import styled from "styled-components"
import Row from "../ui/Row"

import pxToRem from "../../utils/pxToRem"

const communityVis = new URL("../../../public/communityVis.png?as=webp", import.meta.url).toString()
const imageDark = new URL("../../../public/image-dark.png?as=webp", import.meta.url).toString()
const communityMob = new URL("./communityMob.png?as=webp", import.meta.url).toString()

export interface SnookCommunitySCProps {
}

export const RelativeContainer = styled.div`
  position: relative;

  img {
    border-top-right-radius: ${ pxToRem(16) };
  }
`

export const AbsoluteContainer = styled.div``

export const SnookCommunitySC = styled(Row)<SnookCommunitySCProps>`
  background-color: #3284FF;
  width: 100%;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    z-index: -1;
    border-radius: ${ pxToRem(16) };

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 50%),
    linear-gradient(252.77deg, #2D80FE 50%, rgba(45, 128, 254, 0) 100%),
    url(${ imageDark }), #3284FF;
    background-blend-mode: overlay, normal, luminosity, normal;
  }

    // border-bottom: ${ pxToRem(8) } solid #FDC500;
  box-shadow: 0 ${ pxToRem(8) } ${ pxToRem(16) } rgba(0, 0, 0, 0.16);
  border-radius: ${ pxToRem(16) };

  .community-content {
    border-bottom: ${ pxToRem(8) } solid #FDC500;
    border-radius: 0 ${ pxToRem(16) };

    display: flex;
    flex-direction: column;
    justify-content: center;

    overflow: hidden;

    padding: 0 0 0 3rem;
    flex-grow: 1;

    text-align: left;

    .community-content-small-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 98%;
      color: #FFFFFF;
      margin-bottom: 1rem;
    }

    .community-content-big-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 4.5rem;
      line-height: 98%;

      letter-spacing: -0.05em;
      text-transform: capitalize;

      color: #FDC500;
      margin-bottom: 3rem;
    }
  }

  .community-content p {
    text-align: left;
  }

  ${ RelativeContainer } {
    border-bottom: ${ pxToRem(8) } solid #FDC500;
    border-radius: ${ pxToRem(16) } 0;
  }

  .communityBG {
    width: ${ pxToRem(680) };
    height: ${ pxToRem(604) };
    background: url(${ communityVis });
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .mobile-community-img {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    .twitter-icon {
      width: ${ pxToRem(26) };
      height: ${ pxToRem(22) }
    }

    .discord-icon {
      width: ${ pxToRem(34) };
      height: ${ pxToRem(26) }
    }

    .community-content {
      .community-content-small-title {
        font-size: 2rem;
      }

      .community-content-big-title {
        font-size: ${ pxToRem(48) };
        margin-bottom: ${ pxToRem(21) };
      }
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    //padding: 0 !important;
    .button-icon {
      display: none;
      font-size: 85%;
    }
  }

  @media screen and (max-width: 1008px) {
    padding: 0 !important;
    .community-content {
      padding: ${ pxToRem(28) } 0 ${ pxToRem(48) } ${ pxToRem(32) };

      border-bottom: ${ pxToRem(8) } solid #FDC500;
      border-radius: 1rem;
      background: url(${ communityVis });
      background-repeat: no-repeat;

      background-size: contain;
      background-position: right;

      .community-content-small-title {
        font-size: 1rem;
      }

      .community-content-big-title {
        font-size: ${ pxToRem(36) };
        margin-bottom: ${ pxToRem(21) };
      }
    }

    .mobile-community-img {
      background: url(${ communityMob });
    }

    ${ RelativeContainer } {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    .community-content {
      background-size: cover;
      background-position: 10rem;
    }
  }

`
